import React, { useEffect, useState } from 'react'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { BtnForm, CheckBoxList, FormInput, FormSelect, HeaderApp, HomeMain } from '../../../components';
import { FaPlus } from 'react-icons/fa';
import { AiOutlinePrinter } from 'react-icons/ai';
import { datas } from '../../../constances';

const AgentProfileAdd = () => {

    const {create_user_profile, user, profiles_users, countries} = usePlateformContext();
    const navigate = useNavigate();

    let model_data = {
        structure: user?.profile !== 'admin' ? user?.structure : "",
        type_profile: user?.profile !== 'admin' ? 'client' : "admin",
        permissions_list: [],
        label: '',
    };


    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.label) {
                errors.label = "Entrer le nom du profil";
            }

            if (credentials.permissions_list.length === 0) {
                errors.permissions_list = "Selectionner au moins une permission";
            }

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = await create_user_profile(credentials);

                if (response) {
                    setCredentials(model_data);
                    navigate("/profiles-users/list");
                }
            }
        
    }


    //
    function ajouterOuSupprimerElement(liste, element) {
        const index = liste.indexOf(element);
    
        if (index === -1) {
        // L'élément n'existe pas dans la liste, ajoutons-le.
        liste.push(element);
        } else {
        // L'élément existe dans la liste, supprimons-le.
        liste.splice(index, 1);
        }

        return liste;
    }   

    const handleChangeCheckBoxList = (name, value) => {
        setCredentials({...credentials, [name] : value})
    }

    const permissions = user.profile !== "admin" ? datas.permissions_profile_client : datas.permissions_profile_admin

  return (
    <div>
        <HeaderApp
            title="Nouveau profil utilisateur"
            links={[
                {
                    description: "Liste",
                    path: "/profiles-users",
                    icon: "",
                },
                {
                    description: "Nouveau",
                    path: "",
                    icon: "",
                    active: true
                }
            ]}
        />

        <div className='flex flex-col justify-start items-center py-3'>
            <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3 rounded-md'>

                <div>

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormInput
                            type={"text"}
                            label={"Profil"}
                            required={true}
                            placeholder={"Nom du profil"}
                            autoComplete='off'
                            value={credentials.label}
                            error={credentialsError.label}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("label", null)}
                            name={"label"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

                        />
                    </div>

                    <div className='w-full my-3'>
                        <h3 className='font-normal text-sm mb-1'>Permissions</h3>
                        <div className='flex flex-wrap border-[1px] px-1'>
                            {permissions.map((item, index) => {
                                return(
                                    <CheckBoxList 
                                        key={index} 
                                        list={credentials?.permissions_list} 
                                        item={item} 
                                        label={`${item}`} 
                                        onPress={() => {handleChangeCheckBoxList("permissions_list", ajouterOuSupprimerElement(credentials.permissions_list, item)); handleChangeError("permissions_list", null)}}
                                    />
                                )
                            })}
                        </div>
                        {credentialsError.permissions_list && <span className='text-red-900 mt-2 text-sm'>{credentialsError.permissions_list}</span>}
                    </div>


                    <BtnForm
                        type="submit"
                        text={"Enregistrer"}
                    />

                </div>

            </form>
        </div>
    </div>
  )
}

export default AgentProfileAdd