import React, { useRef } from 'react'
import { usePlateformContext } from '../../../contexts';
import { useNavigate } from 'react-router-dom';
import { GenericTable, HeaderApp } from '../../../components';
import { getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
import { FormatDateTime } from '../../../constances/functions';
 
const VentesHistorique = () => {

    const {
        user, 
        ventesList,
        pointVenteSelected,
        agents,
    } = usePlateformContext();

    const navigate = useNavigate();

    const columns = [
        {key: "facture", label: "N° Facture", value: row => row.facture},
        {key: "total", label: "Montant", value: row => row.total},
        {key: "vente_items", label: "Nombre de produit", value: row => row.vente_items.length},
        user.profile !== 'admin' && {key: "user", label: "Agent", value: row => row.user === user?.id ? user.user_id : getElementData(row.user, agents)?.user_id},
        // {key: "currency", label: "Devise", value: row => row.currency},
        {key: "date_creation", label: "Date", value: row => <span className='capitalize'>{FormatDateTime(row.date_creation)}</span>},

    ]


    let links_admin = [
        {
            description: "Suivit de vente",
            path: "/ventes",
            icon: "",
            active: true
        },
    ]

    let links_client = [
        {
            description: "Suivit de vente",
            path: "/ventes",
            icon: "",
            active: true
        },
        {
            description: "Nouveau",
            path: "/ventes/create",
            icon: "",
        }
    ]

  return (
    <div className=''>
        <HeaderApp
            title="Historique de vente"
            links={user.profile !== "admin" ? links_client : links_admin}
        />
        
        <div className='print:p-2'>

            <div 
                className='p-2 bg-white rounded-lg'
            > 

                <GenericTable
                    data={ventesList.filter(item => item.site.toString() === pointVenteSelected.toString())}
                    columns={columns}
                />

            </div>
        </div>
    </div>
  )
}

export default VentesHistorique