import React, { createContext, useContext, useEffect, useState } from 'react'
import { AiFillBell, AiFillShop, AiOutlineBarChart, AiOutlineBars, AiOutlineLogout, AiOutlineMore, AiOutlineOrderedList } from 'react-icons/ai'
import { FaBoxOpen, FaBoxes, FaTachometerAlt, FaThList, FaUserCircle, FaUsers } from 'react-icons/fa'
import { FaBuildingUser } from 'react-icons/fa6'
import { MdArrowRight, MdLocalPharmacy, MdPerson, MdShoppingCart } from 'react-icons/md'
import { TbRefreshDot, TbTruckDelivery } from 'react-icons/tb'
import { RiOrderPlayFill, RiPhoneFindFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { usePlateformContext } from '../../contexts'
import { IMAGES, datas, functions, getElementData } from '../../constances'
import SideBarItems from './SideBarItems'
import { HiBars3CenterLeft } from "react-icons/hi2";

// const SideBar = () => {

//     const location = useLocation();
//     const {user, sites, pointVenteSelected, selectPointVente, setIsLoading} = usePlateformContext();

//     const isActive = (path) => {
//         if(path === '/'){
//             return  path.includes(location.pathname);
//         }else{
//             return  path ? location.pathname.includes(path) : false;
//         }
//     };

//     const [menu_list_items, set_menu_list_items] = useState([]);

//     const menu_list = [
//         {
//             label: "Gestion de stocks",
//             path: "/stocks",
//             icone: <FaBoxes/>,
//             default_active: isActive("/stocks"),
//             items: [
//                 {
//                     label: "Stock disponible",
//                     path: '/stocks',
//                     active: isActive("/stocks"),
//                     permission: true,
//                 },
//             ],

//             permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
//                 [
//                     datas.PERMISSIONS.ENREGISTRER_STOCK, 
//                     datas.PERMISSIONS.MODIFIER_STOCK, 
//                     datas.PERMISSIONS.CONSULTER_STOCK_DISPONIBLE, 
//                     datas.PERMISSIONS.CONSULTER_STOCK_HISTORIQUE
//                 ],
//                 user.profile_permissions_list
//             ),

//         },

//         {
//             label: "Gestion de ventes",
//             path: "/ventes",
//             icone: <MdShoppingCart />,
//             permission: (user.profile !== "admin") 
//         },

//         {
//             label: "Gestion de commandes",
//             path: "",
//             icone: <RiPhoneFindFill />,
//             // permission: (user.profile === "admin" || user.profile === "client-admin" || true) 
//         },

//         {
//           label: "Rapports",
//           path: "/rapports",
//           icone: <AiOutlineBarChart/>,
//             // permission: (user.profile === "admin" || user.profile === "client-admin" || true)  
//         },
//         {
//             label: "Alertes",
//             path: "",
//             icone: <AiFillBell/>,
//             permission: false 
//         },

//         {
//             label: "Produits",
//             path: "/produits",
//             icone: <FaBoxOpen/>,
//             // permission: (user.profile === "admin") 
//         },
    
//         {
//           label: "Fournisseurs",
//           path: "/fournisseurs",
//           icone: <TbTruckDelivery />,
//         //   permission: (user.profile === "client-admin" || user.profile === "client-agent") 
//         },

//         {
//             label: "Points de ventes",
//             path: "/points-ventes",
//             icone: <AiFillShop/>,
//             // permission: (user.profile === "admin")  
//         },

//         {
//             label: "Comptes clients",
//             path: "/comptes-clients",
//             icone: <FaBuildingUser />,
//             // permission: (user.profile === "admin")
//         },
    
//         {
//             label: "Profils Utilisateurs",
//             path: "/profiles-users",
//             icone: <MdPerson/>,
//             permission: (user.profile === "admin" || user.profile === "client-admin") 
//         },

//         {
//             label: "Utilisateurs",
//             path: "/personnels",
//             icone: <FaUsers/>,
//             // permission: (user.profile === "admin" || user.profile === "client-admin") 
//         },
    
//         {
//           label: "Réabonnement",
//           path: "/abonnements",
//           icone: <TbRefreshDot />,
//         //   permission: (user.profile === "admin" || user.profile === "client-admin") 
//         },

//     ]

//     // Points de vente
//     let pointVente = getElementData(pointVenteSelected, sites)

//     const model_data = {
//         point_vente: "",
//     }

//     const [credentials, setCredentials] = useState(model_data);

//     const handleChange = (e) => {
//         setCredentials({...credentials, [e.target.name] : e.target.value});

//         setIsLoading(true);

//         setTimeout(() => {
//             selectPointVente(e.target.value);
//             setIsLoading(false);
//         }, 2000);
//     }

//     useEffect(() => {
//         setCredentials({...credentials, ['point_vente'] : pointVenteSelected})
//     }, [pointVenteSelected])

//     useEffect(() => {
//         set_menu_list_items(menu_list)
//     }, [user])


//     console.log("Utilisateur", user)

//     return (
//         // <div className='bg-secondary h-screen overflow-y-scroll relative z-10'>
//         //     {/* Entete Poitn de vente */}
//         //     <div className='flex justify-center xl:justify-start items-center  p-[10px] py-[13px] border-b-[0.5px] border-[#F6F6F6]/[0.5] bg-white'>
//         //         {(user.profile !== "admin" && sites.length > 0) ?
//         //             <label htmlFor='point_de_vente' className='flex h-[30px]  cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[80%]'>
//         //                 <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
//         //                     <AiFillShop />
//         //                 </span>

//         //                 <select onChange={handleChange} id='point_de_vente' value={credentials.point_vente} name='point_vente' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary xl:inline-block hidden'>
//         //                     {sites.map((item, index) => <option key={index} value={item.id} className='uppercase text-black'>{item.label}</option>)}
//         //                 </select>
//         //             </label> :
//         //             <div className='h-[30px] flex justify-center items-center w-full'>
//         //                 {/* Logo de Mopessi  */}
//         //                 <img src={IMAGES.logo} alt='logo mopesi' className='hidden xl:block h-[100%] object-contain'/>
//         //                 <img src={IMAGES.logo_m} alt='logo mopesi' className='xl:hidden h-[100%] object-contain'/>
//         //             </div>}
//         //     </div>

//         //     {/* Lien vers le tableau de bord */}
//         //     <Link 
//         //         to={'/'} 
//         //         className={`flex border-l-[3px] ${
//         //             isActive('/') ? 'border-l-primary bg-[rgba(0,0,0,0.4)]' : 'border-l-[transparent] bg-[transparent]'
//         //         } hover:bg-[rgba(0,0,0,0.4)] focus:bg-[rgba(0,0,0,0.4)] xl:gap-[10px] items-center justify-center xl:justify-start px-[10px] py-[15px] cursor-pointer my-2`}> 
//         //         <span className='text-[30px] xl:text-[25px] text-[#fefefe]'>
//         //             <FaTachometerAlt/>
//         //         </span>
//         //         <p className='text-[15px] leading-[20px] font-bold hidden xl:block text-[#fefefe]'>Tableau de bord</p>
//         //     </Link>

//         //     {/* Menu gestion de pont de vente */}
//         //     <div className='pb-[15px] border-t-[0.5px] border-[#F6F6F6]/[0.5]'>
//         //         {/* <p className='text-[12px] px-[10px] pb-[10px] font-normal leading-[10px] text-gray-400/[3.5] hidden xl:block uppercase'>GESTION DE POINT DE VENTE</p> */}
                
//         //         {user.profile_permissions && menu_list_items.filter(item => item.permission === true).map((item, index) => (
//         //             <Link
//         //                 to={item.path}
//         //                 key={index}
//         //                 className={`flex border-l-[3px] ${
//         //                     isActive(item.path) ? 'border-l-primary bg-[rgba(0,0,0,0.4)]' : 'border-l-[transparent] bg-[transparent]'
//         //                 } hover:bg-[rgba(0,0,0,0.4)] focus:bg-[rgba(0,0,0,0.4)] xl:gap-[10px] items-center justify-center xl:justify-start px-[10px] py-[15px] cursor-pointer my-2`}
//         //             >
//         //                 <span className='text-[30px] xl:text-[25px] text-[#fefefe]'>{item.icone}</span>
//         //                 <p className='text-[15px] leading-[20px] font-bold hidden xl:block text-[#fefefe]'>
//         //                     {item.label}
//         //                 </p>
//         //             </Link>
//         //         ))}
                

//         //     </div>
//         // </div>

//         <div className='bg-secondary h-screen overflow-y-scroll relative z-10'>
//             {/* Entete : Logo de Mopesi pour les admin de mopesi et logo avec select de point de vente pour les agents client */}
//             <div className='flex justify-center xl:justify-start items-center  p-[10px] py-[13px] border-b-[0.5px] border-[#F6F6F6]/[0.5] bg-white'>
//                 {(user.profile !== "admin" && sites.length > 0) ?
//                 <label htmlFor='point_de_vente' className='flex h-[30px]  cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[80%]'>
//                     <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
//                         <AiFillShop />
//                     </span>

//                     <select onChange={handleChange} id='point_de_vente' value={credentials.point_vente} name='point_vente' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary xl:inline-block hidden'>
//                         {sites.map((item, index) => <option key={index} value={item.id} className='uppercase text-black'>{item.label}</option>)}
//                     </select>
//                 </label> :
//                 <div className='h-[30px] flex justify-center items-center w-full'>
//                     {/* Logo de Mopessi  */}
//                     <img src={IMAGES.logo} alt='logo mopesi' className='hidden xl:block h-[100%] object-contain'/>
//                     <img src={IMAGES.logo_m} alt='logo mopesi' className='xl:hidden h-[100%] object-contain'/>
//                 </div>}
//             </div>

//             <ul>
//                 <li className={`group ${isActive('/') && 'active'}`}>
//                     <Link 
//                         to={`/`} 
//                         className={`flex border-l-[3px] group-[.active]:border-l-primary group-[.active]:bg-[rgba(0,0,0,0.4)] border-l-[transparent] bg-[transparent]
//                                      hover:bg-[rgba(0,0,0,0.4)] focus:bg-[rgba(0,0,0,0.4)] xl:gap-[10px] items-center justify-center xl:justify-start px-[10px] py-[15px] cursor-pointer my-2`}>
//                         <FaTachometerAlt className='text-[30px] xl:text-[25px] text-[#fefefe]'/>
//                         <span className='text-[15px] leading-[20px] font-bold hidden xl:block text-[#fefefe]'>Tableau de bord</span>
//                     </Link>
//                 </li>

//                 {menu_list.map((item, index) => {
//                     return(
//                         <SideBarItems
//                             key={index}
//                             icon={item.icone}
//                             label={item.label}
//                             items={item.items}
//                             path={item.path}
//                             default_active={false}
//                         />
//                     )
//                 })}

//             </ul>

//         </div>
//     )
// }

const sideBarContext = createContext();

const SideBar = ({children}) => {
    const navigate = useNavigate();
    const {user, logout,} = usePlateformContext();
    const [expanded, setExpanded] = useState(true);
    const [dropdown, setdropdown] = useState(false);

    return(
        <aside className={`${expanded ? 'w-auto' : 'w-auto'} h-screen`}>
            <nav className='h-full flex flex-col bg-white border-r shadow-sm'>
                <div className='p-4 pb-2 flex justify-between items-center border-b'>
                    <img className={`overflow-hidden transition-all ${expanded ? 'w-20' : 'w-0'}`} src={IMAGES.logo} alt={'Mopesi'}/>

                    <button onClick={() => setExpanded(curr => !curr)} className='p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100'>
                        {<HiBars3CenterLeft className='text-[20px]'/>}
                    </button>
                </div>

                <sideBarContext.Provider value={{expanded, setExpanded}}>
                <ul className="flex-1 px-3 bg-secondary py-3">{children}</ul>
                </sideBarContext.Provider>

                <div onClick={() => setdropdown(curr => !curr)} className={`flex items-center cursor-pointer border-t bg-secondary ${expanded ? 'p-2' : 'justify-center py-2'}`}>
                    <span className='w-10 h-10 flex items-center justify-center rounded-md bg-gray-200 hover:bg-gray-300'>{user.avatar}</span>
                    <div className={`flex justify-between items-center ${expanded ? 'w-52 ml-3' : 'w-0 hidden'}`}>
                        <div className='flex flex-col'>
                            <h4 className='font-semibold mb-0 leading-[20px] text-white'>{user.first_name}</h4>
                            <span className='text-xs text-white'>{user.profile_permissions_label}</span>
                        </div>

                        {expanded && <AiOutlineMore className='text-white text-[20px]'/>}
                    </div>
                </div>

                {dropdown &&
                    <div className='bg-white border w-[200px] shadow-md rounded-md overflow-hidden absolute bottom-[40px] left-[70px] z-40 right-0'>
                        <button onClick={() => {setdropdown(false); navigate('/profil');}} className='flex hover:bg-slate-100 w-full items-center text-gray-700 py-2 px-2'>
                            <span className="text-md text-grey-700 mr-2">
                                <FaUserCircle/>
                            </span>
                            <span className='text-[16px]'>Mon profile</span>
                        </button>

                        <button onClick={() => {logout(); setdropdown(false)}} className='flex hover:bg-slate-100 w-full items-center text-red-700 py-2 px-2'>
                            <span className="text-md text-red-700 mr-2">
                                <AiOutlineLogout/>
                            </span>
                            <span className='text-[16px]'>Deconnexion</span>
                        </button>
                    </div>}
            </nav>
        </aside>
    )
}

export function SideBarItem({icon, text, active, alert, items=[], path}) {
    const navigate = useNavigate();

    const {expanded, setExpanded} = useContext(sideBarContext);
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        if (items.length > 0 ) {
            setVisible(curr => !curr);
            setExpanded(true);
        } else {
            navigate(path);
        }
    }

    useEffect(() => {
        setVisible(active);
    }, [active])

    return(
        <li className={`group ${visible && 'active'} relative flex flex-col items-center my-2  `}>
            
            <div 
                onClick={handleClick} 
                className={`flex items-center py-2 px-3 font-medium rounded-md cursor-pointer transition-colors ${(((active && !expanded) || (active && items.length === 0)) ? 'bg-[rgba(0,0,0,0.4)] text-gray-50' : 'hover:bg-[rgba(0,0,0,0.4)] text-gray-50')}`}
            >
                
                {icon}
                
                <span className={`overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0 hidden'}`}>{text}</span>

                {alert && <div className={`absolute right-2 w-2 h-2 rounded bg-red-700 ${expanded ? "" : "top-2"}`}/>}

                {(items.length > 0 && expanded) && <MdArrowRight className='absolute ml-auto group-[.active]:rotate-90 text-white text-[20px] right-0'/>}
            
                {!expanded && <div className={`absolute left-full rounded-md z-10 px-2 ml-6 bg-secondary border text-white invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 flex w-auto text-sm`}><span>{text}</span></div>}    
            
            </div>
 
            {items.filter(item => item.permission).length > 0 && <ul className={`${expanded ? 'w-full' : 'hidden w-0'}`}>
                {items.filter(item => item.permission).map((item, index) => {
                    return(
                        <li key={index} className={`mt-2 ml-3 hidden group-[.active]:block items-center py-2 px-3 font-light rounded-md cursor-pointer transition-all ${item.active ? 'bg-[rgba(0,0,0,0.4)] text-gray-50' : 'hover:bg-[rgba(0,0,0,0.4)] text-gray-50'}`}>
                            <Link to={item.path} className="flex items-center before:contents-[''] before:w-1 before:h-1 before:rounded-full before:bg-white before:mr-3">{item.label}</Link>
                        </li>
                    )
                })}
            </ul>}
        
        </li>
    )
}

export default SideBar