import React from 'react'
import {usePlateformContext} from '../../../contexts';
import { IMAGES } from '../../../constances';

const BtnForm = ({text, type, onPress=()=>{}}) => {

  const {formLoading} = usePlateformContext();

  return (
    <button 
      onClick={onPress}
      disabled={formLoading} 
      type={type} 
      className={`my-2 bg-secondary flex-grow text-light h-[50px] w-full flex justify-center items-center`}>
        {!formLoading ? text : <img width={30} height={30} src={IMAGES.loading_btn} alt='loading'/>}
    </button>
  )
}

export default BtnForm