import React, { useEffect, useState } from 'react'
import { AiFillShop, AiOutlineLogout } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom';
import { getElementData } from '../../constances';
import { usePlateformContext } from '../../contexts';
import { FaUserCircle } from 'react-icons/fa';

const TopBar = () => {

    const {user, sites, subscriptions, currencies, countries, pointVenteSelected, selectPointVente, logout, abonnement_alert, setIsLoading} = usePlateformContext();

    const navigate = useNavigate();

    

    const [dropdown, setdropdown] = useState(false);

    //  Points de vente
    let pointVente = getElementData(pointVenteSelected, sites)

    const model_data = {
        point_vente: "",
    }

    const [credentials, setCredentials] = useState(model_data);

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value});

        setIsLoading(true);

        setTimeout(() => {
            selectPointVente(e.target.value);
            setIsLoading(false);
        }, 2000);
    }

    useEffect(() => {
        setCredentials({...credentials, ['point_vente'] : pointVenteSelected})
    }, [pointVenteSelected])    
    
  return (
    <div className='shadow-sm bg-white flex justify-between items-center px-2 py-2 rounded-lg'>
        <div>
            {user.profile != "admin" && <label htmlFor='point_de_vente' className='flex h-[30px]  cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-auto'>
                <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                    <AiFillShop />
                </span>

                <select onChange={handleChange} id='point_de_vente' value={credentials.point_vente} name='point_vente' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary inline-block'>
                    {sites.map((item, index) => <option key={index} value={item.id} className='uppercase text-black'>{item.label}</option>)}
                </select>
            </label>}
        </div>

        <div className='flex items-center relative'>

            {(user.profile !== "admin") && <div onClick={() => user.profile === "client-admin" ? navigate(`/abonnements`) : ''} className={`${user.profile === "client-admin" && 'cursor-pointer'} flex items-center border-[1px] px-2 py-1 rounded-full`}>
                {abonnement_alert.access ? <div className='flex items-center'>
                    <span className='block bg-green-700 w-3 h-3 rounded-full'></span>
                    <span className='text-[12px] font-bold ml-2 uppercase hidden xl:inline-block'>Abonnement Actif</span>
                </div> : 
                <div className='flex items-center'>
                    <span className='block bg-red-700 w-3 h-3 rounded-full'></span>
                    <span className='text-[12px] font-bold ml-2 uppercase hidden xl:inline-block'>Abonnement Inactif</span>
                </div>}
            </div>}

            {/* <div className='relative'>
                <div onClick={() => setdropdown(!dropdown)} className='flex items-center cursor-pointer'>
                    <div className='mr-2 flex-col items-end flex'>
                        <p className='text-[13px] leading-[20px]'>{user.first_name}</p>
                        <strong className='text-[13px] leading-[20px]'>{user.last_name}</strong>
                    </div>
                    
                    <span className='w-[40px] h-[40px] bg-gray-100 border-[1px] border-gray-400 rounded-[35px] flex justify-center items-center text-[#333] text-[14px] font-light'>{user.avatar}</span>
                </div>

                {dropdown &&
                    <div className='bg-white border w-[200px] shadow-md rounded-md overflow-hidden absolute bottom-[-95px] z-40 right-0'>
                        <button onClick={() => {navigate('/profil'); setdropdown(false)}} className='flex hover:bg-slate-100 w-full items-center text-gray-700 py-2 px-2'>
                            <span className="text-md text-grey-700 mr-2">
                                <FaUserCircle/>
                            </span>
                            <span className='text-[16px]'>Mon profile</span>
                        </button>

                        <button onClick={() => {logout(); setdropdown(false)}} className='flex hover:bg-slate-100 w-full items-center text-red-700 py-2 px-2'>
                            <span className="text-md text-red-700 mr-2">
                                <AiOutlineLogout/>
                            </span>
                            <span className='text-[16px]'>Deconnexion</span>
                        </button>
                    </div>
                }
            </div> */}
        </div>

        {user.profile === "admin" && <span className='flex items-center border-[1px] px-2 py-1 rounded-full'>
            <div className='flex items-center'>
                <span className='block bg-secondary w-3 h-3 rounded-full'></span>
                <span className='text-[12px] font-bold ml-2 uppercase'>Compte administrateur</span>
            </div>
        </span>}
    </div>
  )
}

export default TopBar