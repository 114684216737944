import React, { useRef } from 'react'
import { AiFillCiCircle, AiOutlineClose, AiOutlineFilePdf, AiOutlinePrinter } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { AbonnementAlert, GenericTable, HeaderApp, HomeMain } from '../../../components';
import { checkPermission } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
import { FormatDateTime, FormatPrice, getElementData } from '../../../constances/functions';
 
const Abonnements = () => {

    const {subscriptions, user, sites, currencies, abonnement_alert, pointVenteSelected} = usePlateformContext();

    const navigate = useNavigate();

    const columns = [
        { key: "site", label: "Point de vente", value: row => getElementData(row.site, sites)?.label },
        { key: "", label: "N°", value: row => getElementData(row.site, sites)?.unique_identification_number },
        { key: "forfait", label: "Offre" },
        { key: "price", label: "Montant", value: row => FormatPrice(row.price) },
        { key: "currency", label: "Devise", value: row => getElementData(row.currency, currencies)?.iso_code},
        { key: "days", label: "Durée (jour)"},
        { key: "debut", label: "Debut", value: row => FormatDateTime(row.debut)},
        { key: "fin", label: "Fin", value: row => FormatDateTime(row.fin)},
        { key: "status", label: "Status", value: row => row.status ? <div className='flex items-center'>
            <span className='block bg-green-700 w-3 h-3 rounded-full'></span>
            <span className='text-sm font-light ml-2'>En cours</span>
        </div> : 
        <div className='flex items-center'>
            <span className='block bg-red-700 w-3 h-3 rounded-full'></span>
            <span className='text-sm font-light ml-2'>obsolète</span>
        </div>},
    ]

    let data_filter = subscriptions.filter(item => item.site.toString() === pointVenteSelected.toString())


  return (
    <div>
        <div className='w-full'>
            <AbonnementAlert value={abonnement_alert}/>
        </div>

        <HeaderApp
            title="Réabonnement"
            links={[
                {
                    description: "Liste",
                    path: "",
                    icon: "",
                    active: true
                },
                {
                    description: 'Se réabonner',
                    path: "",
                    icon: "",
                }
            ]}
        />

        <div></div>

        <div className='p-2 bg-white rounded-lg'>
            <GenericTable
                data={data_filter}
                columns={columns}

            />
        </div>
    </div>
  )
}

export default Abonnements