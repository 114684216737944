import React, { useEffect, useRef, useState } from 'react'
import { Content, EspaceAgentContent, GenericTable, HeaderApp, Onglet } from '../../../components'
import { useParams } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts';
import { functions, getElementData } from '../../../constances';
import { AiFillDelete } from 'react-icons/ai';
import { FormatPrice, formaterDate } from '../../../constances/functions';
import { MdAddShoppingCart } from 'react-icons/md';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const Ventes = () => {

    const {sites, currencies, user, suivitStocksList, pointVenteSelected, produits, create_vente, stocksList, ventesList} = usePlateformContext();

    let site = getElementData(pointVenteSelected, sites);
    site = site ? site : {};

    const [stock_disponible, set_stock_disponible] =  useState([]);
    const [devise_point_de_vente, set_devise_point_de_vente] = useState('');

    let point_de_vente_id = pointVenteSelected;

    // let DATA = suivitStocksList.filter(item => item.site.toString() === pointVenteSelected && item.total_quantite > 0)

    const [panier_data, setPanier_data] = useState([]);
    const [errorsPanier, setErrorsPanier] = useState("");
    const [venteSuccesful, setVenteSuccesful] = useState(null);

    const factureRef = useRef();

    const handlePront = useReactToPrint({
        content: () => factureRef.current,
        documentTitle: `Facture-${0}`,
        onAfterPrint: () => console.log("print successfuly")
    });

    const handleChangeQuantity = (itemId, newQuantity) => {
        // Copiez le tableau de panier_data pour travailler sur une copie
        const updatedCart = [...panier_data];
    
        // Recherchez l'index de l'article dans le tableau
        const itemIndex = updatedCart.findIndex(item => item.id === itemId);
    
        // Vérifiez si l'article a été trouvé
        if (itemIndex !== -1) {
        // Mise à jour de la quantité avec une valeur minimale de 1
        updatedCart[itemIndex].quantity = Math.max(1, newQuantity);
    
        // Mettez à jour l'état avec le nouveau tableau
        setPanier_data(updatedCart);
        }
    };

    const handleDeletePanier = (itemId) => {
        // Filtrez les articles qui ne correspondent pas à l'ID de l'article à supprimer
        const updatedCart = panier_data.filter(item => item.id !== itemId);
    
        // Mettez à jour l'état avec le nouveau tableau
        setPanier_data(updatedCart);
        setErrorsPanier("");
    };

    const addToCart = (productId) => {
        // Vérifiez si le produit existe déjà dans le panier
        const itemIndex = panier_data.findIndex(item => item.id === productId);
    
        if (itemIndex !== -1) {
            // Le produit existe déjà, augmentez la quantité de +1
            const updatedCart = [...panier_data];
            updatedCart[itemIndex].quantity += 1;
            setPanier_data(updatedCart);
        } else {
            // Le produit n'existe pas, ajoutez-le au panier avec une quantité de 1
            const productToAdd = getElementData(productId, stock_disponible); // Remplacez getProductById par votre fonction pour obtenir les détails du produit
        if (productToAdd) {
            setPanier_data([...panier_data, { ...productToAdd, quantity: 1 }]);
        }
        }
    };

    const viderPanier = () => {
        // Réinitialisez le panier en le remplaçant par un tableau vide
        setPanier_data([]);
        setErrorsPanier("");
    };
    
    const validerPanier = async () => {
        // Affichez le contenu du panier dans la console

        let sale_info = {
            user: user?.id,
            site: pointVenteSelected,
            panier: panier_data,
            type_vente: "comptoir"
        } 
        

        panier_data.map(item => {
            if (item.quantity > item.quantite) {
                setErrorsPanier("La quantité de produit en rouge est élevée par rapport à celle du stock, veuillez la réduire avant de valider la vente.");
            }
        })


        if (!errorsPanier) {
            console.log("Validation de vente :");
            let response = await create_vente(sale_info);

            if (response.valid) {
                setVenteSuccesful(response.data)
            }
        }
    
        // Ajoutez ici le code pour soumettre le panier, par exemple, à un backend
        // pour effectuer une commande réelle.
    };
    

    const [total_panier, setTotal_panier] = useState(0);

    useEffect(() => {
        let total = 0

        panier_data.map((item, index) => {
            total += parseInt(item.quantity * item.prix_unitaire);
        })

        setTotal_panier(total);
    }, [panier_data])

    useEffect(() => {
        console.log("Le panier a etet vide en raison du changement de point de vente ....")
        viderPanier();
        setErrorsPanier("");
    }, [pointVenteSelected])
    

    let links_admin = [
        {
            description: "Suivit de vente",
            path: "/ventes",
            icon: "",
        },
    ]

    let links_client = [
        {
            description: "Suivit de vente",
            path: "/ventes",
            icon: "",
        },
        {
            description: "Nouveau",
            path: "/ventes/create",
            icon: "",
            active: true
        }
    ]

    let columns = [
        {key: "", label: "ID", value: row => getElementData(row.produit, produits)?.reference},
        {key: "produit", label: "ID", value: row => getElementData(row.produit, produits)?.designation},
        {key: "prix_unitaire", label: `Prix`, value: row => `${FormatPrice(row.prix_unitaire)} ${devise_point_de_vente}`},
        {key: "quantite", label: "Qté en stock"},
        {key: "", label: "Date de péremption", value: row => formaterDate(row.date_exp)},
        {key: "", label: "", value: row => {
            return(
                <div>
                    <button className='border-[1px] border-green-600 bg-green-600 flex justify-center items-center text-lg px-2 py-1 rounded-sm text-white' onClick={() => addToCart(row.id)}>
                        <MdAddShoppingCart/>
                    </button>
                </div>
            )
        }}
    ]


    useEffect(() => {

        let devise = '';
        let point_vente = getElementData(point_de_vente_id, sites);

        devise = getElementData(point_vente?.currency, currencies)?.iso_code;


        let stock =  stocksList.filter(item => item.site.toString() === pointVenteSelected?.toString());
        let vente =  ventesList.filter(item => item.site.toString() === pointVenteSelected?.toString());

        let stock_disponible = functions.stock_disponible(
            stock, 
            vente
        );

        set_devise_point_de_vente(devise)
        set_stock_disponible(stock_disponible.filter(item => item.quantite > 0))
    }, [stocksList, ventesList, pointVenteSelected])



return (
    <div className=''>
        <HeaderApp
            title="Enregistrer une vente"
            links={user.profile !== "admin" ? links_client : links_admin}
        />

        <div className='flex items-start justify-between flex-col-reverse xl:flex-row xl:items-start'>
            {/* Liste de produit en stock */}
            <div className='xl:flex-grow mr-1 px-2 xl:mt-0 mt-3 bg-white p-2 rounded-lg w-full xl:w-auto'>
                <GenericTable
                    data={stock_disponible}
                    columns={columns}
                    exportation={false}
                    viewAllItems={true}
                />
            </div>

            {/* Panier */}
            {!venteSuccesful && <div className='xl:w-[400px] w-full flex flex-col justify-between min-h-[400px] border px-2 bg-white p-2 rounded-lg'>
                <div className='py-2'>
                    <h3 className='font-bold text-sm'>Produits en panier ({panier_data.length})</h3>
                </div>

                <div className='flex-grow'>
                    <div className='max-h-[400px] overflow-y-auto w-full'>
                        <table className='table border '>
                            <thead className='bg-dark text-light font-bold sticky top-0 z-10'>
                                <tr>
                                    {/* <td>ID</td> */}
                                    <td>Produits</td>
                                    <td>Prix ({getElementData(site.currency, currencies)?.iso_code})</td>
                                    <td>Qté</td>
                                    <td>Total</td>
                                    <td></td>
                                </tr>
                            </thead>

                            <tbody>
                                {panier_data.map((item, index) => {
                                    return(
                                    <tr key={index} className={`${item.quantity > item.quantite ? 'text-red-700 font-bold' : ''} `}>
                                        {/* <td className='whitespace-pre-line'>{getElementData(item.produit, produits)?.reference}</td> */}
                                        <td className='whitespace-pre-line'>{getElementData(item.produit, produits)?.designation}</td>
                                        <td>{item.prix_unitaire}</td>
                                        <td>
                                            <input
                                                min={1}
                                                max={item.quantite}
                                                onChange={(e) => {handleChangeQuantity(item.id, parseInt(e.target.value)); setErrorsPanier("")}} 
                                                value={item.quantity}
                                                name={`qte-${index}`} 
                                                type='number' 
                                                className='w-[50px] bg-[transparent] outline-[none] focus:outline-[none]'
                                            />
                                        </td>
                                        <td>{item.quantity ? item.quantity * item.prix_unitaire : 0 }</td>
                                        <td>
                                            <button onClick={() => handleDeletePanier(item.id)} className='w-[30px] h-[30px] rounded-[50%] flex justify-center items-center bg-[rgba(0,0,0,0.12)]'>
                                                <AiFillDelete className='text-dark text-lg'/>
                                            </button>
                                        </td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {panier_data.length < 1 && <div className='min-h-[150px] flex flex-col justify-end items-center'>
                        <h5 className='font-bold text-center'>Aucun produit dans le panier</h5>
                        <p className='text-sm text-center'>Veuillez ajouter des produits afin de pouvoir enregistrer une vente.</p>
                        </div>}
                </div>

                {errorsPanier && <div className='flex justify-center items-center'>
                    <span className='text-sm text-red-700 text-center'>{errorsPanier}</span>    
                </div>}

                <div className='mt-3'>
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td className='border border-gray-200 w-[50%] font-bold'>Total</td>
                                <td className='border border-gray-200 w-[50%] text-center'>{total_panier}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='flex my-2'>
                    <button
                        onClick={viderPanier} 
                        className={`flex-grow ${panier_data.length < 1 ? 'bg-gray-400' : 'bg-primary'} text-light text-sm mr-1 py-[5px] rounded-[3px]`}>Vider le panier</button>
                    <button
                        disabled={panier_data.length < 1 ? true : false}
                        onClick={validerPanier} 
                        className={`flex-grow ${panier_data.length < 1 ? 'bg-gray-400' : 'bg-secondary'} text-light text-sm ml-1 py-[5px] rounded-[3px]`}>Valider le panier</button>
                </div>
            </div>}

            {venteSuccesful && <div className='xl:w-[400px] w-full flex flex-col justify-between min-h-[400px] border px-2 bg-white p-2 rounded-lg'>
                {/* <div>
                    <h1>Vente enregistrer avec succes ! </h1>
                </div> */}

                {/* Facture */}
                <div ref={factureRef} className='print:p-2'>
                    {/* Header */}
                    <div></div>

                    {/* Content */}
                    <div>
                        <table className='table border'>
                            <thead className='bg-dark text-light font-bold'>
                                <tr>
                                    <td>ID</td>
                                    <td>Produits</td>
                                    <td>Prix ({getElementData(site.currency, currencies)?.iso_code})</td>
                                    <td>Qté</td>
                                    <td>Total</td>
                                </tr>
                            </thead>

                            <tbody>
                                {venteSuccesful.vente_items.map((item, index) => {
                                    return(
                                    <tr key={index}>
                                        <td className='whitespace-pre-line'>{getElementData(item.produit, produits)?.reference}</td>
                                        <td className='whitespace-pre-line'>{getElementData(item.produit, produits)?.designation}</td>
                                        <td>{item.prix_unitaire}</td>
                                        <td>{item.quantite}</td>
                                        <td>{item.total}</td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/* Footer */}
                    <div></div>
                </div>

                <div className='flex items-center'>

                    <button
                        onClick={() => {handlePront(); setVenteSuccesful(null); setPanier_data([]); setErrorsPanier("")}} 
                        className={`flex-grow bg-secondary text-light text-sm mr-1 py-[5px] rounded-[3px]`}>Imprimer la facture</button>

                    <button
                        onClick={() => {setVenteSuccesful(null); setPanier_data([]); setErrorsPanier("")}} 
                        className={`flex-grow bg-red-900 text-light text-sm mr-1 py-[5px] rounded-[3px]`}>Ignorer</button>
                </div>
            </div>}
        </div>
    </div>

  )
}

export default Ventes