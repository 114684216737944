import React from 'react'
import { AiFillFileImage, AiOutlineClose, AiOutlineFileImage, AiOutlineFilePdf } from 'react-icons/ai'
import { usePlateformContext } from '../../contexts'
import { BASE_URL, getElementData } from '../../constances';

const DetailsProduits = () => {

    const {produitDetailsCmponent, setProduitDetailsCmponent, produits, famillesProduits, formeProduits, voiesProduits,} = usePlateformContext();

    let produit = getElementData(produitDetailsCmponent, produits)

  return (
    <div className={`${produitDetailsCmponent ? 'flex' : 'hidden'} bg-[rgba(0,0,0,0.5)] fixed z-50 w-full h-full justify-center items-center`}>
        <div className='xl:rounded-md h-screen xl:h-[60vh] xl:w-[60%] w-screen'>
            <div className='px-2 bg-gray-300 py-3 flex items-center justify-between xl:rounded-t-md'>
                <h2 className='text-[16px] font-medium'>Details du produit | {produit?.reference}</h2>

                <button className='text-[20px] cursor-pointer' onClick={() => setProduitDetailsCmponent('')}>
                    <AiOutlineClose/>
                </button>
            </div>
            <div className='p-2 bg-white w-full h-full overflow-y-scroll flex items-start xl:flex-row flex-col xl:justify-between'>
                {/* Contenue du modal */}
                <div className='xl:w-[59%] w-[100%]'>
                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Code produit :</span>
                        <span>{produit?.reference}</span>
                    </div>

                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Désignation :</span>
                        <span>{produit?.designation}</span>
                    </div>

                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Famille :</span>
                        <span>{getElementData(produit?.famille, famillesProduits)?.label}</span>
                    </div>

                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Forme :</span>
                        <span>{getElementData(produit?.forme, formeProduits)?.label}</span>
                    </div>

                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Dosage :</span>
                        <span>{produit?.dosage}</span>
                    </div>

                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Classe :</span>
                        <span>{produit?.classe}</span>
                    </div>

                    <div className='flex flex-col pb-2 border-b-[1px] mb-2'>
                        <span className='font-medium text-md'>Voie :</span>
                        <span>{getElementData(produit?.voie, voiesProduits)?.label}</span>
                    </div>

                    <div className='flex flex-col pb-2 mb-2'>
                        <span className='font-medium text-md'>Description :</span>
                        <p>{produit?.description}</p>
                    </div>
                </div>

                <div className='xl:w-[40%] w-[100%] mb-10'>
                    {/* image du produit */}
                    {produit?.image ? (
                        <img className='w-[100%] border-[1px] mb-2 h-[250px] object-contain rounded-md' src={BASE_URL + produit?.image} alt='produit'/>

                        ) : (
                            <span className='flex text-gray-500 text-[80px] justify-center items-center w-[100%] border-[1px] mb-2 h-[250px] object-contain rounded-md'>
                                <AiOutlineFileImage/>
                            </span>
                        )}

                    
                    <div className='w-full flex items-center'>
                        <img className='w-[50px] h-[50px] rounded-md mr-2 object-contain border-[1px]' src={BASE_URL + produit?.image_qrcode} alt='QR CODE du produit'/>
                        
                        <button className='flex items-center bg-red-800 h-[45px] rounded-md w-full justify-center text-white'>
                            <span className='text-lg mr-2'>
                                <AiOutlineFilePdf/>
                            </span>
                            <span className='text-md font-medium'>Codes QR (A4 format)</span>
                        </button>
                    </div>

                    <div className='my-2'>
                        {/* La fiche produit */}
                        <button className='flex items-center bg-green-800 h-[45px] rounded-md w-full justify-center text-white'>
                            <span className='text-lg mr-2'>
                                <AiOutlineFilePdf/>
                            </span>
                            <span className='text-md font-medium'>Fiche produit</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default DetailsProduits