import React from 'react'
import { GenericTable, HeaderApp } from '../../../components'
import { usePlateformContext } from '../../../contexts';
import { FormatDate, FormatDateTime, FormatPrice, formaterDate, getElementData } from '../../../constances/functions';
import { AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { datas, functions } from '../../../constances';

const CompteClient = () => {

    const {compteClientList, subscriptions, currencies, countries, user} = usePlateformContext();
    const navigate = useNavigate(); 

    const columns = [
        { key: "forfait", label: "Abonnement" },
        { key: "price", label: "Montant", value: row => FormatPrice(row.price) },
        { key: "devise", label: "Devise"},
        { key: "days", label: "Durée (jour)"},
        { key: "debut", label: "Debut", value: row => FormatDateTime(row.debut)},
        { key: "fin", label: "Fin", value: row => FormatDateTime(row.fin)},
        { key: "status", label: "Status", value: row => row.status ? <div className='flex items-center'>
            <span className='block bg-green-700 w-3 h-3 rounded-full'></span>
            <span className='text-sm font-light ml-2'>En cours</span>
        </div> : 
        <div className='flex items-center'>
            <span className='block bg-red-700 w-3 h-3 rounded-full'></span>
            <span className='text-sm font-light ml-2'>obsolète</span>
        </div>},
    ]


    const columns_comptes_clients = [
        {key: "unique_identification_number", label: "N°", value: row => `${row.numero}`},
        {key: "name_of_structure", label: "Nom"},
        {key: "legal_form", label: "Status"},
        {key: "commerce_registry", label: "RCCM"},
        {key: "country", label: "Pays", value: row => `${getElementData(row.country, countries)?.label}`},
        {key: "address", label: "Adresse"},
        {key: "phone", label: "Téléphone"},
        {key: "email", label: "E-mail"},
        // {key: "validated", label: "Status", value: row => `${row.validated ? 'actif' : 'inactif'}`},
        {key: "updated", label: "", value: row => `${formaterDate(row.updated)}`.toUpperCase()},

        (functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_COMPTE_CLIENT], user.profile_permissions_list)) && {key: "", value: row => <div className='flex items-center w-[100px]'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/comptes-clients/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>
        </div>}
    ]

    console.log(compteClientList)

  return (
    <div>
        <HeaderApp
            title="Comptes clients"
            links={[
                {
                    description: "Liste",
                    path: "",
                    icon: "",
                    active: true
                },
                {
                    description: 'Nouveau',
                    path: "/comptes-clients/create",
                    icon: "",
                }
            ]}
        />

        <div></div>

        <div className='p-2 bg-white rounded-lg'>
            <GenericTable
                data={compteClientList}
                columns={columns_comptes_clients}

            />
        </div>
    </div>
  )
}

export default CompteClient