import React, { useState } from 'react';
import './FormInput.css';
import { AiOutlineEye } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const FormInput = ({
  className, 
  error, 
  label, 
  name, 
  type, 
  placeholder, 
  required,
  value,
  onChange,
  onFocus = () => {},
  disabled=false,
  autoComplete='on',
  password=false
}) => {

  const [visiblePassword, setVisiblePassword] = useState(false)

  return (
    <div className={`${className} form-input my-2`}>

        <span className='form-label'>{label}<span>{required && '*'}</span></span>

        <div className={`form-input-section ${error && 'error'}`}>
            <input 
                type={visiblePassword ? 'text' : password ? 'password' : type}
                name={name}
                placeholder={placeholder}
                className='form-input-section-input'
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                autoComplete={autoComplete}
            />

            {password && <a onClick={() => setVisiblePassword(!visiblePassword)} className='bg-gray-200 text-lg border-[1px] text-gray-900 w-[40px] h-[45px] flex justify-center items-center cursor-pointer'>
              {visiblePassword ? <FaEye/> : <FaEyeSlash/>}
            </a>}
        </div>

        <span className='form-input-error'>{error}</span>
    </div>
  )
}

export default FormInput