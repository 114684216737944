import React, { useEffect, useState } from 'react'
import { ApexChartComp, GenericTable, HeaderApp } from '../../../components'
import { usePlateformContext } from '../../../contexts'
import { AiFillPieChart, AiFillShop, AiOutlineBarChart, AiOutlineEdit, AiOutlineTable } from 'react-icons/ai';
import { datas, functions, getElementData } from '../../../constances';
import { useNavigate } from 'react-router-dom';
import { FormatDateTime, FormatPrice } from '../../../constances/functions';

const Rapports = () => {

    const navigate = useNavigate();

    const {
        user, 
        sites,
        stocksList,
        ventesList,
        setIsLoading,
        currencies,
        fournisseurs,
        agents,
        produits,
        famillesProduits,
        voiesProduits,
        formeProduits,
        pointVenteSelected,
        countries
    } = usePlateformContext();

    const [credentials, setCredentials] = useState({
        point_vente: user.profile === 'admin' ? '' : pointVenteSelected.toString(),
        type_rapport: '',
        date_debut: new Date() - 10,
        date_fin: new Date(),
        pays: '',
        ville: ''
    });

    const [values_rapport, set_values_rapport] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }


    const [style_view, set_style_view] = useState('table');

    const [data_stock_historique, set_stock_historique] = useState([]);
    const [data_vente_historique, set_vente_historique] = useState([]);
    const [data_quantite_produit_vendu, set_quantite_produit_vendu] = useState([]);
    const [data_stock_disponible, set_stock_disponible] = useState([]);

    const [chartSeriesClientTopProduitVenteQuantite, setChartSeriesClientTopProduitVenteQuantite] = useState([]);
    const [chartSeriesClientTopProduitVenteCategories, setChartSeriesClientTopProduitVenteCategories] = useState([]);

    const [options_pays, set_options_pays] = useState([]);
    const [options_ville, set_options_ville] = useState([]);
    const [options_sites, set_options_sites] = useState([]);

    const options_type_rapports = [
        {permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.GENERER_RAPPORT_HISTORIQUE_STOCK], user.profile_permissions_list), value: 'stock_historique', label: 'Historique de stock'},
        {permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.GENERER_RAPPORT_HISTORIQUE_VENTE], user.profile_permissions_list), value: 'vente_historique', label: 'Historique de vente'},
        {permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.GENERER_RAPPORT_QUANTITE_PRODUIT_VENDU], user.profile_permissions_list), value: 'quantite_produit_vendu', label: 'Quantité de produits vendus'},
    ]

    const columns_stock_historique = [
        {key: "code_produit", label: "ID", value: row => functions.getElementData(row.produit, produits)?.reference},
        {key: "designation", label: "Produit", value: row => functions.getElementData(row.produit, produits)?.designation},
        {key: "quantite", label: "Quantité", value: row => row.quantite},
        {key: "prix_unitaire", label: "Prix unitaire", value: row => `${functions.FormatPrice(row.prix_unitaire)} ${functions.getElementData(functions.getElementData(row.site, sites)?.currency, currencies)?.iso_code}`},
        {key: "prix_achat", label: "Prix d'achat", value: row => `${functions.FormatPrice(row.prix_achat)} ${functions.getElementData(functions.getElementData(row.site, sites)?.currency, currencies)?.iso_code}`},
        {key: "date_exp", label: "Date de exp", value: row => <span className='capitalize'>{functions.FormatDate(row.date_exp)}</span>},
        {key: "fournisseur", label: "Fournisseur", value: row => row.fournisseur ? functions.getElementData(row.fournisseur, fournisseurs)?.designation : "-"},
        {key: "user", label: "Agent", value: row => row.user !== user?.id ? functions.getElementData(row.user, agents)?.user_id : user?.user_id},
        {key: "date_creation", label: "Date", value: row => <span className='capitalize'>{functions.FormatDateTime(row.date_creation)}</span>},


        (functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_STOCK], user.profile_permissions_list)) && {key: "", value: row => <div className='flex items-center'>
            {(user.id === row.user) && <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/stocks/historique/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>}
        </div>}
    ]

    const columns_vente_historique = [
        {key: "facture", label: "N° Facture", value: row => row.facture},
        {key: "total", label: "Montant", value: row => row.total},
        {key: "vente_items", label: "Nombre de produit", value: row => row.vente_items.length},
        user.profile !== 'admin' && {key: "user", label: "Agent", value: row => row.user === user?.id ? user.user_id : getElementData(row.user, agents)?.user_id},
        // {key: "currency", label: "Devise", value: row => row.currency},
        {key: "date_creation", label: "Date", value: row => <span className='capitalize'>{FormatDateTime(row.date_creation)}</span>},
    ]

    const columns_quantite_produit_vendu = [
        { key: "", label: "ID", value: row => getElementData(row.produit, produits)?.reference },
        { key: "produit", label: "Designation", value: row => getElementData(row.produit, produits)?.designation },
        { key: "", label: "Famille", value: row => getElementData(getElementData(row.produit, produits)?.famille, famillesProduits)?.label },
        { key: "", label: "Forme", value: row => getElementData(getElementData(row.produit, produits)?.forme, formeProduits)?.label },
        { key: "", label: "Dosage", value: row => getElementData(row.produit, produits)?.dosage },
        { key: "", label: "Voie", value: row => getElementData(getElementData(row.produit, produits)?.voie, voiesProduits)?.label },
        { key: "totalQuantite", label: "Qté vendue" },
        // (user.profile === "client-admin" || credentials.point_vente) && { key: "totalMontant", label: "Total des ventes", value: row => `${FormatPrice(row.totalMontant)} ${getElementData(getElementData(credentials.point_vente, sites)?.currency, currencies)?.iso_code}`},
        // credentials.point_vente && { key: "total", label: "Prix u.", value: row => `${FormatPrice(data_stock_disponible.filter(item => item.produit === row.produit)[0]?.prix_unitaire)} ${getElementData(getElementData(credentials.point_vente, sites)?.currency, currencies)?.iso_code}` },  
    ];


    const hundleGenerate = () => {
        setIsLoading(true);
        set_values_rapport(credentials);

        if (credentials.type_rapport === "stock_historique") {
            let site_list = sites.filter(item => (credentials.pays ? item.country.toString() === credentials.pays.toString() : true) && (credentials.ville ? item.city.toString() === credentials.ville.toString() : true))
            .map(item => {
                return item.id
            })

            let data_stock_historique = stocksList.filter(item => site_list.includes(item.site) && functions.isDateBetween(credentials.date_debut, credentials.date_fin, item.date_creation));
            set_stock_historique(data_stock_historique)
        } else {
            set_stock_historique([]);
        }

        if (credentials.type_rapport === "vente_historique") {
            let site_list = sites.filter(item => (credentials.pays ? item.country.toString() === credentials.pays.toString() : true) && (credentials.ville ? item.city.toString() === credentials.ville.toString() : true))
            .map(item => {
                return item.id
            })

            let data_vente_historique = ventesList.filter(item => site_list.includes(item.site) && functions.isDateBetween(credentials.date_debut, credentials.date_fin, item.date_creation));
            set_vente_historique(data_vente_historique)
        } else {
            set_vente_historique([]);
        }

        if (credentials.type_rapport === "quantite_produit_vendu") {

            let site_list = sites.filter(item => (credentials.pays ? item.country.toString() === credentials.pays.toString() : true) && (credentials.ville ? item.city.toString() === credentials.ville.toString() : true))
            .map(item => {
                return item.id
            })

            let stock_intervalle = stocksList.filter(item => site_list.includes(item.site) && functions.isDateBetween(credentials.date_debut, credentials.date_fin, item.date_creation));
            let vente_intervalle = ventesList.filter(item => site_list.includes(item.site) && functions.isDateBetween(credentials.date_debut, credentials.date_fin, item.date_creation));

            let data_stock = !credentials.point_vente ? stock_intervalle : stock_intervalle.filter(item => item.site.toString() === credentials.point_vente);
            let data_vente = !credentials.point_vente ? vente_intervalle : vente_intervalle.filter(item => item.site.toString() === credentials.point_vente);


            // Données pour les produits vendus
            let DATA_TOP_PRODUITS = functions.vente_par_produit(data_vente)
            let DATA_TABLE = DATA_TOP_PRODUITS;

            let data = [];
            let categories = [];
            let DATAS = DATA_TOP_PRODUITS;

            DATAS.forEach(item => {
            data.push(item.totalQuantite);
                categories.push(`${getElementData(item.produit, produits)?.designation} (${getElementData(item.produit, produits)?.dosage})`);
            });

            setChartSeriesClientTopProduitVenteQuantite([{name: 'Quantité de produits vendus', data: data}]);
            setChartSeriesClientTopProduitVenteCategories(categories);

            set_stock_disponible(data_stock)
            set_quantite_produit_vendu(DATA_TABLE.sort((a, b) => a.totalQuantite - b.totalQuantite))
        } else {
            set_quantite_produit_vendu([]);
            set_stock_disponible([]);
        }

        setTimeout(() => {
            // Ramener a false
            setIsLoading(false);
        }, 2000);
    }

    const hundleReset = () => {
        set_stock_historique([]);
        set_vente_historique([]);
        set_quantite_produit_vendu([]);
        set_stock_disponible([]);
        set_values_rapport({});
        setChartSeriesClientTopProduitVenteCategories([]);
        setChartSeriesClientTopProduitVenteQuantite([]);
        set_style_view('table');

        setCredentials({
            point_vente: user.profile === 'admin' ? '' : pointVenteSelected.toString(),
            type_rapport: '',
            date_debut: new Date() - 10,
            date_fin: new Date(),
            pays: '',
            ville: ''
        });
    }

    // console.log(credentials)

    useEffect(() => {
        set_stock_historique([]);
        set_vente_historique([]);
        set_quantite_produit_vendu([]);
        set_stock_disponible([]);
        set_values_rapport({});
        setChartSeriesClientTopProduitVenteCategories([]);
        setChartSeriesClientTopProduitVenteQuantite([]);
        set_style_view('table');

        setCredentials({
            point_vente: user.profile === 'admin' ? '' : pointVenteSelected.toString(),
            type_rapport: '',
            date_debut: new Date() - 10,
            date_fin: new Date(),
            pays: '',
            ville: ''
        });
    }, [pointVenteSelected, user])


    useEffect(() => {
        let countries_list = [];
        
        countries.map(item => countries_list.push({label: item.label, value: item.id}))

        set_options_pays(countries_list);
    }, [countries])


    useEffect(() => {
        let ville_list = [];
        
        countries.filter(item => item.id.toString() === credentials.pays.toString())[0]?.cities?.map(item => ville_list.push({label: item.label, value: item.id}))

        set_options_ville(ville_list);
    }, [countries, credentials.pays])


    useEffect(() => {
        let sites_list = [];
        let sites_filter = sites.filter(item => (credentials.pays ? item.country.toString() === credentials.pays.toString() : true) && (credentials.ville ? item.city.toString() === credentials.ville.toString() : true))
        
        sites_filter.map(item => sites_list.push({label: item.label, value: item.id}))

        set_options_sites(sites_list);
    }, [countries, credentials.pays, credentials.ville])


    // Colonnes pour le tableau
    // Données pour le graphique des produits vendus
    //   

    //   useEffect(() => {
        
    //   }, [user, ventesList]);

  return (
    <div className='w-full'>
        <HeaderApp
            title="Rapports"
            links={[]}
        />

        {(!values_rapport.type_rapport) ? 
            <>

                {user.profile === "admin" &&  <div className='p-3 rounded-md bg-white mb-3 flex flex-col xl:flex-row'>
                    
                    <label htmlFor='pays' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[100%] xl:mb-auto mb-3 xl:mr-2'>
                        <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                            <AiFillShop />
                        </span>

                        <select onChange={(val) => setCredentials({...credentials, ['ville'] : '', ['point_vente'] : '', [val.target.name]: val.target.value})} id='pays' value={credentials.pays} name='pays' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'>
                            <option value={''} className='py-3'>Tous pays</option>
                            {options_pays.map((item, index) => <option key={index} value={item.value} className='text-black'>{item.label}</option>)}
                        </select>
                    </label>
            

                    <label htmlFor='villes' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[100%] xl:mb-auto mb-3 xl:mr-2'>
                        <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                            <AiFillShop />
                        </span>

                        <select onChange={(val) => setCredentials({...credentials, ['point_vente'] : '', [val.target.name]: val.target.value})} id='villes' value={credentials.ville} name='ville' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'>
                            <option value={''} className='py-3'>Toutes les villes</option>
                            {options_ville.map((item, index) => <option key={index} value={item.value} className='text-black'>{item.label}</option>)}
                        </select>
                    </label>
            

                    <label htmlFor='point_de_vente' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[100%] xl:mb-auto mb-3 xl:mr-2'>
                        <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                            <AiFillShop />
                        </span>

                        <select onChange={handleChange} id='point_de_vente' value={credentials.point_vente} name='point_vente' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'>
                            <option value={''} className='py-3'>Tous les points de vente</option>
                            {options_sites.map((item, index) => <option key={index} value={item.value} className='text-black'>{item.label}</option>)}
                        </select>
                    </label>

                </div>}

                <div className='p-3 rounded-md bg-white mb-3 flex flex-col xl:flex-row xl:items-center xl:justify-between'>
                    <label htmlFor='type_rapport' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-auto w-[100%] xl:mb-auto mb-2 xl:mr-2'>
                        <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                            <AiOutlineBarChart />
                        </span>

                        <select onChange={handleChange} id='type_rapport' value={credentials.type_rapport} name='type_rapport' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'>
                            <option value={''} className='py-3'>Selectionner l'objet du rapport</option>
                            {options_type_rapports.filter(item => item.permission).map((item, index) => <option key={index} value={item.value} className='text-black'>{item.label}</option>)}
                        </select>
                    </label>

                    <div className='flex flex-col xl:flex-row xl:items-center'>
                        {(true) && <div className='flex flex-col xl:flex-row xl:items-center'>
                            <span className='xl:mr-2 xl:my-auto mb-0 text-md'>Générer le rapport du</span>

                            <label htmlFor='date_debut' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[100%] xl:mb-auto mb-2 xl:mr-2'>
                                {/* <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                                    <AiFillShop />
                                </span> */}

                                <input
                                    type='date'
                                    onChange={handleChange} 
                                    id='date_debut' 
                                    value={credentials.date_debut} 
                                    name='date_debut'
                                    className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'
                                />
                            </label>

                            <span className='xl:mr-2 xl:my-auto mb-0 text-md'>au</span>

                            <label htmlFor='date_fin' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[100%] xl:mb-auto mb-2 xl:mr-2'>
                                {/* <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                                    <AiFillShop />
                                </span> */}

                                <input
                                    type='date'
                                    onChange={handleChange} 
                                    id='date_fin' 
                                    value={credentials.date_fin} 
                                    name='date_fin'
                                    className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'
                                />
                            </label>
                        </div>}

                        <button onClick={hundleGenerate} className='bg-secondary text-white rounded-md xl:py-1 px-2 py-2 xl:mt-auto mt-2 cursor-pointer '>
                            <span>Générer le rapport</span>
                        </button>
                    </div>
                </div>

            </> : 
            <>
                <div className='rounded-md flex flex-col xl:flex-row xl:items-center xl:justify-end'>
                    <button onClick={hundleReset} className='bg-secondary text-white rounded-md xl:py-1 px-2 py-2 xl:mt-auto mt-2 cursor-pointer '>
                        <span>Générer le rapport</span>
                    </button>
                </div>
            </>
        }

        {values_rapport.type_rapport === "stock_historique" && <div className='w-full'>
            <div className='w-full bg-white rounded-lg p-2 my-3 flex justify-between items-center'>
            <div>
                <h3 className='font-medium text-md'>Rapports sur l'historique de stock</h3>
                <p className='font-light text-sm'>Rapports sur l'historique de stock</p>
            </div>

            {(false) && (
                <div className='flex items-center'>
                <button 
                    // onClick={() => setTopView("table")} 
                    className={`p-2 text-lg rounded-md cursor-pointer mr-2  ${(true) ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineTable />
                </button>

                <button 
                    // onClick={() => setTopView("chart")} 
                    className={`p-2 text-lg rounded-md cursor-pointer ml-2  ${(true) ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineBarChart />
                </button>
                </div>
            )}
            </div>

            <div className='p-2 bg-white rounded-lg'>
                <GenericTable
                    data={data_stock_historique}
                    columns={columns_stock_historique}
                />
            </div>
        </div>}

        {values_rapport.type_rapport === "vente_historique" && <div className='w-full'>
            <div className='w-full bg-white rounded-lg p-2 my-3 flex justify-between items-center'>
            <div>
                <h3 className='font-medium text-md'>Rapports sur l'historique de vente</h3>
                <p className='font-light text-sm'>Rapports sur l'historique de vente</p>
            </div>

            {(false) && (
                <div className='flex items-center'>
                <button 
                    // onClick={() => setTopView("table")} 
                    className={`p-2 text-lg rounded-md cursor-pointer mr-2  ${(true) ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineTable />
                </button>

                <button 
                    // onClick={() => setTopView("chart")} 
                    className={`p-2 text-lg rounded-md cursor-pointer ml-2  ${(true) ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineBarChart />
                </button>
                </div>
            )}
            </div>

            <div className='p-2 bg-white rounded-lg'>
                <GenericTable
                    data={data_vente_historique}
                    columns={columns_vente_historique}
                />
            </div>
        </div>}

        {values_rapport.type_rapport === "quantite_produit_vendu" && <div className='w-full'>
            <div className='w-full bg-white rounded-lg p-2 my-3 flex justify-between items-center'>
              <div>
                <h3 className='font-medium text-md'>Rapports sur la quantité de produits vendus</h3>
                <p className='font-light text-sm'>Rapports sur la quantité de produits vendus</p>
              </div>

              {(true) && (
                <div className='flex items-center'>
                  <button 
                    onClick={() => set_style_view("table")} 
                    className={`p-2 text-lg rounded-md cursor-pointer mr-2  ${(style_view === 'table') ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineTable />
                  </button>

                  <button 
                    onClick={() => set_style_view("chart")} 
                    className={`p-2 text-lg rounded-md cursor-pointer ml-2  ${(style_view === 'chart') ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineBarChart />
                  </button>
                </div>
              )}
            </div>

            <div className='p-2 bg-white rounded-lg'>
                {style_view === 'table' ? 
                    <GenericTable
                        data={data_quantite_produit_vendu}
                        columns={columns_quantite_produit_vendu}
                    /> 
                    : 
                    <ApexChartComp 
                        series={chartSeriesClientTopProduitVenteQuantite}
                        categories={chartSeriesClientTopProduitVenteCategories}
                        type={"bar"}
                        height={600}
                    />
                }
            </div>
        </div>}

    </div>
  )
}

export default Rapports