import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'

const CheckBoxList = ({
    list,
    label,
    item,
    onPress
}) => {


  return (
    <div  className='w-[100%] md:w-[50%] my-2 flex items-center'>
        {list?.includes(item) ? <FaCheck className='text-[green] mr-2 text-sm'/> : <AiOutlineClose className='text-[red] mr-2 text-sm'/>}

        <label 
            onClick={onPress}
            className='cursor-pointer text-sm'>{label}</label>
    </div>
  )
}

export default CheckBoxList