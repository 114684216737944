import React from 'react'
import { BASE_URL, IMAGES, getElementData } from '../../constances'
import { usePlateformContext } from '../../contexts'

const IsLoadingComponent = ({ visible }) => {
  const { user, pointVenteSelected, sites } = usePlateformContext();

  let point_de_vente = getElementData(pointVenteSelected, sites);

  return (
    <div className={`${visible ? 'flex' : 'hidden'} fixed z-[20000] bg-white w-screen h-screen justify-center items-center`}>
      <div className='flex flex-col justify-center items-start cursor-none select-none'>
        <div className='flex flex-row items-center'>
          <img src={(point_de_vente?.logo && user.profil !== "admin") ? BASE_URL + point_de_vente?.logo : IMAGES.logo} alt='log Mopesi' className='w-[100px] object-contain' />
          <img src={IMAGES.loading_btn} alt='log Mopesi' className='w-[40px] ml-5' />
        </div>
        {/* <span className='text-[12px]'>Chargement ...</span> */}
      </div>
    </div>
  )
}

export default IsLoadingComponent;
