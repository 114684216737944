import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Abonnements, AgentAdd, AgentEdit, AgentList, AgentProfileAdd, AgentProfileEdit, AgentProfilesList, CompteClient, CompteClientAdd, CompteClientEdit, Fournisseur, FournisseurAdd, FournisseurEdit, HomePagePlateform, LoginPage, OTPPage, Produit, ProduitAdd, ProduitEdit, ProfilePage, Rapports, SiteAdd, SiteEdit, SiteList, Stock, StockAdd, StockEdit, StockHistorique, Ventes, VentesHistorique } from '../pages'
import { PrivateRoot } from '../components'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Router = () => {
  return (
    <div>
        <ToastContainer
            position='bottom-right'

            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"

            style={{
                fontSize: 13,
                lineHeight: 1.3
            }}
        />
        
        <BrowserRouter>
            <Routes>

                {/* Authentification */}
                <Route exact path={`/login`} element={<LoginPage/>} />
                <Route exact path={`/otp`} element={<OTPPage/>} />
            
                {/* Tableau de bord */}
                <Route path={`/`} element={<PrivateRoot/>}>
                    <Route exact path={`/`} element={<HomePagePlateform/>} />
                </Route>

                {/* Les routes possibles */}
                {/* Produit */}
                <Route path={`/profil`} element={<PrivateRoot/>}>
                    <Route exact path={`/profil`} element={<ProfilePage/>} />
                </Route>

                {/* Compte client */}
                <Route path={`/points-ventes/compte/list`} element={<PrivateRoot/>}>
                    <Route exact path={`/points-ventes/compte/list`} element={<CompteClient/>} />
                </Route>

                <Route path={`/points-ventes/compte/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/points-ventes/compte/create`} element={<CompteClientAdd/>} />
                </Route>

                {/* Point de vente */}
                <Route path={`/points-ventes/list`} element={<PrivateRoot/>}>
                    <Route exact path={`/points-ventes/list`} element={<SiteList/>} />
                </Route>

                <Route path={`/points-ventes/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/points-ventes/create`} element={<SiteAdd/>} />
                </Route>

                <Route path={`/points-ventes/update/:id_site`} element={<PrivateRoot/>}>
                    <Route exact path={`/points-ventes/update/:id_site`} element={<SiteEdit/>} />
                </Route>


                {/* Personnels */}
                <Route path={`/utilisateurs/list`} element={<PrivateRoot/>}>
                    <Route exact path={`/utilisateurs/list`} element={<AgentList/>} />
                </Route>

                <Route path={`/utilisateurs/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/utilisateurs/create`} element={<AgentAdd/>} />
                </Route>

                <Route path={`/utilisateurs/list/update/:id_personnel`} element={<PrivateRoot/>}>
                    <Route exact path={`/utilisateurs/list/update/:id_personnel`} element={<AgentEdit/>} />
                </Route>

                {/* Profiles personnels */}
                <Route path={`/profiles-users/list`} element={<PrivateRoot/>}>
                    <Route exact path={`/profiles-users/list`} element={<AgentProfilesList/>} />
                </Route>

                <Route path={`/profiles-users/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/profiles-users/create`} element={<AgentProfileAdd/>} />
                </Route>

                <Route path={`/profiles-users/update/:id_profile`} element={<PrivateRoot/>}>
                    <Route exact path={`/profiles-users/update/:id_profile`} element={<AgentProfileEdit/>} />
                </Route>


                {/* Fournisseur */}
                <Route path={`/fournisseurs/list`} element={<PrivateRoot/>}>
                    <Route exact path={`/fournisseurs/list`} element={<Fournisseur/>} />
                </Route>

                <Route path={`/fournisseurs/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/fournisseurs/create`} element={<FournisseurAdd/>} />
                </Route>

                <Route path={`/fournisseurs/list/update/:id_fournisseur`} element={<PrivateRoot/>}>
                    <Route exact path={`/fournisseurs/list/update/:id_fournisseur`} element={<FournisseurEdit/>} />
                </Route>

                {/* Produit */}
                <Route path={`/produits/list`} element={<PrivateRoot/>}>
                    <Route exact path={`/produits/list`} element={<Produit/>} />
                </Route>

                <Route path={`/produits/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/produits/create`} element={<ProduitAdd/>} />
                </Route>

                <Route path={`/produits/list/update/:id_produit`} element={<PrivateRoot/>}>
                    <Route exact path={`/produits/list/update/:id_produit`} element={<ProduitEdit/>} />
                </Route>


                <Route path={`/comptes-clients/update/:id_compte_client`} element={<PrivateRoot/>}>
                    <Route exact path={`/comptes-clients/update/:id_compte_client`} element={<CompteClientEdit/>} />
                </Route>

                {/* Gestion de point de vente */}
                {/* Abonnements */}
                <Route path={`/abonnements/historique`} element={<PrivateRoot/>}>
                    <Route exact path={`/abonnements/historique`} element={<Abonnements/>} />
                </Route>

                <Route path={`/abonnements/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/abonnements/create`} element={<Abonnements/>} />
                </Route>

                {/* Ventes */}
                <Route path={`/ventes/historique`} element={<PrivateRoot/>}>
                    <Route exact path={`/ventes/historique`} element={<VentesHistorique/>} />
                </Route>

                <Route path={`/ventes/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/ventes/create`} element={<Ventes/>} />
                </Route>

                {/* Stocks */}
                <Route path={`/stocks/disponible`} element={<PrivateRoot/>}>
                    <Route exact path={`/stocks/disponible`} element={<Stock/>} />
                </Route>

                <Route path={`/stocks/create`} element={<PrivateRoot/>}>
                    <Route exact path={`/stocks/create`} element={<StockAdd/>} />
                </Route>

                <Route path={`/stocks/historique`} element={<PrivateRoot/>}>
                    <Route exact path={`/stocks/historique`} element={<StockHistorique/>} />
                </Route>

                <Route path={`/stocks/historique/update/:id_stock`} element={<PrivateRoot/>}>
                    <Route exact path={`/stocks/historique/update/:id_stock`} element={<StockEdit/>} />
                </Route>

                {/* Alertes */}

                {/* Rapports */}
                <Route path={`/rapports`} element={<PrivateRoot/>}>
                    <Route exact path={`/rapports`} element={<Rapports/>} />
                </Route>



            </Routes>
        </BrowserRouter>
    </div>
  )
}

export default Router