import React, { useRef, useState } from 'react'
import { AiOutlineClose, AiOutlineEdit, AiOutlineFilePdf, AiOutlinePrinter } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { GenericTable, HeaderApp, HomeMain } from '../../../components';
import { URL_PLATEFORM, checkPermission, datas, functions, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
 
const SiteList = () => {

    const {sites, agents, currencies, countries, user} = usePlateformContext();

    const navigate = useNavigate();

    let data_exists = false;

    const componentRef = useRef();

    const handlePront = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'liste-des-agents',
        onAfterPrint: () => console.log("print successfuly")
    });


    const columns = [
        { key: "unique_identification_number", label: "N°", value: row => `${row.unique_identification_number}` },
        { key: "label", label: "Description" },
        { key: "type_site", label: "Point de vente" },
        { key: "currency", label: "Devise", value: row => getElementData(row.currency, currencies)?.label },
        { key: "address", label: "Adresse" },
        { key: "country", label: "Pays", value: row => getElementData(row.country, countries)?.label},
        { key: "city", label: "Ville", value: row => getElementData(row.city, getElementData(row.country, countries)?.cities)?.label },
        { key: "", label: "Status", value: row =>  <span>Actif</span>},
        (functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_POINT_DE_VENTE], user.profile_permissions_list)) && { key: "", value: row => <div className='flex items-center max-w-[100px]'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/points-ventes/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>
        </div>}
    ];

  return (
    <div>
        <HeaderApp
            title="Point de vente"
            links={[
                {
                    description: "Liste",
                    path: "/points-ventes",
                    icon: "",
                    active: true
                },
                {
                    description: "Nouveau",
                    path: "/points-ventes/create",
                    icon: "",
                }
            ]}
        />

        <div 
            className='p-2 bg-white rounded-lg'
        >
            {/* Section de recherche et fitre du tableau - exporter */}
            <div></div>

            <div ref={componentRef} className='print:p-2'>
                <div className=''>
                    <GenericTable
                        data={sites}
                        columns={columns}

                    />
                </div>


                {/* {!data_exists && <div className='w-[100%] min-h-[50vh] flex flex-col justify-center items-center'>
                        <FaUserSlash className='text-[50px] text-[var(--gray)]'/>
                        <span className='text-xl mt-3 text-[var(--gray)]'>Aucun agent enregistré</span>
                </div>} */}
            </div>

        </div>
    </div>
  )
}

export default SiteList