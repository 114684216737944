import React, { useRef } from 'react'
import { AiOutlineClose, AiOutlineEdit, AiOutlineFilePdf, AiOutlinePrinter } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { GenericTable, HeaderApp, HomeMain } from '../../../components';
import { URL_PLATEFORM, checkPermission, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
 
const Fournisseur = () => {

    const {fournisseurs, agents, currencies, countries, user} = usePlateformContext();

    const navigate = useNavigate();

    let data_exists = false;

    const componentRef = useRef();

    const handlePront = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'liste-des-fournisseur',
        onAfterPrint: () => console.log("print successfuly")
    });

    const columns = [
        {key: "designation", label: "Désignation"},
        {key: "telephone", label: "Téléphone"},
        {key: "email", label: "E-mail"},
        {key: "address", label: "Adresse", value: row => row.adresse ? row.adresse : '-'},
        {key: "", value: row => <div className='flex items-center w-[100px]'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/fournisseurs/list/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>
        </div>}
    ]

  return (
    <div className=''>
        <HeaderApp
            title="Forunisseurs"
            links={[
                {
                    description: "Liste",
                    path: "/fournisseurs",
                    icon: "",
                    active: true
                },
                {
                    description: "Nouveau",
                    path: "/fournisseurs/create",
                    icon: "",
                }
            ]}
        />
        
        <div ref={componentRef} className='print:p-2'>

            <div 
                className='p-2 bg-white rounded-lg'
            >

                <GenericTable
                    data={fournisseurs}
                    columns={columns}
                />

            </div>
        </div>
    </div>
  )
}

export default Fournisseur