import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { BtnForm, FormInput, FormSelect, HeaderApp, HomeMain } from '../../../components';
import { FaPlus } from 'react-icons/fa';
import { AiOutlinePrinter } from 'react-icons/ai';

const FournisseurAdd = () => {

  const navigate = useNavigate();

  const {currencies, user, create_fournisseur, countries} = usePlateformContext();

  const model_data = {
    structure: user ? user.structure : '',
    designation: '',
    telephone: '',
    email: '',
    adresse: '',
  }

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }


  const handleSubmit = async (e) => {
      e.preventDefault()

      // Validation du formulaire

      const errors = {}; // Objet pour stocker les erreurs

      if (!credentials.designation) {
          errors.designation = "Entrer la designation du fournisseur";
      }

      if (!credentials.telephone) {
          errors.telephone = "Entrer le numéro de téléphone";
      }

      if (!credentials.email) {
          errors.email = "Entrer l'adresse e-mail";
      }


      if (Object.keys(errors).length > 0) {
          setCredentialsError(errors);
      } else {
        let response = await create_fournisseur(credentials);

        if (response) {
          setCredentials(model_data);
          navigate("/fournisseurs/list");
        }
      }
      
  }


  const [optionsCountries, setOptionsCountries] = useState([]);
  const [optionsCities, setOptionsCities] = useState([]);
  const [optionsCurrencies, setOptionsCurrencies] = useState([]);

  useEffect(() => {
    let countries_list = countries.map((item) => {
      return {label: item.label, value: item.id}
    });

    setOptionsCountries(countries_list)
  }, [countries])

  useEffect(() => {

      let country_selected = countries.filter((item) => item.id === (credentials.country ? parseInt(credentials.country) : ''))[0]

      setOptionsCities(country_selected?.cities?.map((item) => {
        return {label: item.label, value: item.id}
      }))
  }, [credentials.country, countries, credentials])

  // useEffect(() => {
  //   let currencies_list = currencies.map((item) => {
  //     return {label: item.iso_code, value: item.id}
  //   });

  //   setOptionsCurrencies(currencies_list)
  // }, [currencies])

  return (
    <div>
      <HeaderApp
            title="Forunisseurs"
            links={[
                {
                    description: "Liste",
                    path: "/fournisseurs",
                    icon: "",
                },
                {
                    description: "Nouveau",
                    path: "",
                    icon: "",
                    active: true
                }
            ]}
        />

      <div className='flex flex-col justify-start items-center'>
          <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3'>
          <FormInput
              type={"text"}
              label={"Designation du fournisseur"}
              required={true}
              placeholder={"Designation"}
              autoComplete='off'
              value={credentials.designation}
              error={credentialsError.designation}
              onChange={handleChange}
              onFocus={() => handleChangeError("designation", null)}
              name={"designation"}
          />

          <FormInput
              type={"text"}
              required={true}
              placeholder={"Numéro de téléphone"}
              label={"Téléphone"}
              value={credentials.telephone}
              error={credentialsError.telephone}
              onChange={handleChange}
              onFocus={() => handleChangeError("telephone", null)}
              name={"telephone"}
          />

          <FormInput
              type={"email"}
              required={true}
              placeholder={"exemple@site.com"}
              label={"E-mail"}
              value={credentials.email}
              error={credentialsError.email}
              onChange={handleChange}
              onFocus={() => handleChangeError("email", null)}
              name={"email"}
          />


          <FormInput
              type={"text"}
              required={false}
              placeholder={"Adresse physique"}
              label={"Adresse"}
              value={credentials.adresse}
              error={credentialsError.adresse}
              onChange={handleChange}
              onFocus={() => handleChangeError("adresse", null)}
              name={"adresse"}
          />

          <BtnForm
              text={"Enregistrer"}
              type={"submit"}
          />
          </form>
      </div>
    </div>
  )
}

export default FournisseurAdd