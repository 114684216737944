import React from 'react';
import './FormSelect.css';

const FormSelect = ({
  className, 
  error,
  label, 
  name, 
  type, 
  placeholder, 
  required, 
  options=[], 
  description,
  value,
  onChange,
  onFocus,
}) => {
  return (
    <div className={`${className} form-select`}>
        <span className='form-label'>{label}<span>{required && '*'}</span></span>

        <div className={`form-select-section ${error && 'error'}`}>

            <select 
                type={type}
                name={name}
                placeholder={placeholder}
                className='form-select-section-select overflow-hidden mx-w-[100%]'
                value={value}
                onChange={onChange}
                onFocus={onFocus}
            >
                {description && <option value={""} style={{color: 'var(--gray)'}}>{description}</option>}

                {options.map((item, index) => {
                  return(
                    <option key={index} value={item.value}>{item.label}</option>
                  )
                })}
            </select>
        </div>

        <span className='form-select-error'>{error}</span>
    </div>
  )
}

export default FormSelect