import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { usePlateformContext } from '../../contexts';
import App from '../../App';

function PrivateRoot() {
    const {token, user} = usePlateformContext();

  return !!token ? (
    (
      // <Outlet />
      // user.password_changed ? <Outlet /> : <Navigate to={`/changer-password`} />
      true ? <App><Outlet /></App> : <Navigate to={`/changer-password`} />
    )
  ) : (
    <Navigate to={`/login`} />
  )
}

export default PrivateRoot



