import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const ApexChartComp = ({series = [], categories = [], colors = ["#0571b2"], title, horizontal = false, type, formatter="", dataLabels = false, height = 350}) => {
  const [chartData, setChartData] = useState({
    series: series,
    options: {

      chart: {
        type: type,
        height: height,
        stacked: true
      },

      plotOptions: {
        bar: {
          horizontal: horizontal,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },

      dataLabels: {
        enabled: dataLabels,
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },

      xaxis: {
        categories: categories,
      },

      yaxis: {
        title: {
          text: title,
        },
      },

      fill: {
        opacity: 1,
      },

      tooltip: {
        y: {
          formatter: (val) => `${val} ${formatter}`,
        },
      },

    },
  });

  useEffect(() => {
    setChartData({
      series: series,
      options: {
  
        chart: {
          type: type,
          height: height,
          stacked: true
        },

        colors: colors,
  
        plotOptions: {
          bar: {
            horizontal: horizontal,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
  
        dataLabels: {
          enabled: dataLabels,
        },
  
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
  
        xaxis: {
          categories: categories,
        },
  
        yaxis: {
          title: {
            text: title,
          },
        },
  
        fill: {
          opacity: 1,
        },
  
        tooltip: {
          y: {
            formatter: (val) => `${val} ${formatter}`,
          },
        },
  
      },
    })
  }, [type, series, categories, title])

  return (
    <div id="chart">
      <Chart options={chartData.options} series={chartData.series} type={type} height={height}/>
    </div>
  );
};

export default ApexChartComp;
