import React, { useEffect, useRef, useState } from 'react'
import { BtnForm, FormInput, FormSelect, FormTextarea, HeaderApp } from '../../../components'
import { AiFillFileExcel, AiOutlineCheck, AiOutlineCiCircle, AiOutlineClose, AiOutlineFileImage, AiOutlineForm, AiOutlineUpload } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { BASE_URL, getElementData } from '../../../constances';

const ProduitEdit = () => {

    const [choiceContent, setChoiceContent] = useState('formulaire');
    const navigate = useNavigate();
    const {id_produit} = useParams();

    const {create_produits_file, currencies, user, update_produit, produits, famillesProduits, formeProduits, voiesProduits,} = usePlateformContext();

    let produit = getElementData(id_produit, produits);

    const model_data = {
        designation: '',
        dosage: '',
        famille: '',
        forme: '',
        voie: '',
        classe: '',
        description: '',
        image: '',
    }

    const [credentials, setCredentials] = useState(produit ? produit : model_data);
    const [credentialsError, setCredentialsError] = useState({});
    const [imageActuelle, setImageActuelle] = useState('');

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Validation du formulaire

        const errors = {}; // Objet pour stocker les erreurs

        if (!credentials?.designation) {
            errors.designation = "Entrer la designation";
        }

        if (!credentials?.dosage) {
            errors.dosage = "Entrer le dosage";
        }

        if (!credentials?.famille) {
            errors.famille = "Selectionner la famille";
        }

        if (!credentials?.forme) {
            errors.forme = "Selectionner la forme";
        }

        if (!credentials?.voie) {
            errors.voie = "Selectionner la voie";
        }

        if (!credentials?.classe) {
            errors.classe = "Selectionner la classe";
        }


        if (Object.keys(errors).length > 0) {
            setCredentialsError(errors);
        } else {

            let response = await update_produit(imageActuelle !== credentials?.image ? {...credentials, image_qrcode : null} : {...credentials, image : null, image_qrcode : null});

            if (response) {
            setCredentials(model_data);
            navigate("/produits/list");
            }
        }
        
    }

    // Drag and drop
    const [file, setFile] = useState(null);
    const [errorFile, setErrorFile] = useState('');
    const inputRef = useRef();

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event) => {
        event.preventDefault();

        let selected = event.dataTransfer.files[0]

        if (selected.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setFile(selected)
            setErrorFile('')
        } else {
            setErrorFile("Format de ficher non pris en charge, entrer plutot un fichier de format xlsx.")
        }
    }

    const handleInputFile = (event) => {
        event.preventDefault();

        let selected = event.target.files[0]

        if (selected.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setFile(selected)
            setErrorFile('')
        } else {
            setErrorFile("Format de ficher non pris en charge, entrer plutot un fichier de format xlsx.")
        }
    }

    const handleDragOverImageProduit = (event) => {
        event.preventDefault();
    }

    const handleDropImageProduit = (event) => {
        event.preventDefault();

        let selected = event.dataTransfer.files[0]

        if (selected.type === "image/png") {
            setCredentials({...credentials, ["image"] : selected})
            setCredentialsError({...credentialsError, image: ""});
        } else {
            setCredentialsError({...credentialsError, image: "Format de ficher non pris en charge, entrer plutot un fichier de format png."});
        }
    }

    const handleInputFileImageProduit = (event) => {
        event.preventDefault();

        let selected = event.target.files[0]

        if (selected.type === "image/png") {
            setCredentials({...credentials, ["image"] : selected})
            setCredentialsError({...credentialsError, image: ""});
        } else {
            setCredentialsError({...credentialsError, image: "Format de ficher non pris en charge, entrer plutot un fichier de format png."});
        }
    }

    const classe_options = [
        {value: 'Humain', label: 'Humain'},
        {value: 'Vétérinaire', label: 'Vétérinaire'},
        {value: 'Désinfectant', label: 'Désinfectant'},
        {value: 'Radiopharmaceutique', label: 'Radiopharmaceutique'},
    ]

    let [familles_options, setFamillesOptions] = useState([]);
    let [formes_options, setFormesOptions] = useState([]);
    let [voies_options, setVoiesOptions] = useState([]);

    useEffect(() => {

        let familles = []
        let formes = []
        let voies = []

        famillesProduits.map(item => familles.push({value: item.id, label: item.label}));
        formeProduits.map(item => formes.push({value: item.id, label: item.label}));
        voiesProduits.map(item => voies.push({value: item.id, label: item.label}));

        setFamillesOptions(familles);
        setFormesOptions(formes);
        setVoiesOptions(voies);

    }, [famillesProduits, formeProduits, voiesProduits])

    console.log(credentials?.image)

    useEffect(() => {
        if (produit?.image?.includes('/uploads/')) {
            setImageActuelle(produit?.image)
        }

        setCredentials(produit);
    }, [produits])

  return (
    <div>
        <HeaderApp
            title="Modifier le produits"
            links={[
                {
                    description: "Liste",
                    path: "/produits",
                    icon: "",
                },
                // {
                //     description: "Nouveau",
                //     path: "/produits/create",
                //     icon: "",
                // },
                {
                    description: "Modifier",
                    path: "/produits/update",
                    icon: "",
                    active: true
                }
            ]}
        />

        <div className='flex items-start xl:justify-between flex-col xl:flex-row'>
            <div className='xl:w-[28%] w-[100%] bg-white p-2 rounded-md mb-3'>
                {imageActuelle && <img src={BASE_URL + imageActuelle} alt='image' className='w-[100%] object-contain border-[1px] rounded-md p-2'/>}
                {!imageActuelle && <span className='flex text-gray-500 text-[80px] justify-center items-center w-[100%] border-[1px] mb-2 h-[150px] object-contain rounded-md'>
                                <AiOutlineFileImage/>
                            </span>}

                <div>
                    <span className='text-sm font-light mt-3'>{imageActuelle ? "Photo actuelle" : "Aucune photo actuellement"}</span>
                </div>
            </div>

            <div className='xl:w-[70%] mx-auto w-[100%] bg-white p-2 rounded-md'>
                {choiceContent === "formulaire" && <div>
                    {/* <div>
                        <h1>Formulaire</h1>
                        <p></p>
                    </div>  */}

                    <form onSubmit={handleSubmit} className='w-[100%]'>

                    <div className='flex xl:flex-row flex-col my-3 items-center xl:h-[100px] h-[auto]'>
                            <div className='xl:w-[50%] w-[100%] h-full xl:mr-2'>
                                {(!credentials?.image) && <div
                                    className={`${credentialsError.image ? 'border-red-700' : 'border-gray-400'} border-[2px] border-dashed w-full xl:h-[100px] h-[120px] rounded-lg`}
                                    onDragOver={handleDragOverImageProduit}
                                    onDrop={handleDropImageProduit}
                                >
                                    <label
                                        htmlFor='file_produit'
                                        className='flex flex-col justify-center items-center w-full h-full cursor-pointer'
                                    >
                                        <span className='text-[40px] text-gray-500'>
                                            <FaCloudUploadAlt />
                                        </span>

                                        <span className='text-center text-sm text-gray-500 max-w-[95%]'>Glissez et déposez le la photo ou cliquez pour en choisir une (uniquement au format png).</span>

                                    </label>

                                    <input 
                                        type='file'
                                        className='hidden'
                                        accept='.png'
                                        id='file_produit'
                                        ref={inputRef}
                                        hidden
                                        name='image'
                                        value={credentials?.image}
                                        onChange={handleInputFileImageProduit}
                                    />
                                </div>}


                                {/* Le contenue a afficher au cas ou le fichier a etet uplodaer */}
                                {(credentials?.image) && <div className='h-full'>
                                    <div className='flex items-center justify-between border-dashed border-[1px] h-full border-gray-700 rounded-lg py-3 px-2'>
                                        <div className='flex items-center justify-start max-w-[70%]'>

                                            {credentials?.image && <span className='flex text-gray-500 text-[40px] justify-center items-center mr-2 object-contain rounded-md'>
                                                <AiOutlineFileImage/>
                                            </span>}

                                            <div className='flex flex-col max-w-[100%]'>
                                                <span className='font-bold text-md text-secondary truncate '>{credentials?.image.name ? credentials?.image.name : credentials?.image}</span>
                                                {credentials?.image.size && <span className='text-normal text-sm text-gray-500'>{(credentials?.image.size / 1024).toFixed(2)} Ko</span>}
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => setCredentials({...credentials, ['image'] : ''})}
                                            className='text-red-600 text-[25px]'
                                        >
                                            <AiOutlineClose/>
                                        </button>
                                    </div>
                                </div>}
                            </div>

                            <div className='xl:mb-auto mt-2 flex flex-col h-full xl:w-[50%] w-[100%] justify-center'>
                                <span className='text-sm font-medium'>Photo du produit</span>
                                <span className='text-sm font-light'>Selectionner une photo du produit (facultative)</span>

                                {credentialsError.image && <div>
                                    <span className='text-sm font-light text-red-700'>{credentialsError.image}</span>    
                                </div>}
                            </div>
                        </div>

                        <FormInput
                            type={"text"}
                            label={"Désignation"}
                            required={true}
                            placeholder={"Désignation"}
                            autoComplete='off'
                            value={credentials?.designation}
                            error={credentialsError.designation}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("designation", null)}
                            name={"designation"}
                        />

                        <div className='flex items-start w-full xl:flex-row flex-col'>
                            <FormSelect
                                required={true}
                                label={"Famille"}
                                description={"Selectionner la famille"}
                                value={credentials?.famille}
                                error={credentialsError.famille}
                                onChange={handleChange}
                                onFocus={() => handleChangeError("famille", null)}
                                name={"famille"}
                                options={familles_options}
                                className={"w-full xl:mr-1"}
                            />

                            <FormSelect
                                required={true}
                                label={"Classe"}
                                description={"Selectionner la classe"}
                                value={credentials?.classe}
                                error={credentialsError.classe}
                                onChange={handleChange}
                                onFocus={() => handleChangeError("classe", null)}
                                name={"classe"}
                                options={classe_options}
                                className={"w-full xl:ml-1"}
                            />
                        </div>

                        <div className='flex items-start w-full xl:flex-row flex-col'>
                            <FormSelect
                                required={true}
                                label={"Forme"}
                                description={"Selectionner la forme"}
                                value={credentials?.forme}
                                error={credentialsError.forme}
                                onChange={handleChange}
                                onFocus={() => handleChangeError("forme", null)}
                                name={"forme"}
                                options={formes_options}
                                className={"w-full xl:mr-1"}
                            />

                            <FormSelect
                                required={true}
                                label={"Voie"}
                                description={"Selectionner la voie"}
                                value={credentials?.voie}
                                error={credentialsError.voie}
                                onChange={handleChange}
                                onFocus={() => handleChangeError("voie", null)}
                                name={"voie"}
                                options={voies_options}
                                className={"w-full xl:ml-1"}
                            />
                        </div>

                        <div className='flex items-start w-full xl:flex-row flex-col'>
                            <FormInput
                                type={"text"}
                                required={true}
                                placeholder={"Dosage"}
                                label={"Dosage"}
                                value={credentials?.dosage}
                                error={credentialsError.dosage}
                                onChange={handleChange}
                                onFocus={() => handleChangeError("dosage", null)}
                                name={"dosage"}
                                className={"w-full"}
                            />
                        </div>

                        <FormTextarea
                            required={false}
                            placeholder={"Description"}
                            label={"Description"}
                            value={credentials?.description}
                            error={credentialsError.description}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("description", null)}
                            name={"description"}
                        />  

                        <BtnForm
                            text={"Modifier"}
                            type={"submit"}
                        />
                    </form>   
                </div>}

            </div>
        </div>

    </div>
  )
}

export default ProduitEdit