import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BtnForm, FormInput, FormSelect, HeaderApp, HomeMain } from '../../../components';
import { usePlateformContext } from '../../../contexts';
import { AiOutlineClose, AiOutlinePlus, AiOutlinePrinter } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { TbMapPlus } from 'react-icons/tb';
import { getElementData } from '../../../constances';

const SiteEdit = () => {

  const navigate = useNavigate();

  const {currencies, user, update_site, countries, compteClientList, sites} = usePlateformContext();
  const {id_site } = useParams();

  let site = getElementData(id_site, sites)

  const model_data = {
    user: user.id,
    structure: '',
    label: '',
    currency: '',
    address: '',
    country: '',
    city: '',
    longitude: '',
    latitude: '',
    type_site: '',
    ouvertures: []
  }

  const [credentials, setCredentials] = useState(site ? site : model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }


  const handleSubmit = async (e) => {
      e.preventDefault()

      // Validation du formulaire

      const errors = {}; // Objet pour stocker les erreurs

      if (!credentials?.label) {
          errors.label = "Entrer la description du site";
      }

      if (!credentials?.currency) {
          errors.currency = "Selectionner la devise";
      }

      if (!credentials?.country) {
          errors.country = "Selectionner le pays";
      }

      if (!credentials?.city) {
          errors.city = "Selectionner la ville";
      }

      // if (!credentials?.label) {
      //     errors.label = "Entrer une description";
      // }else if (credentials?.label.length < 100) {
      //   errors.label = "La description doit contenir au minimum 100 caracteres";
      // }

      if (!credentials?.address) {
          errors.address = "Entrer l'adresse physique";
      }

      if (Object.keys(errors).length > 0) {
          setCredentialsError(errors);
      } else {
        let response = await update_site(credentials);

        if (response) {
          setCredentials(model_data);
          navigate("/points-ventes/list");
        }
      }
      
  }


  const [optionsCountries, setOptionsCountries] = useState([]);
  const [optionsCities, setOptionsCities] = useState([]);
  const [optionsCurrencies, setOptionsCurrencies] = useState([]);
  const [optionsStructure, setOptionsStructure] = useState([]);

  useEffect(() => {
    let countries_list = countries.map((item) => {
      return {label: item.label, value: item.id}
    });

    setOptionsCountries(countries_list)
  }, [countries])

  useEffect(() => {

      let country_selected = countries.filter((item) => item.id === (credentials?.country ? parseInt(credentials?.country) : ''))[0]

      setOptionsCities(country_selected?.cities?.map((item) => {
        return {label: item.label, value: item.id}
      }))
  }, [credentials?.country, countries, credentials])

  useEffect(() => {
    let currencies_list = currencies?.map((item) => {
      return {label: item.iso_code, value: item.id}
    });

    setOptionsCurrencies(currencies_list)
  }, [currencies])


  useEffect(() => {
    let compteClientList_list = compteClientList?.map((item) => {
      return {label: `${item.unique_identification_number} - ${item.name_of_structure}`, value: item.id}
    });

    setOptionsStructure(compteClientList_list)
  }, [compteClientList])


  const optionsDays = [
      {value: 'Lundi', label: 'Lundi'},
      {value: 'Mardi', label: 'Mardi'},
      {value: 'Mercredi', label: 'Mercredi'},
      {value: 'Jeudi', label: 'Jeudi'},
      {value: 'Vendredi', label: 'Vendredi'},
      {value: 'Samedi', label: 'Samedi'},
      {value: 'Dimanche', label: 'Dimanche'},
  ]

  const optionsTypeSite = [
    {value: 'Jour', label: 'Jour'},
    {value: 'Nuit', label: 'Nuit'},
]

  const ajouter_section_financement = () => {
    let section = credentials?.ouvertures
    section.push({jour : "", heure_ouverture: '', heure_fermeture: ''})
    
    setCredentials({...credentials, ["ouvertures"] : section})
  }

  const supprimer_section_financement = (indice) => {
      let section = [...credentials?.ouvertures]; // Créez une copie du tableau pour éviter de modifier l'original directement
      if (indice >= 0 && indice < section.length) {
          section.splice(indice, 1); // Supprimez l'élément à l'indice spécifié
          setCredentials({...credentials, ["ouvertures"]: section});
      } else {
          console.error("Indice invalide. L'élément n'a pas été supprimé.");
      }
  }

  const handleFinancementChange = (e, index) => {
      const { name, value } = e.target;
      const updatedFinancement = [...credentials?.ouvertures];
      updatedFinancement[index][name] = value;

      setCredentials({
          ...credentials,
          ouverture: updatedFinancement
      });
  };


  useEffect(() => {
    let site = getElementData(id_site, sites)
    setCredentials(site)
  }, [sites])

  console.log(credentials)


  return (
    <div>
      <HeaderApp
            title="Point de vente"
            links={[
                {
                    description: "Liste",
                    path: "/points-ventes",
                    icon: "",
                },
                {
                    description: "Nouveau",
                    path: "",
                    icon: "",
                    active: true
                }
            ]}
        />

      <div className='flex flex-col justify-start items-center my-4'>
          <div className='w-[100%] max-w-[100%] bg-white p-3'>

          <FormSelect
              required={true}
              label={"Compte client"}
              description={"Selectionner le compte client"}
              value={credentials?.structure}
              error={credentialsError.structure}
              onChange={handleChange}
              onFocus={() => handleChangeError("structure", null)}
              name={"structure"}
              options={optionsStructure}

          />

          <div className='flex justify-between md:flex-row flex-col'>
            <FormInput
                type={"text"}
                label={"Description du site"}
                required={true}
                placeholder={"Description"}
                autoComplete='off'
                value={credentials?.label}
                error={credentialsError.label}
                onChange={handleChange}
                onFocus={() => handleChangeError("label", null)}
                name={"label"}
                className={"md:w-[33%] w-full mr-0 md:mr-1 flex-grow"}

            />

            <FormSelect
                required={true}
                label={"Type de point de vente"}
                description={"Selectionner le type"}
                value={credentials?.type_site}
                error={credentialsError.type_site}
                onChange={handleChange}
                onFocus={() => handleChangeError("type_site", null)}
                name={"type_site"}
                options={optionsTypeSite}
                className={"md:w-[33%] w-full mr-0 md:mr-1 mt-2 flex-grow"}

            />

            <FormSelect
                required={true}
                label={"Devise du site"}
                description={"Selectionner la devise"}
                value={credentials?.currency}
                error={credentialsError.currency}
                onChange={handleChange}
                onFocus={() => handleChangeError("currency", null)}
                name={"currency"}
                options={optionsCurrencies}
                className={"md:w-[33%] w-full mr-0 md:mr-1 mt-2 flex-grow"}

            />
          </div>

          <div className='flex justify-between md:flex-row flex-col'>
            <FormSelect
                required={true}
                label={"Pays"}
                description={"Selectionner le pays"}
                value={credentials?.country}
                error={credentialsError.country}
                onChange={handleChange}
                onFocus={() => handleChangeError("country", null)}
                name={"country"}
                options={optionsCountries}
                className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

            />

            <FormSelect
                required={true}
                label={"Ville"}
                description={"Selectionner la ville"}
                value={credentials?.city}
                error={credentialsError.city}
                onChange={handleChange}
                onFocus={() => handleChangeError("city", null)}
                name={"city"}
                options={optionsCities}
                className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

            />
          </div>

          <FormInput
              type={"text"}
              label={"Adresse physique"}
              required={true}
              placeholder={"Adresse du site"}
              autoComplete='off'
              value={credentials?.address}
              error={credentialsError.address}
              onChange={handleChange}
              onFocus={() => handleChangeError("address", null)}
              name={"address"}
          />

          <div className='mt-4'>
            <div className='mb-0'>
              <div className='flex items-center '>
                <h3 className='font-medium text-lg mr-2'>Données de géolocalisation</h3>
                <button className='bg-secondary text-white rounded-full w-[30px] h-[30px] flex justify-center items-center font-bold text-lg'>
                  <TbMapPlus/>
                </button>
              </div>
              <p className='mt-2'>Appuyez sur le bouton ci-dessus pour récupérer automatiquement les informations géographiques du point de vente, uniquement si vous vous trouvez physiquement à cet endroit. Sinon, veuillez les saisir manuellement.</p>
            </div>

            <div className='flex justify-between md:flex-row flex-col'>
              <FormInput
                  type={"text"}
                  label={"Latitude"}
                  required={true}
                  placeholder={"Latitude"}
                  autoComplete='off'
                  value={credentials?.latitude}
                  error={credentialsError.latitude}
                  onChange={handleChange}
                  onFocus={() => handleChangeError("latitude", null)}
                  name={"latitude"}
                  className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

              />

              <FormInput
                  type={"text"}
                  label={"Longitude"}
                  required={true}
                  placeholder={"Longitude"}
                  autoComplete='off'
                  value={credentials?.longitude}
                  error={credentialsError.longitude}
                  onChange={handleChange}
                  onFocus={() => handleChangeError("longitude", null)}
                  name={"longitude"}
                  className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

              />
            </div>
          </div>

          <div className='my-4'>
            <div className='mb-3'>
              <div className='flex items-center'>
                <h3 className='font-medium text-lg mr-2'>Horaires d'ouverture</h3>
                <a onClick={() => ajouter_section_financement()} className='bg-secondary cursor-pointer text-white rounded-full w-[30px] h-[30px] flex justify-center items-center font-bold text-lg'>
                  <AiOutlinePlus/>
                </a>
              </div>
              <p className='mt-2'>Cliquez sur le bouton ci-dessus pour accéder à la rubrique d'ajout des jours, heures d'ouverture et de fermeture du point de vente.</p>
            </div>
            
            {credentials?.ouvertures?.map((item, index) => <div key={index} className='border rounded-md p-2 mb-3'>
              <div className='flex justify-end items-center'>
                <a className='text-red-700 text-lg cursor-pointer' onClick={() => supprimer_section_financement(index)}>
                  <AiOutlineClose/>
                </a>
              </div>
              <div className='flex justify-between md:flex-row flex-col'>
                <FormSelect
                    required={true}
                    label={"Jour"}
                    description={"Selectionner le jour"}
                    value={credentials?.ouvertures[index].jour}
                    error={credentialsError.jour}
                    onChange={e => handleFinancementChange(e, index)}
                    onFocus={() => handleChangeError("jour", null)}
                    name={"jour"}
                    options={optionsDays}
                    className={"md:w-[49%] w-full mr-0 md:mr-1 mt-2 flex-grow"}

                />

                <FormInput
                    type={"time"}
                    label={"Ouverture"}
                    required={true}
                    placeholder={"Ouverture"}
                    autoComplete='off'
                    value={credentials?.ouvertures[index].heure_ouverture}
                    error={credentialsError.heure_ouverture}
                    onChange={e => handleFinancementChange(e, index)}
                    onFocus={() => handleChangeError("label", null)}
                    name={"heure_ouverture"}
                    className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

                />

                <FormInput
                    type={"time"}
                    label={"Fermeture"}
                    required={true}
                    placeholder={"Fermeture"}
                    autoComplete='off'
                    value={credentials?.ouvertures[index].heure_fermeture}
                    error={credentialsError.heure_fermeture}
                    onChange={e => handleFinancementChange(e, index)}
                    onFocus={() => handleChangeError("label", null)}
                    name={"heure_fermeture"}
                    className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

                />
              </div>
            </div>)}
          </div>

          <BtnForm
              text={"Enregistrer"}
              type={"submit"}
              onPress={handleSubmit}
          />
          </div>
      </div>
    </div>
  )
}

export default SiteEdit