import axios from "axios";
import { getSiteUrl } from "./functions";

const DEBUG = false;


// URL du back en local
const LOCAL_URL = "http://localhost:8000/";

// URL du back en production
const PRODUCTION_URL = getSiteUrl();


export const BASE_URL = DEBUG ? LOCAL_URL : PRODUCTION_URL;

export const Axios = axios.create({
    baseURL: BASE_URL
});

export const URL_PLATEFORM = '/';