import React, { useRef } from 'react'
import { AiOutlineClose, AiOutlineEdit, AiOutlineFilePdf, AiOutlinePrinter } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { GenericTable, HeaderApp, HomeMain } from '../../../components';
import { URL_PLATEFORM, checkPermission, datas, functions, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';

const AgentList = () => {

    const {sites, agents, countries, user} = usePlateformContext();

    const navigate = useNavigate();

    let data_exists = false;

    const componentRef = useRef();

    const handlePront = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'liste-des-agents',
        onAfterPrint: () => console.log("print successfuly")
    });

    const columns_admin_profile = [
        { key: "user_id", label: "Identifiant" },
        { key: "last_name", label: "Nom(s)"},
        { key: "first_name", label: "Prénom(s)"},
        { key: "gender", label: "G" },
        { key: "phone", label: "Téléphone" },
        { key: "email", label: "E-mail" },
        { 
            key: "profile", 
            label: "Type de compte",
            value: row => {
                if (row.profile === "admin") {
                    return <span className='bg-primary rounded-full px-2 text-sm text-white font-normal'>{"Admin"}</span>
                }
                else if (row.profile === "client-admin") {
                    return <span className='bg-green-700 rounded-full px-2 text-sm text-white font-normal'>{"Client-admin"}</span>
                }
                else if (row.profile === "client-agent") {
                    return <span className='bg-yellow-700 rounded-full px-2 text-sm text-white font-normal'>{"Client-agent"}</span>
                }
                else if (row.profile === "client-mobile") {
                    return <span className='bg-dark rounded-full px-2 text-sm text-white font-normal'>{"Client-Mobile"}</span>
                }
            }
        },
        {
            key: "numero_client",
            label: "N° de compte",
            value: row => row.numero_client ? row.numero_client : '-'
        },
        { 
            key: "active", 
            label: "Actif",
            value: row => row.active ? <FaCheck className='text-[green]'/> : <AiOutlineClose className='text-[red] text-md'/>
        },
        {
          key: "sites_list",
          label: "Points de ventes",
          value: row => row.profile !== "admin" ? (row.sites_list?.length === 0 ? "Aucun" : row.sites_list?.length) : "-"
        },
        (functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_UTILISATEUR], user.profile_permissions_list)) && {key: "", value: row => <div className='flex items-center w-[100px]'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/utilisateurs/list/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>
        </div>}

    ];

    const columns = [
        { key: "user_id", label: "Identifiant" },
        { key: "last_name", label: "Nom(s)"},
        { key: "first_name", label: "Prénom(s)"},
        { key: "gender", label: "G" },
        { key: "phone", label: "Téléphone" },
        { key: "email", label: "E-mail" },
        { 
            key: "active", 
            label: "Actif",
            value: row => row.active ? <FaCheck className='text-[green]'/> : <AiOutlineClose className='text-[red] text-md'/>
        },
        {
          key: "sites_list",
          label: "Points de ventes",
          value: row => (row.sites_list?.length > 0) ? (row.sites_list?.map((item, index) =>  getElementData(item, sites)?.label + ((index === row.sites_list?.length - 1) ? "" : " ; "))) : "Aucun"
        },
        (functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_UTILISATEUR], user.profile_permissions_list)) && {key: "", value: row => <div className='flex items-center w-[100px]'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/utilisateurs/list/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>
        </div>}

    ];


  return (
    <div>
         <HeaderApp
            title="Utilisateurs"
            links={[
                {
                    description: "Liste",
                    path: "",
                    icon: "",
                    active: true
                },
                {
                    description: "Nouveau",
                    path: "/utilisateurs/list/create",
                    icon: "",
                }
            ]}
        />

        <div ref={componentRef} className='print:p-2'>
        
        <div 
            className='p-2 bg-white rounded-lg'
        >

            <GenericTable
                data={agents}
                columns={user.profile !== "admin" ? columns : columns_admin_profile}
            />

        </div>

        </div>
    </div>
  )
}

export default AgentList