import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BtnForm, FormInput, FormSelect, HeaderApp, HomeMain } from '../../../components';
import { usePlateformContext } from '../../../contexts';
import { getElementData } from '../../../constances';

const FournisseurEdit = () => {
  
  const navigate = useNavigate();
  
  const {id_fournisseur} = useParams();
  const {fournisseurs, update_fournisseur} = usePlateformContext();

  let fournisseur = getElementData(id_fournisseur, fournisseurs);

  const [credentials, setCredentials] = useState(fournisseur ? fournisseur : {});
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }


  const handleSubmit = async (e) => {
        e.preventDefault()

        // Validation du formulaire

        const errors = {}; // Objet pour stocker les erreurs

        if (!credentials?.designation) {
            errors.designation = "Entrer la designation du fournisseur";
        }

        if (!credentials?.telephone) {
            errors.telephone = "Entrer le numéro de téléphone";
        }

        if (!credentials?.email) {
            errors.email = "Entrer l'adresse e-mail";
        }


        if (Object.keys(errors).length > 0) {
            setCredentialsError(errors);
        } else {
        let response = await update_fournisseur(credentials);

        if (response) {
            setCredentials(fournisseur);
            navigate("/fournisseurs/list");
        }
        }
        
    }


  useEffect(() => {
    setCredentials(getElementData(id_fournisseur, fournisseurs));
  }, [id_fournisseur, fournisseurs])

  return (
    <div>
      <HeaderApp
            title="Forunisseurs"
            links={[
                {
                    description: "Liste",
                    path: "/fournisseurs",
                    icon: "",
                },
                {
                    description: "Modifier",
                    path: "",
                    icon: "",
                    active: true
                }
            ]}
        />

      <div className='flex flex-col justify-start items-center'>
          <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3'>
            <FormInput
                type={"text"}
                label={"Designation du fournisseur"}
                required={true}
                placeholder={"Designation"}
                autoComplete='off'
                value={credentials?.designation}
                error={credentialsError.designation}
                onChange={handleChange}
                onFocus={() => handleChangeError("designation", null)}
                name={"designation"}
            />

            <FormInput
                type={"text"}
                required={true}
                placeholder={"Numéro de téléphone"}
                label={"Téléphone"}
                value={credentials?.telephone}
                error={credentialsError.telephone}
                onChange={handleChange}
                onFocus={() => handleChangeError("telephone", null)}
                name={"telephone"}
            />

            <FormInput
                type={"email"}
                required={true}
                placeholder={"exemple@site.com"}
                label={"E-mail"}
                value={credentials?.email}
                error={credentialsError.email}
                onChange={handleChange}
                onFocus={() => handleChangeError("email", null)}
                name={"email"}
            />


            <FormInput
                type={"text"}
                required={false}
                placeholder={"Adresse physique"}
                label={"Adresse"}
                value={credentials?.adresse}
                error={credentialsError.adresse}
                onChange={handleChange}
                onFocus={() => handleChangeError("adresse", null)}
                name={"adresse"}
            />

            <BtnForm
                text={"Enregistrer"}
                type={"submit"}
            />
            </form>
        </div>
    </div>
  )
}

export default FournisseurEdit