import React, { useEffect, useState } from 'react'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BtnForm, CheckBoxList, FormInput, FormSelect, HeaderApp, HomeMain } from '../../../components';
import { FaPlus } from 'react-icons/fa';
import { AiOutlinePrinter } from 'react-icons/ai';
import { getElementData } from '../../../constances';

const CompteClientEdit = () => {

    const {update_compte_client, user, sites, permissions, countries, compteClientList} = usePlateformContext();
    const navigate = useNavigate();
    const {id_compte_client} = useParams();

    let compte_client = getElementData(id_compte_client, compteClientList)

    let model_data = {
        name_of_structure: '',
        phone: '',
        email: '',
        address: '',
        description: '',
        legal_form: '',
        commerce_registry: '',
        unique_identification_number: '',
        validated: '',
        country: "",
        city: "",

        // Informations de l'administrateur
        first_name: "",
        last_name: "",
        gender: "",
        birth: "",
        nationality: "",
        active: true,
    };


    const [credentials, setCredentials] = useState(compte_client ? compte_client : model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials?.name_of_structure) {
                errors.name_of_structure = "Entrer le nom du compte";
            }

            if (!credentials?.legal_form) {
                errors.legal_form = "Selectionner la forme juridique";
            }

            if (!credentials?.commerce_registry) {
                errors.commerce_registry = "Entrer le numero du registre de commerce";
            }

            if (!credentials?.country) {
                errors.country = "Selectionner le pays";
            }

            if (!credentials?.city) {
                errors.city = "Selectionner la ville";
            }


            if (!credentials?.phone) {
                errors.phone = "Entrer le numéro de téléphone";
            }


            if (!credentials?.email) {
                errors.email = "Entrer l'adresse e-mail";
            }

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = await update_compte_client(credentials);

                if (response) {
                    setCredentials(model_data);
                    navigate(-1);
                }
            }
        
    }

    let optionsGender = [
        {label: "Masculin", value: "M"},
        {label: "Feminin", value: "F"}
    ]

    let optionsActive = [
        {label: "Oui", value: true},
        {label: "Non", value: false}
    ]

    const optionsLegalForm = [
        { label: "Société à Responsabilité Limitée (SARL)", value: "Société à Responsabilité Limitée (SARL)" },
        { label: "Société à Responsabilité Limitée Unipersonnelle (SARLU)", value: "Société à Responsabilité Limitée Unipersonnelle (SARLU)" },
        { label: "Société Anonyme (SA)", value: "Société Anonyme (SA)" },
        { label: "Société par Actions Simplifiée (SAS)", value: "Société par Actions Simplifiée (SAS)" },
        { label: "Société par Actions Simplifiée Unipersonnelle (SASU)", value: "Société par Actions Simplifiée Unipersonnelle (SASU)" },
        { label: "Société en Commandite (SC)", value: "Société en Commandite (SC)" },
        { label: "Société en Commandite par Actions (SCA)", value: "Société en Commandite par Actions (SCA)" },
        { label: "Société Coopérative d'Intérêt Collectif (SCIC)", value: "Société Coopérative d'Intérêt Collectif (SCIC)" },
        { label: "Société Coopérative ouvrière de Production (SCOP)", value: "Société Coopérative ouvrière de Production (SCOP)" },
        { label: "Société en Nom Collectif (SNC)", value: "Société en Nom Collectif (SNC)" },
        { label: "Entreprise Unipersonnelle à Responsabilité Limitée (EURL)", value: "Entreprise Unipersonnelle à Responsabilité Limitée (EURL)" },
        { label: "Entreprise Individuelle (EI)", value: "Entreprise Individuelle (EI)" },
        { label: "Auto-entrepreneur", value: "Auto-entrepreneur" },
    ];
    


    const [optionsCountries, setOptionsCountries] = useState([]);
    const [optionsCities, setOptionsCities] = useState([]);

    useEffect(() => {
        let countries_list = countries.map((item) => {
        return {label: item.label, value: item.id}
        });

        setOptionsCountries(countries_list)
    }, [countries])

    useEffect(() => {

        let country_selected = countries.filter((item) => item.id === (credentials?.country ? parseInt(credentials?.country) : ''))[0]

        setOptionsCities(country_selected?.cities?.map((item) => {
            return {label: item.label, value: item.id}
        }))
    }, [credentials?.country, countries, credentials])

    //
    function ajouterOuSupprimerElement(liste, element) {
        const index = liste.indexOf(element);
    
        if (index === -1) {
        // L'élément n'existe pas dans la liste, ajoutons-le.
        liste.push(element);
        } else {
        // L'élément existe dans la liste, supprimons-le.
        liste.splice(index, 1);
        }

        return liste;
    }   

    const handleChangeCheckBoxList = (name, value) => {
        setCredentials({...credentials, [name] : value})
    }

    useEffect(() => {
        let compte_client = getElementData(id_compte_client, compteClientList)
        setCredentials(compte_client);
    }, [compteClientList])

  return (
    <div>
        <HeaderApp
            title="Nouveau compte client"
            links={[
                {
                    description: "Liste",
                    path: "/comptes-clients",
                    icon: "",
                },
                {
                    description: "Nouveau",
                    path: "",
                    icon: "",
                    active: true
                }
            ]}
        />

        <div className='flex flex-col justify-start items-center py-3'>
            <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3 rounded-md'>

                {/* Informations de la structure */}
                <div>
                    <FormInput
                        type={"text"}
                        label={"Nom du compte"}
                        required={true}
                        placeholder={"Nom du compte"}
                        autoComplete='off'
                        value={credentials?.name_of_structure}
                        error={credentialsError.name_of_structure}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("name_of_structure", null)}
                        name={"name_of_structure"}
                    />

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormSelect
                            type={"text"}
                            label={"Forme juridique"}
                            required={true}
                            placeholder={"Forme juridique"}
                            autoComplete='off'
                            value={credentials?.legal_form}
                            error={credentialsError.legal_form}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("legal_form", null)}
                            name={"legal_form"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow mt-2"}
                            options={optionsLegalForm}
                            description={"Selectionner la forme juridique"}
                        />

                        <FormInput
                            type={"text"}
                            label={"Registre de commerce (RCCM)"}
                            required={true}
                            placeholder={"Registre de commerce (RCCM)"}
                            autoComplete='off'
                            value={credentials?.commerce_registry}
                            error={credentialsError.commerce_registry}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("commerce_registry", null)}
                            name={"commerce_registry"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}
                        />
                    </div>

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormSelect
                            type={"text"}
                            label={"Pays"}
                            required={true}
                            placeholder={"Pays"}
                            autoComplete='off'
                            value={credentials?.country}
                            error={credentialsError.country}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("country", null)}
                            name={"country"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}
                            options={optionsCountries}
                            description={"Selectionner le pays"}
                        />

                        <FormSelect
                            type={"text"}
                            label={"Ville"}
                            required={true}
                            placeholder={"Ville"}
                            autoComplete='off'
                            value={credentials?.city}
                            error={credentialsError.city}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("city", null)}
                            name={"city"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}
                            options={optionsCities}
                            description={"Selectionner la ville"}
                        />
                    </div>

                    <FormInput
                        type={"text"}
                        label={"Adresse"}
                        required={true}
                        placeholder={"Adresse physique de la direction generale"}
                        autoComplete='off'
                        value={credentials?.address}
                        error={credentialsError.address}
                        onChange={handleChange}
                        onFocus={() => handleChangeError("address", null)}
                        name={"address"}
                    />

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormInput
                            type={"text"}
                            label={"Téléphone"}
                            required={true}
                            placeholder={"Téléphone"}
                            autoComplete='off'
                            value={credentials?.phone}
                            error={credentialsError.phone}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("phone", null)}
                            name={"phone"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow mt-2"}
                        />

                        <FormInput
                            type={"email"}
                            label={"E-mail"}
                            required={true}
                            placeholder={"E-mail"}
                            autoComplete='off'
                            value={credentials?.email}
                            error={credentialsError.email}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("email", null)}
                            name={"email"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}
                        />
                    </div>
                </div>

                <BtnForm
                    type="submit"
                    text={"Enregistrer"}
                />

            </form>
        </div>
    </div>
  )
}

export default CompteClientEdit