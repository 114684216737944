import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HeaderApp = ({title, links}) => {
    return(
    <div className='flex justify-between items-center my-3 bg-white rounded-lg p-2'>
        <h1 className='text-lg font-normal'>{title}</h1>

        {/* <div className='flex items-center'>
           {links.map((item, index) => {
            return(
                <Link className={`${item.active ? 'bg-secondary' : 'bg-[transparent] border-[1px]'} flex ml-2 rounded-md items-center text-[12px] py-2 px-4 text-white font-semibold focus:outline-none focus:ring-opacity-75`} to={`${item.path}`}>
                    {item.icon && <span className='text-gray-500 mr-2'>
                        {item.icon}
                    </span>}
                    <span className={`uppercase ${item.active ? 'text-white' : 'text-gray-500'}`}>{item.description}</span>
                </Link>
            )}
           )}
        </div> */}
    </div>
)}

export default HeaderApp;