// import jwtDecode from "jwt-decode"
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const tokenIsValid = (token) => {

    // const { exp } = jwtDecode(token);

    // if(exp * 1000 > new Date().getTime()){
    //     return true;
    // }

    return !!token;
}


export function filtrerData(id, list_element = []) {
  const response = list_element.find(item => item.id.toString() === id.toString());
  return response || null;
}



export const formaterDate = (date) => {
  let now = new Date(date)
  // return format(now, 'EEE dd MMM yyyy', { locale: fr });
  return format(now, 'dd/MM/yyyy', { locale: fr });
}


export const FormatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {day: '2-digit', month: 'short', year: 'numeric'};
  const formattedDate = date.toLocaleDateString('fr-FR', options).replace(/ /g, ' ');
  
  return formattedDate; // Exemple de résultat : 20-Apr-2023
}

export const FormatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = {day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};
  const formattedDate = date.toLocaleDateString('fr-FR', options).replace(/ /g, ' ');
  
  return formattedDate; // Exemple de résultat : 20-Apr-2023
}


export const FormatPrice = (arg) => {
  //const value = new Intl.NumberFormat('fr-FR').format(arg);
  // return String(arg).replace(/(.)(?=(\d{3})+$)/g,'$1 ');

  const formattedValue = new Intl.NumberFormat('fr-FR').format(arg);
  const [integerPart, decimalPart] = formattedValue.split(',');

  let formattedIntegerPart = integerPart.replace(/(.)(?=(\d{3})+$)/g, '$1 ');

  if (decimalPart && parseInt(decimalPart) === 0) {
    // Si la partie décimale est présente et égale à zéro, ajoutez deux zéros après la virgule
    formattedIntegerPart += ',00';
  } else if (decimalPart) {
    // Si la partie décimale est présente mais différente de zéro, ajoutez la partie décimale
    formattedIntegerPart += ',' + decimalPart;
  }

  return formattedIntegerPart;
}


export function getShortenedName(imagePath, maxLength) {
  const maxChars = maxLength || 30; // Nombre maximal de caractères avant la troncature (20 par défaut)
  const ellipsis = '...';

  if (imagePath.length <= maxChars) {
    return imagePath;
  } else {
    const truncatedName = imagePath.substring(0, maxChars - ellipsis.length) + ellipsis;
    return truncatedName;
  }
}


export const getElementData = (id, element) => {
  let response = {};

  try {
    response = element.find(item => item.id.toString() === id.toString());

    return response;

  } catch (error) {
    return response;
  }

  
};


 // Fonction pour calculer la différence en jours entre deux dates
 export function calculerDifferenceEnJours(date1, date2) {
  // Convertir les dates en objets de type Date
  var date1Obj = new Date(date1);
  var date2Obj = new Date(date2);

  // Calculer la différence en millisecondes
  var differenceMs = Math.abs(date2Obj - date1Obj);

  // Convertir la différence en jours
  var differenceJours = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  // Retourner le résultat
  return differenceJours;
}


export const ValidePhone =(inputPhone) => {
  // let phoneRegex = new RegExp('^[0]{1}[6,4,5]{1}[0-9]{7}$', 'g');

  // seulement les numero mtn
  let phoneRegex = new RegExp('^[0]{1}[6,5,4]{1}[0-9]{7}$', 'g');

  let testPhone = phoneRegex.test(inputPhone);

  if(testPhone){
      return true; 
  }else{
      return false; 
  }
};

export const FormatageNumero = (arg) => {
  var value = arg;
  //const value = new Intl.NumberFormat('fr-FR').format(arg);
  return String(value).replace(/(.{2})(.{3})(.{2})(.{2})/g,'$1 $2 $3 $4');
}


export const supprimerUnderscores = (texte) => {
  try {
    return texte.replace(/_/g, ' ');
  } catch (error) {
    return texte;
  }
};


export function checkPermission(permission, list_permission = []) {
  const response = list_permission.find(item => item.toLowerCase() === permission.toLowerCase());
  return response !== undefined && response.length > 0;
}

export function checkIfAnyElementInListExists(listToCheck, targetList) {
  return listToCheck.some(item => targetList.includes(item));
}

export function getSiteUrl() {
  return window.location.origin;
}



// Fonctions pour renvoyer le stock disponible par produit et les produits vendus
// Fonction pour regrouper les données par produit
const groupByProduct = (data, key) => {
  const groupedData = {};
  data.forEach((item) => {
      const groupId = item[key];
      if (!groupedData[groupId]) {
      groupedData[groupId] = [];
      }
      groupedData[groupId].push(item);
  });
  return groupedData;
};

// Fonction pour calculer la somme des quantités et obtenir l'élément avec la date de création la plus récente
const calculateSumAndLatest = (groupedData, dateKey) => {
  return Object.values(groupedData).map((group) => {
      const totalQuantity = group.reduce((sum, item) => sum + item.quantite, 0);

      // Trouver l'objet avec la date de création la plus récente
      const latestItem = group.reduce((latest, item) => {
      return new Date(item[dateKey]) > new Date(latest[dateKey]) ? item : latest;
      }, group[0]);

      return { ...latestItem, quantite: totalQuantity };
  });
};

// Fonction pour regrouper les ventes par produit
const groupVentesByProduct = (venteData) => {
  const groupedVentes = {};
  venteData.forEach((vente) => {
    vente.vente_items.forEach((venteItem) => {
      const produitId = venteItem.produit;
      if (!groupedVentes[produitId]) {
        groupedVentes[produitId] = { totalQuantite: 0, totalMontant: 0, venteItems: [] };
      }
      groupedVentes[produitId].totalQuantite += venteItem.quantite;
      groupedVentes[produitId].totalMontant += venteItem.total;
      groupedVentes[produitId].venteItems.push(venteItem);
    });
  });
  return groupedVentes;
};

// Fonction pour calculer le stock disponible
const calculateStockDisponible = (stocks, ventes) => {
  const stocksDisponibles = {};
  stocks.forEach((stock) => {
    const produitId = stock.produit;
    const venteGroup = ventes.find((vente) => vente.produit === produitId);

    const quantiteVendue = venteGroup ? venteGroup.totalQuantite : 0;
    const stockDisponible = stock.quantite - quantiteVendue;

    stocksDisponibles[produitId] = { ...stock, quantite: stockDisponible };
  });

  return Object.values(stocksDisponibles);
};

// Utiliser les fonctions pour regrouper les données de stock et de ventes
export const vente_par_produit = (ventesList) => {
  const groupedVentes = groupVentesByProduct(ventesList);

  const result_vente = Object.entries(groupedVentes).map(([produitId, venteGroup]) => {
    return {
        produit: parseInt(produitId, 10),
        totalQuantite: venteGroup.totalQuantite,
        totalMontant: venteGroup.totalMontant,
        venteItems: venteGroup.venteItems,
    };
  });

  return result_vente
}

export const stock_par_produit = (stocksList) => {
  const groupedStock = groupByProduct(stocksList, 'produit');

  const result_stock = calculateSumAndLatest(groupedStock, 'date_creation');

  return result_stock
}

export const stock_disponible = (stocksList, ventesList) => {
  // Utiliser la fonction pour calculer le stock disponible
  const result_stock_disponible = calculateStockDisponible(stock_par_produit(stocksList), vente_par_produit(ventesList));

  return result_stock_disponible
}


export function isDateBetween(startDateStr, endDateStr, targetDateStr) {
    const parseDate = (dateStr) => {
      const parsedDate = new Date(dateStr);
      return isNaN(parsedDate) ? null : parsedDate;
  };

  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);
  const targetDate = parseDate(targetDateStr);

  if (startDate === null || endDate === null || targetDate === null) {
      // Une des dates n'est pas valide
      console.error("Une ou plusieurs dates ne sont pas valides.");
      return false;
  }

  return targetDate >= startDate && targetDate <= endDate;
}


export function profile_permission_check(liste1, liste2) {
  // Vérifie que les paramètres sont des tableaux
  if (!Array.isArray(liste1) || !Array.isArray(liste2)) {
    console.log("Les deux paramètres doivent être des tableaux | Profil check");

    return false
  }

  // Utilisez la méthode some() pour vérifier si au moins un élément de liste1 est présent dans liste2
  return liste1.some(element => liste2.includes(element));
}


// Fonctions pour verifier les permissions
// export function hasPermission(permission, user) {
//   return user?.profile_permissions_list ? user.profile_permissions_list?.includes(permission) : false;
// }

// export function hasAnyPermission(permissions, user) {
//   return permissions.some(permission => hasPermission(permission, user));
// }

export function isAdmin(user) {
  return user.profile === "admin" && (!user.profile_permissions_list || user.profile_permissions_list?.length === 0);
}

export function isClientAdmin(user) {
  return user.profile === "client-admin";
}

export function hasProfilePermission(permissions, user) {
  return profile_permission_check(permissions, user);
}