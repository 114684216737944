import React from 'react'
import { FaShoppingCart } from 'react-icons/fa'
import { FormatPrice } from '../../constances/functions'
import { Link } from 'react-router-dom'
import { AiOutlineRight } from 'react-icons/ai'

const BolckStat = ({bgClassColor, textClassColor, description, value, path}) => {
  return (
    <div className={`${bgClassColor} ${(value || value === 0) ? 'flex' : 'hidden'} shadow-sm min-h-[130px] xl:w-[24.6%] w-[100%] mb-3 xl:mx-[0.2%] flex-col justify-between rounded-md`}>
      <div className={`${textClassColor} flex flex-col items-center justify-between p-3 flex-grow`}>

        {/* <span className={`${textClassColor} flex justify-center border-[2px] border-white items-center min-w-[50px] min-h-[50px] text-lg rounded-full p-1 mr-3`}>
            <FaShoppingCart/>
        </span> */}

        <div className='flex flex-col items-center justify-center flex-grow max-w-[100%]'>
            <span className='font-light text-md text-center'>{description}</span>
            <span className='font-bold text-[25px]'>{value}</span>
        </div>
      </div>

      {path && <Link to={path} className={`${textClassColor} py-[3px] flex items-center justify-center bg-[rgba(0,0,0,0.12)]`}>
        <span className='text-md font-light mr-3'>Plus de details</span>
        <span className='text-md'>
          <AiOutlineRight/>
        </span>
      </Link>}
    </div>
  )
}

export default BolckStat