import React from 'react'

const LabelValue = ({label, value}) => {
  return (
    <div className='mb-4 border-b-[1px] pb-2 rounded'>
        <span className='font-bold text-sm text-color-plt-1'>{label}</span>
        <p className='font-normal text-md text-justify'>{value}</p>
    </div>
  )
}

export default LabelValue