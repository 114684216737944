export const IMAGES = {
    auth_1: require('../assets/images/1.jpg'),
    auth_2: require('../assets/images/2.jpg'),
    auth_3: require('../assets/images/3.jpg'),
    auth_4: require('../assets/images/4.jpg'),
    auth_5: require('../assets/images/5.jpg'),
    auth_6: require('../assets/images/6.jpg'),
    
    loading_btn: require('../assets/images/loading.gif'),
    logo_m: require('../assets/images/logo-m.png'),
    logo: require('../assets/images/logo.png'),
}