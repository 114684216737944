import React, { useRef } from 'react'
import { AiOutlineClose, AiOutlinePlus, AiOutlineFilePdf, AiOutline, AiOutlinePlusPrinter, AiOutlineEdit } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { BtnForm, GenericTable, HeaderApp, HomeMain } from '../../../components';
import { URL_PLATEFORM, checkPermission, datas, functions, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
 
const Produit = () => {

    const {
        produits,
        famillesProduits,
        formeProduits,
        voiesProduits, 
        fournisseurs, 
        agents, 
        currencies, 
        countries, 
        user, 
        setProduitDetailsCmponent} = usePlateformContext();

    const navigate = useNavigate();

    let data_exists = false;

    const componentRef = useRef();

    const handlePront = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'liste-des-fournisseur',
        onAfterPrint: () => console.log("print successfuly")
    });

    const columns = [
        {key: "reference", label: "Code"},
        {key: "designation", label: "Désignation"},
        {key: "famille", label: "Famille", value: row => row.famille ? getElementData(row.famille, famillesProduits)?.label : ''},
        {key: "forme", label: "Forme", value: row => row.forme ? getElementData(row.forme, formeProduits)?.label : ''},
        {key: "dosage", label: "Dosage"},
        {key: "voie", label: "Voie", value: row => row.voie ? getElementData(row.voie, voiesProduits)?.label : ''},
        
        {key: "", value: row => <div className='flex items-center'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => setProduitDetailsCmponent(row.id)}>
                <span className='mr-1'>Details</span>
                <AiOutlinePlus/>
            </button>

            {(functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_PRODUIT, datas.PERMISSIONS.SUPPRIMER_PRODUIT], user.profile_permissions_list)) && <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/produits/list/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>}
        </div>}
    ]

    // console.log(produits)

  return (
    <div className=''>
        <HeaderApp
            title="Liste de produits"
            links={[
                {
                    description: "Liste",
                    path: "/produits",
                    icon: "",
                    active: true
                },
                {
                    description: "Nouveau",
                    path: "/produits/create",
                    icon: "",
                }
            ]}
        />
        
        <div ref={componentRef} className='print:p-2'>

            <div 
                className='p-2 bg-white rounded-lg'
            >

                <GenericTable
                    data={produits}
                    columns={columns}
                />

            </div>
        </div>
    </div>
  )
}

export default Produit