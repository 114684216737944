import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

const ApexChartPieComp = ({series = [], categories = [], colors = [], title, horizontal = false, type="pie", formatter="", dataLabels = false, height = 350}) => {
  const [widthChart, setWidthChart] = useState(0);
  const [chartData, setChartData] = useState({
    series: series,
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Ventes au comptoir', 'Ventes par commande'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom',
          }
        }
      }]
    },
  });

  // useEffect(() => {
  //   setChartData({
  //     series: series,
  //     options: {
  
  //       chart: {
  //         type: type,
  //         height: height,
  //         stacked: true
  //       },
  
  //       plotOptions: {
  //         bar: {
  //           horizontal: horizontal,
  //           columnWidth: '55%',
  //           endingShape: 'rounded',
  //         },
  //       },
  
  //       dataLabels: {
  //         enabled: dataLabels,
  //       },
  
  //       stroke: {
  //         show: true,
  //         width: 2,
  //         colors: ['transparent'],
  //       },
  
  //       xaxis: {
  //         categories: categories,
  //       },
  
  //       yaxis: {
  //         title: {
  //           text: title,
  //         },
  //       },
  
  //       fill: {
  //         opacity: 1,
  //       },
  
  //       tooltip: {
  //         y: {
  //           formatter: (val) => `${val} ${formatter}`,
  //         },
  //       },
  
  //     },
  //   })
  // }, [type, series, categories, title])

  const divRef = useRef(null);

  useEffect(() => {
    // La taille du div sera disponible ici après le rendu
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      setWidthChart(width);
      console.log('Largeur du div :', width);
    }
  }, [divRef]);


  return (
    <div id="chart_pie" className='w-full flex flex-col z-0' ref={divRef}>
      {/* <Chart options={chartData.options} series={chartData.series} type={type}  /> */}
      <Chart 
        options={{
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: categories,
          responsive: [{
            breakpoint: 480,
            options: {
              // chart: {
              //   width: 300
              // },
              legend: {
                position: 'bottom',
              }
            }
          }],

          colors: colors
        }} 
    
        series={series} 
        type={type}  
        width={(widthChart * 1)}
        height={widthChart
        
        }
      />
    </div>
  );
};

export default ApexChartPieComp;
