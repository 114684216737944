import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BtnForm, FormInput, FormSelect, HeaderApp, HomeMain, SelectFilter } from '../../../components';
import { usePlateformContext } from '../../../contexts';
import { AiOutlinePrinter } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { getElementData } from '../../../constances';

const StockAdd = () => {

  const navigate = useNavigate();

  const {currencies, user, create_stock, countries, produits, fournisseurs, pointVenteSelected} = usePlateformContext();

  const model_data = {
    site: pointVenteSelected,
    produit: '',
    fournisseur: '',
    prix_unitaire: '',
    prix_achat: '',
    quantite: '',
    user: user?.id,
    date_fab: null,
    date_exp: null,
  }

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }


  const handleSubmit = async (e) => {
    e.preventDefault()

    // Validation du formulaire

    const errors = {}; // Objet pour stocker les erreurs

    if (!credentials.prix_unitaire) {
        errors.prix_unitaire = "Entrer le prix unitaire";
    }

    if (!credentials.prix_achat) {
        errors.prix_achat = "Entrer le prix d'achat";
    }

    if (!credentials.produit) {
        errors.produit = "Selectionner le produit";
    }

    if (!credentials.quantite) {
        errors.quantite = "Entrer la quantité";
    }


    if (Object.keys(errors).length > 0) {
        setCredentialsError(errors);
    } else {
    let response = await create_stock(credentials);

    if (response) {
        setCredentials(model_data);
        navigate('/stocks/historique');
    }
    }
      
  }


  const [optionsFournisseurs, setOptionsFournisseurs] = useState([]);
  const [optionsProduits, setOptionsProduits] = useState([]);


  useEffect(() => {
    let produits_list = produits?.map((item) => {
      return {label: item.designation, value: item.id}
    });

    setOptionsProduits(produits_list)
  }, [produits])

  useEffect(() => {
    let fournisseurs_list = fournisseurs?.map((item) => {
      return {label: item.designation, value: item.id}
    });

    setOptionsFournisseurs(fournisseurs_list)
  }, [fournisseurs])

  const [total_vente, set_total_vente] = useState(0)
  const [total_achat, set_total_achat] = useState(0)
  const [marge_benefice, set_marge_benefice] = useState(`${0} (${0}%)`)

  useEffect(() => {
    let total_vente = (credentials.prix_unitaire && credentials.quantite) ? credentials.prix_unitaire * credentials.quantite : 0
    let total_achat = (credentials.prix_achat && credentials.quantite) ? credentials.prix_achat * credentials.quantite : 0

    let benefice = (total_vente !== 0 && total_achat !== 0) ? (total_vente - total_achat).toFixed(0) : 0
    let pourcentage_benefice = (total_vente !== 0 && total_achat !== 0) ? ((100 * benefice) / total_achat).toFixed(0) : 0

    let marge_benefice = `${benefice} (${pourcentage_benefice}%)`

    set_total_achat(total_achat)
    set_total_vente(total_vente)
    set_marge_benefice(marge_benefice)
  }, [credentials])

  useEffect(() => {
    setCredentials({...credentials, site : pointVenteSelected})
  }, [pointVenteSelected])

  
  return (
    <div>
      <HeaderApp
          title="Enregistrer le stock"
          links={[
              {
                  description: "Suivit de stock",
                  path: "/stocks",
                  icon: "",
              },
              {
                  description: "Historique",
                  path: "/stocks/historique",
                  icon: "",
              },
              {
                  description: "Nouveau",
                  path: "",
                  icon: "",
                  active: true
              }
          ]}
      />

      <div className='flex flex-col justify-start items-center my-4'>
          <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3'>      
            <FormSelect
                required={true}
                label={"Fournisseur"}
                description={"Selectionner le fournisseur"}
                value={credentials.fournisseur}
                error={credentialsError.fournisseur}
                onChange={handleChange}
                onFocus={() => handleChangeError("fournisseur", null)}
                name={"fournisseur"}
                options={optionsFournisseurs}
            />

            <FormSelect
              required={true}
              label={"Produit"}
              description={"Selectionner le produit"}
              value={credentials.produit}
              error={credentialsError.produit}
              onChange={handleChange}
              onFocus={() => handleChangeError("produit", null)}
              name={"produit"}
              options={optionsProduits}
            />

            <div className='flex items-start w-full xl:flex-row flex-col'>
                <FormInput
                    type={"number"}
                    label={"Prix unitaire (vente)"}
                    required={true}
                    placeholder={"Prix unitaire"}
                    autoComplete='off'
                    value={credentials.prix_unitaire}
                    error={credentialsError.prix_unitaire}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("prix_unitaire", null)}
                    name={"prix_unitaire"}
                    className={"w-full xl:mr-1"}
                />

                <FormInput
                    type={"number"}
                    label={"Prix unitaire (achat)"}
                    required={true}
                    placeholder={"Prix unitaire"}
                    autoComplete='off'
                    value={credentials.prix_achat}
                    error={credentialsError.prix_achat}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("prix_achat", null)}
                    name={"prix_achat"}
                    className={"w-full xl:ml-0"}
                />

                <FormInput
                    type={"number"}
                    label={"Quantité"}
                    required={true}
                    placeholder={"Quantité"}
                    autoComplete='off'
                    value={credentials.quantite}
                    error={credentialsError.quantite}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("quantite", null)}
                    name={"quantite"}
                    className={"w-full xl:ml-1"}
                />
            </div>

            {/* <div className='flex items-start w-full xl:flex-row flex-col'>
                <FormInput
                    type={"number"}
                    label={"Toatl (achat)"}
                    required={false}
                    placeholder={"Toatl (achat)"}
                    value={total_achat}
                    name={"label"}
                    disabled={true}
                    className={"w-full xl:mr-1"}
                />

                <FormInput
                    type={"number"}
                    label={"Toatl (vente)"}
                    required={false}
                    placeholder={"Toatl (vente)"}
                    value={total_vente}
                    error={credentialsError.label}
                    name={"label"}
                    className={"w-full xl:mx-0"}
                    disabled={true}
                />

                <FormInput
                    type={"text"}
                    label={"Marge beneficiare"}
                    required={false}
                    placeholder={"Marge beneficiare"}
                    value={marge_benefice}
                    error={credentialsError.label}
                    className={"w-full xl:ml-1"}
                    disabled={true}
                />
            </div> */}

            <div className='flex items-start w-full xl:flex-row flex-col'>
                <FormInput
                    type={"date"}
                    label={"Date de fabrication"}
                    required={true}
                    placeholder={"Date de fabrication"}
                    autoComplete='off'
                    value={credentials.date_fab}
                    error={credentialsError.date_fab}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("date_fab", null)}
                    name={"date_fab"}
                    className={"w-full xl:mr-1"}
                />

                <FormInput
                    type={"date"}
                    label={"Date de péremption"}
                    required={true}
                    placeholder={"Date de péremption"}
                    autoComplete='off'
                    value={credentials.date_exp}
                    error={credentialsError.date_exp}
                    onChange={handleChange}
                    onFocus={() => handleChangeError("date_exp", null)}
                    name={"date_exp"}
                    className={"w-full xl:ml-1"}
                />
            </div>

          <BtnForm
              text={"Enregistrer"}
              type={"submit"}
          />
          </form>
      </div>
    </div>
  )
}

export default StockAdd