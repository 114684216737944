import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { datas, getElementData } from '../../../constances';
import { BtnForm, CheckBoxList, FormInput, FormSelect, HeaderApp, HomeMain } from '../../../components';
import { AiOutlineDelete } from 'react-icons/ai';

const AgentProfileEdit = () => {

    const {id_profile} = useParams();

    const {
        update_user_profile,
        delete_user_profile,
        profiles_users, 
        user
    } = usePlateformContext();

    const navigate = useNavigate();

    let model_data = {
        structure: user?.profile === 'admin' ? "" : user?.structure,
        type_profile: user?.profile === 'admin' ? "admin" : 'client',
        permissions_list: [],
        label: '',
    };

    let profile_user = getElementData(id_profile, profiles_users)
    
    const [credentials, setCredentials] = useState(profile_user ? profile_user : model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Validation du formulaire

        const errors = {}; // Objet pour stocker les erreurs

        if (!credentials.label) {
            errors.label = "Entrer le nom du profil";
        }

        if (credentials.permissions_list.length === 0) {
            errors.permissions_list = "Selectionner au moins une permission";
        }

        if (Object.keys(errors).length > 0) {
            setCredentialsError(errors);
        } else {
            let response = await update_user_profile(credentials);

            if (response) {
                setCredentials(model_data);
                navigate("/profiles-users/list");
            }
        }
    
    }

    const handleDelete = async () => {
        let response = await delete_user_profile(credentials);
        
        if (response) {
            navigate(-1);
        }
    }

//
    function ajouterOuSupprimerElement(liste, element) {
        const index = liste.indexOf(element);
        
        if (index === -1) {
            // L'élément n'existe pas dans la liste, ajoutons-le.
            liste.push(element);
        } else {
            // L'élément existe dans la liste, supprimons-le.
            liste.splice(index, 1);
        }

        return liste;
    }   

    const handleChangeCheckBoxList = (name, value) => {
        setCredentials({...credentials, [name] : value})
    }

    useEffect(() => {
        setCredentials(getElementData(id_profile, profiles_users))
    }, [id_profile, profiles_users])

    const permissions = user.profile !== "admin" ? datas.permissions_profile_client : datas.permissions_profile_admin

    return (
        <div>
            <HeaderApp
                title="Profil utilisateur"
                links={[
                    {
                        description: "Liste",
                        path: "/profiles-users",
                        icon: "",
                    },
                    {
                        description: "Modifier",
                        path: "/",
                        icon: "",
                        active: true
                    }
                ]}
            />

            <div className='flex flex-col justify-start items-center py-3'>
                <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3 rounded-md'>

                    <div>

                        <div className='flex justify-between md:flex-row flex-col'>
                            <FormInput
                                type={"text"}
                                label={"Profil"}
                                required={true}
                                placeholder={"Nom du profil"}
                                autoComplete='off'
                                value={credentials?.label}
                                error={credentialsError.label}
                                onChange={handleChange}
                                onFocus={() => handleChangeError("label", null)}
                                name={"label"}
                                className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

                            />
                        </div>

                        <div className='w-full my-3'>
                            <h3 className='font-normal text-sm mb-1'>Permissions</h3>
                            <div className='flex flex-wrap border-[1px] px-1'>
                                {permissions.map((item, index) => {
                                    return(
                                        <CheckBoxList 
                                            key={index} 
                                            list={credentials?.permissions_list} 
                                            item={item} 
                                            label={`${item}`} 
                                            onPress={() => {handleChangeCheckBoxList("permissions_list", ajouterOuSupprimerElement(credentials.permissions_list, item)); handleChangeError("permissions_list", null)}}
                                        />
                                    )
                                })}
                            </div>
                            {credentialsError.permissions_list && <span className='text-red-900 mt-2 text-sm'>{credentialsError.permissions_list}</span>}
                        </div>

                        <div className='w-full my-5'>
                            <span className='text-red-900 text-sm flex items-center cursor-pointer' onClick={() => handleDelete()}>
                                <AiOutlineDelete/>
                                <small className='text-sm ml-2'>Supprimer (non recommander)</small>
                            </span>
                        </div>


                        <BtnForm
                            type="submit"
                            text={"Enregistrer"}
                        />

                    </div>

                </form>
            </div>
        </div>
    )
}

export default AgentProfileEdit