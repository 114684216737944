import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineClose, AiOutlinePlus, AiOutlineFilePdf, AiOutline, AiOutlinePlusPrinter, AiOutlineEdit, AiFillShop } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { BtnForm, GenericTable, HeaderApp, HomeMain } from '../../../components';
import { URL_PLATEFORM, checkPermission, functions, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
import { FormatPrice, formaterDate } from '../../../constances/functions';
 
const Stock = () => {

    const {
        produits,
        famillesProduits,
        formeProduits,
        voiesProduits, 
        fournisseurs, 
        agents, 
        currencies, 
        countries, 
        user, 
        setProduitDetailsCmponent,
        suivitStocksList,
        pointVenteSelected,
        sites,
        stocksList,
        ventesList,
    } = usePlateformContext();

    const navigate = useNavigate();


    const componentRef = useRef();

    const handlePront = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'liste-des-fournisseur',
        onAfterPrint: () => console.log("print successfuly")
    });


    let links_admin = [
        {
            description: "Suivit de stock",
            path: "/stocks",
            icon: "",
            active: true
        },
    ]

    let links_client = [
        {
            description: "Suivit de stock",
            path: "/stocks",
            icon: "",
            active: true
        },
        {
            description: "Historique",
            path: "/stocks/historique",
            icon: "",
        },
        {
            description: "Nouveau",
            path: "/stocks/create",
            icon: "",
        }
    ]


    const [stock_disponible, set_stock_disponible] =  useState([]);
    const [credentials, setCredentials] = useState({point_vente: ''});
    const [devise_point_de_vente, set_devise_point_de_vente] = useState('');

    let point_de_vente_id = pointVenteSelected?.toString();

    const columns = [
        {key: "produit", label: "Produit", value: row => <button className='cursor-pointer font-medium' onClick={()  => setProduitDetailsCmponent(row.produit)}>{getElementData(row.produit, produits)?.designation}</button>},
        {key: "quantite", label: "Quantité", value: row => row.quantite},
        ((user.profile === "admin" && credentials.point_vente) || user.profile !== "admin") && {key: "prix_unitaire", label: "Prix Unitaire", value: row => `${FormatPrice(row.prix_unitaire)} ${devise_point_de_vente}`},
        
        ((user.profile === "admin" && credentials.point_vente) || user.profile !== "admin") && {key: "dernier_date_exp", label: "Date de péremption", value: row => formaterDate(row.date_exp)},
        
        {key: "", label: "Status", value: row => row.quantite > 0 ? 'Disponible' : 'Non disponible'},

        (user.profile === "admin" && credentials.point_vente) && {key: "site", label: "Point de vente", value: row => getElementData(row.site, sites)?.label},
    ]

    let data_stock_filter = stocksList.filter(item => credentials.point_vente ? item.site.toString() === credentials.point_vente : true);
    let data_vente_filter = ventesList.filter(item => credentials.point_vente ? item.site.toString() === credentials.point_vente : true);

    let data_stock = user.profile === "admin" ? data_stock_filter : stocksList.filter(item => item.site.toString() === point_de_vente_id);
    let data_vente = user.profile === "admin" ? data_vente_filter : ventesList.filter(item => item.site.toString() === point_de_vente_id);

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    useEffect(() => {

        let devise = '';
        let point_vente = getElementData(
            ((user.profile === "admin" && credentials.point_vente) ? 
            credentials.point_vente 
            : point_de_vente_id), sites
        );

        devise = getElementData(point_vente?.currency, currencies)?.iso_code;


        let stock =  data_stock;
        let vente =  data_vente;

        let stock_disponible = functions.stock_disponible(
            stock, 
            vente
        );

        set_devise_point_de_vente(devise)
        set_stock_disponible(stock_disponible)
    }, [stocksList, ventesList, pointVenteSelected, credentials])



  return (
    <div className=''>
        <HeaderApp
            title="Stock disponible"
        />

        {user.profile === "admin" && <div className='p-3 rounded-md bg-white mb-3'>
            <label htmlFor='point_de_vente' className='flex h-[30px] border-[1px] border-secondary cursor-pointer items-center px-1 py-1 rounded-md xl:w-[250px] w-[80%]'>
                <span className='mr-[10px] text-[30px] xl:text-[25px] text-secondary'>
                    <AiFillShop />
                </span>

                <select onChange={handleChange} id='point_de_vente' value={credentials.point_vente} name='point_vente' className='bg-[transparent] font-bold w-full cursor-pointer outline-none text-secondary'>
                    <option value={''} className='py-3'>Tous les points de vente</option>
                    {sites.map((item, index) => <option key={index} value={item.id} className='uppercase text-black'>{item.label}</option>)}
                </select>
            </label>
        </div>}
        
        <div ref={componentRef} className='print:p-2'>
            <div 
                className='p-2 bg-white rounded-lg'
            >
                <GenericTable
                    data={stock_disponible}
                    columns={columns}
                />
            </div>
        </div>
    </div>
  )
}

export default Stock