import React, { useEffect, useState } from 'react'
import { IMAGES } from '../../constances';

const AuthMain = ({children}) => {

  const bankImage = [
    IMAGES.auth_1,
    IMAGES.auth_2,
    IMAGES.auth_3,
    IMAGES.auth_4,
    IMAGES.auth_5,
    IMAGES.auth_6,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % bankImage.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [bankImage.length]);

  return (
    <div 
      className='h-[100vh] w-full bg-white flex items-center justify-center'
      style={{
          backgroundImage: `url(${bankImage[0]})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 3s ease',
      }} 
    >
        
        {/* <div
            style={{
                backgroundImage: `url(${bankImage[currentIndex]})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transition: 'background-image 3s ease',
            }}  
            className='w-[50%] md:flex hidden h-full'>
          <div className='w-full bg-[var(--rgba-black)] h-full flex flex-col justify-end items-start p-5'>
            <div className='bg-white px-1'>
              <h1 className='text-secondary font-bold text-lg'>Flash <span className='text-primary'>X</span></h1>
            </div>
            <p className='text-white font-normal text-md'>Une solution unique pour une bonne gestion de votre structure</p>
            <small className='text-white font-light text-sm'>Notre preoccupation est votre reussite [...]</small>
          </div>
        </div> */}

        <div className='w-screen h-screen flex items-center justify-center bg-[rgba(0,0,0,0.5)]'>
          <div className='md:w-[50%] w-[100%] flex items-center justify-center'>
            {children}
          </div>
        </div>

        
      </div>
  )
}

export default AuthMain