import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Axios } from '../constances';
import { getItem, removeItem, setItem } from '../services';

// Context Provider qui engloble toute les views de la plateforme, l'application de gestion 


const PlateformContext = createContext();

export const PlateformeContextProvider = ({children}) => {

    const [user, setUser] = useState({});

    const [formLoading, setFormLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [permissions, setPermissions] = useState([]);
    const [sites, setSites] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    const [pointVenteSelected, setPointVenteSelected] = useState({});
    const [abonnement_alert, setAbonnement_alert] = useState({display: false});

    const [currencies, setCurrencies] = useState([]);
    const [countries, setCountries] = useState([]);
    const [agents, setAgents] = useState([]);
    const [profiles_users, set_profiles_users] = useState([]);
    const [produits, setProduits] = useState([]);
    const [famillesProduits, setFamillesProduits] = useState([]);
    const [formeProduits, setFormeProduits] = useState([]);
    const [voiesProduits, setVoiesProduits] = useState([]);
    const [stocksList, setStocksList] = useState([]);
    const [suivitStocksList, setSuivitStocksList] = useState([]);
    const [ventesList, setVentesList] = useState([]);
    const [suivitVentesList, setSuivitVentesList] = useState([]);

    const [compteClientList, setCompteClientList] = useState([]);

    const [produitDetailsCmponent, setProduitDetailsCmponent] = useState('');

    const [authLog, setAuthLog] = useState(
        () => {
            let authLog_storage = getItem('authLog');

            if (!!authLog_storage) {
                return authLog_storage
            }else{
                removeItem('authLog');
                return null
            }
        }
    );

    const [token, setToken] = useState(
        () => {
            let token_storage = getItem('token');

            if (!!token_storage) {
                return token_storage
            }else{
                removeItem('token');
                return null
            }
        }
    );

    let config_header_permission = {
        headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`
        }
    };

    let config_header_permission_data = {
        headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": `Token ${token}`
        }
    };

    const get_user_profiles = async (token) => {
        try {
            setFormLoading(true);
            setIsLoading(true);
      
            const response = await Axios.get(`/api/users/profile-permissions/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": 'Token ' + token
                }
            });
            
            set_profiles_users(response.data);

            setFormLoading(false);
            setIsLoading(false);
        
        } catch (error) {
            console.error(`error: ${error}`);
            setFormLoading(false);
            setIsLoading(false);
        
            const errorMessage = error.response
                ? error.response.data
                : "Erreur.";
        
            toast.error(errorMessage);
        }
    };

    const get_user_info = async (token) => {
        try {
            setFormLoading(true);
            setIsLoading(true);
      
            const response = await Axios.get(`/api/users/user-info/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": 'Token ' + token
                }
            });
            
            setUser(response.data);
            setFormLoading(false);
            setIsLoading(false);
        
        } catch (error) {
            console.error(`error: ${error}`);
            setFormLoading(false);
            setIsLoading(false);
        
            const errorMessage = error.response
                ? error.response.data
                : "Erreur.";
        
            toast.error(errorMessage);
        }
    };

    const get_subscriptions = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/subscriptions/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setSubscriptions(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_sites = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/sites/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setSites(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_fournisseurs = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/fournisseurs/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setFournisseurs(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_currencies = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/configurations/currencies/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setCurrencies(response.data);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const get_countries = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/configurations/countries/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setCountries(response.data);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const get_users = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/users/list`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setAgents(response.data);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const get_produits = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/produits/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setProduits(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_formes = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/produits/formes`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setFormeProduits(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_voies = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/produits/voies`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setVoiesProduits(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_familles = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/produits/familles`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setFamillesProduits(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_stocks = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/stocks/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setStocksList(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_suivit_stocks = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/stocks/suivit`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setSuivitStocksList(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };


    const get_ventes = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/ventes/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setVentesList(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_suivit_ventes = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/ventes/suivi-ventes/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setSuivitVentesList(response.data);
            }

            return response.data

        } catch (error) {
            console.error(error);

            return []
        }
    };

    const get_comptes_clients = async (tokenValue) => {

        try {
            const response = await Axios.get(`/api/structures/`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${tokenValue}`
                }
            });

            if (response) {
                setCompteClientList(response.data);
            }

        } catch (error) {
            console.error(error);
        }
    };

    // Recuperer toutes les donnees
    const get_all_data = async (token) => {
        setIsLoading(true);

        await get_user_profiles(token);
        await get_comptes_clients(token);
        await get_currencies(token);
        await get_countries(token);
        await get_users(token);
        await get_fournisseurs(token);
        await get_subscriptions(token);
        await get_produits(token);
        await get_familles(token);
        await get_formes(token);
        await get_voies(token);
        await get_stocks(token);
        await get_suivit_stocks(token);
        await get_ventes(token);
        await get_suivit_ventes(token);

        let sites_all = await get_sites(token);
        let user = await get_user_info(token);


        let reference_point_vente = await getItem('idPointVente');

        setIsLoading(false);

        if (reference_point_vente) {
            setPointVenteSelected(reference_point_vente)
        }else{
            if (sites_all.length > 0) {
                setPointVenteSelected({id: sites_all[0]?.id})
            }
        }
    }



    // Fonction de creation et de mise a jour
    const create_compte_client = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/structures/`, inputs , config_header_permission_data);

            if (response) {
                setCompteClientList(response.data);
                
                await get_users(token);
                
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_compte_client = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/structures/${inputs.id}/`, inputs , config_header_permission_data);

            if (response) {
                setCompteClientList(response.data);
                
                await get_users(token);
                
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_produits_file = async (file) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/produits/file-create`, {file: file} , config_header_permission_data);

            if (response) {
                setProduits(response.data);
                response_api = true;

                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_produit = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/produits/`, inputs , config_header_permission_data);

            if (response) {
                setProduits(response.data);
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_produit = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/produits/${inputs.id}/`, inputs , config_header_permission_data);

            if (response) {
                setProduits(response.data);
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_stock = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/stocks/`, inputs , config_header_permission);

            if (response) {
                setStocksList(response.data);
                response_api = true;

                await get_suivit_stocks(token);

                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_stock = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/stocks/${inputs.id}`, inputs , config_header_permission);

            if (response) {
                setStocksList(response.data);
                response_api = true;

                await get_suivit_stocks(token);

                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_vente = async (inputs) => {

        let response_api = {valid: false};
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/ventes/`, inputs , config_header_permission);

            if (response) {
                response_api = {
                    valid: true,
                    data: response.data
                };

                await get_ventes(token);
                await get_suivit_ventes(token);
                await get_suivit_stocks(token);

                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };


    const create_user_profile = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/users/profile-permissions/`, inputs , config_header_permission);

            if (response) {
                set_profiles_users(response.data);
                response_api = true;

                // await get_user_profiles(token);

                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_user_profile = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/users/profile-permissions/${inputs.id}/`, inputs , config_header_permission);

            if (response) {
                set_profiles_users(response.data);

                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Mis à jour avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const delete_user_profile = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.delete(`/api/users/profile-permissions/${inputs.id}/`, config_header_permission);

            if (response) {
                await get_user_profiles(token);
                
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Mis à jour avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_user_agent = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/users/list`, inputs , config_header_permission);

            if (response) {
                setAgents(response.data);
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau agent a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_user_agent = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/users/list/${inputs.id}`, inputs , config_header_permission);

            if (response) {
                setAgents(response.data);
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Mis à jour avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_site = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/sites/`, inputs , config_header_permission);

            if (response) {
                setSites(response.data);

                await get_subscriptions(token);

                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau site a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_site = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/sites/${inputs.id}`, inputs , config_header_permission);

            if (response) {
                setSites(response.data);

                await get_subscriptions(token);

                response_api = true;
                setFormLoading(false);
                setIsLoading(false);
                toast.success("Mis à jour avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const create_fournisseur = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/fournisseurs/`, inputs , config_header_permission);

            if (response) {
                setFournisseurs(response.data);
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);

                toast.success("Le nouveau fournisseur a été crée avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    const update_fournisseur = async (inputs) => {

        let response_api = false;
        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/fournisseurs/${inputs.id}`, inputs , config_header_permission);

            if (response) {
                setFournisseurs(response.data);
                response_api = true;
                setFormLoading(false);
                setIsLoading(false);
                toast.success("Mis à jour avec succès !");
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur s'est produite ! verifiez vos informations puis réessayer.");
        }

        return response_api;
    };

    // Mise a jour
    const selectPointVente = async (idPointVente, label) => {
        // Recherche du point de vente dans la liste des sites
        const selectedPointVente = sites.find(item => item.id.toString() === idPointVente.toString());
    
        // Enregistrement du point de vente sélectionné
        setItem("idPointVente", idPointVente);
    
        // Mise à jour de l'état du point de vente sélectionné
        setPointVenteSelected(idPointVente);
    };
    
    const login = async (inputs) => {

        let response_api = false; 

        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/users/login/`, inputs);

            if (response) {
                if (response.data.sending_otp) {
                    // Envoie de l'otp
                    response_api = {
                        sending_otp: true,
                    }

                    setAuthLog(response.data.token);
                    localStorage.setItem('auth', JSON.stringify(response.data.token));
                    toast.success("Votre code OTP a été envoyé avec succès. Veuillez consulter votre messagerie pour récupérer le code.");
                }else{
                    // Connexion direct
                    await get_all_data(response.data.token)

                    response_api = {
                        sending_otp: false,
                    }

                    setToken(response.data.token);
                    localStorage.setItem('token', JSON.stringify(response.data.token));
                }
                
                setFormLoading(false);
                setIsLoading(false);
            }

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Une erreur d'authentification s'est produite. Veuillez vérifier vos informations et réessayer.");
        }

        return response_api;
    };

    const otp_verification = async (inputs) => {

        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.post(`/api/users/otp-verification/`, inputs , {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${authLog}`
                }
            });

            if (response) {
                setUser(response.data);
                setToken(authLog);

                localStorage.setItem('token', JSON.stringify(authLog));

                await get_all_data(authLog);
                
                removeItem('auth');
            }

            toast.success("Votre code OTP a été vérifié avec succès et vous êtes maintenant connecté.");
            setFormLoading(false);
            setIsLoading(false);

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
            toast.error("Nous sommes désolés, mais votre code OTP n'a pas pu être vérifié. Veuillez vérifier que vous avez saisi correctement le code et réessayer.");
        }
    };

    const password_edit = async (inputs) => {

        setFormLoading(true);
        setIsLoading(true);

        try {
            const response = await Axios.put(`/api/users/edit-password/`, inputs , config_header_permission);
            
            console.log(response.data)
            if (response) {
                setUser(response.data);
                localStorage.setItem('user', JSON.stringify(response.data));
            }

            setFormLoading(false);
            setIsLoading(false);

        } catch (error) {
            console.error(error);
            setFormLoading(false);
            setIsLoading(false);
        }
    };

    const logout = async () => {

        try {
            const response = await Axios.post(`/api/users/logout/`, {} , config_header_permission);

            if (response) {
                localStorage.removeItem('token');
                setUser({});
                setSites([]);
                setToken(null)
                removeItem('idPointVente')
            }

        } catch (error) {
            console.error(error);
        }
    };

  


    useEffect(() => {
        const isLoggedIn = async () => {
            try {
                let token_storage = await getItem('token');
                let token_log = await getItem('auth');

                if (!!token_log) {
                    setAuthLog(token_log)
                }
    
                if (!!token_storage) {
                    setToken(token_storage);
                    setAuthLog(null);

                    await get_all_data(token_storage);

                    return true
                }else{
                    removeItem('token');
                    setToken(null);
                    setUser({});
    
                    return false
                }
      
            } catch (error) {
                console.log(`User not connected`);
                setToken(null);
                setUser({});
                setFormLoading(false);
                setIsLoading(false);
                
                return false;
            }
        }

        isLoggedIn();
    }, [])


    // Selectionner un point de vente
    useEffect(() => {
        const fetchData = async () => {
          let reference_point_vente = await getItem('idPointVente');
      
          if (!!reference_point_vente && sites.filter(item => item.id.toString() === reference_point_vente).length > 0) {
            setPointVenteSelected(reference_point_vente);
          } else {
            if (sites.length > 0) {
                let site = sites[0];

              setPointVenteSelected(site.id);
              setItem('idPointVente', site.id);
            }
          }
        };
      
        fetchData();
    }, [sites]);



    useEffect(() => {
        let site_id = pointVenteSelected.id;
        let filter = subscriptions.filter(item => item.site.toString() === site_id && item.validated === "Valide")[0]
        let abonnement_alert = user.profile !== "admin" ? (filter ? filter.message_alert : {display: false}) : {display: false}

        setAbonnement_alert(abonnement_alert)
    }, [subscriptions, pointVenteSelected, sites])



    let value = {
        // Variables
        token,
        formLoading,
        isLoading,
        user,
        profiles_users,
        compteClientList,
        sites,
        fournisseurs,
        pointVenteSelected,
        currencies,
        agents,
        permissions,
        countries,
        subscriptions,
        produits,
        famillesProduits,
        formeProduits,
        voiesProduits,
        stocksList,
        suivitStocksList,
        ventesList,
        suivitVentesList,

        produitDetailsCmponent,
        abonnement_alert,

        // Fonctions
        setIsLoading,
        login,
        logout,
        otp_verification,
        password_edit,
        selectPointVente,
        create_compte_client,
        update_compte_client,
        create_site,
        update_site,

        create_user_profile,
        update_user_profile,
        delete_user_profile,

        create_user_agent,
        update_user_agent,
        create_fournisseur,
        update_fournisseur,
        create_produits_file,
        create_produit,
        update_produit,
        create_stock,
        update_stock,
        create_vente,

        setProduitDetailsCmponent
    }

    return(
        <PlateformContext.Provider value={value}>{children}</PlateformContext.Provider>
    )
}

export const usePlateformContext = () => useContext(PlateformContext);