import './App.css';
import React, { useEffect, useState } from 'react'

import { AbonnementAlert, DetailsProduits, IsLoadingComponent, SideBar, TopBar } from './components';
import { usePlateformContext } from './contexts';
import { SideBarItem } from './components/shared/SideBar';
import { MdDashboard, MdPerson, MdShoppingCart } from 'react-icons/md';
import { FaBoxOpen, FaBoxes, FaUsers } from 'react-icons/fa';
import { datas, functions } from './constances';
import { RiPhoneFindFill } from 'react-icons/ri';
import { AiFillBell, AiFillShop, AiOutlineBarChart } from 'react-icons/ai';
import { TbRefreshDot, TbTruckDelivery } from 'react-icons/tb';
import { FaBuildingUser } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';

const App = (props) => {

  const location = useLocation();
  const {subscriptions, user, sites, pointVenteSelected, abonnement_alert, isLoading} = usePlateformContext();

  const isActive = (path) => {
      if(path === '/'){
          return  path.includes(location.pathname);
      }else{
          return  path ? location.pathname.includes(path) : false;
      }
  };
    
  const [menu_list_items, set_menu_list_items] = useState([]);
    
  const menu_list = [
      {
          label: "Ventes",
          path: "/ventes",
          icone: <MdShoppingCart />,
          default_active: isActive("/ventes"),
          items: [
            {
              label: "Enregistrer une vente",
              path: '/ventes/create',
              active: isActive("/ventes/create"),
              permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_STOCK], user.profile_permissions_list),
            },
  
            {
              label: "Historique de ventes",
              path: '/ventes/historique',
              active: isActive("/ventes/historique"),
              permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_STOCK], user.profile_permissions_list),
            },
          ],

          permission: functions.isClientAdmin(user) || functions.hasProfilePermission(
              [
                  datas.PERMISSIONS.ENREGISTRER_VENTE, 
                  datas.PERMISSIONS.MODIFIER_VENTE, 
                  datas.PERMISSIONS.CONSULTER_VENTE_HISTORIQUE
              ],
              user.profile_permissions_list
          ),

      },

      {
        label: "Commandes",
        path: "#",
        icone: <RiPhoneFindFill />,
        default_active: isActive("#"),
        items: [
          {
            label: "Commandes en attentes",
            path: '#',
            active: isActive("#"),
            permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_COMMANDE_EN_ATTENTE, datas.PERMISSIONS.VALIDER_COMMANDE], user.profile_permissions_list),
          },

          {
            label: "Commandes validées",
            path: '#',
            active: isActive("#"),
            permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_COMMANDE_VALIDEE], user.profile_permissions_list),
          },
        ],

        permission: functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.CONSULTER_COMMANDE_EN_ATTENTE, 
                datas.PERMISSIONS.CONSULTER_COMMANDE_VALIDEE, 
                datas.PERMISSIONS.VALIDER_COMMANDE, 
            ],
            user.profile_permissions_list
        ),

      },

      {
        label: "Stocks",
        path: "/stocks",
        icone: <FaBoxes/>,
        default_active: isActive("/stocks"),
        items: [
          {
            label: "Enregistrer un stock",
            path: '/stocks/create',
            active: isActive("/stocks/create"),
            permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_STOCK], user.profile_permissions_list),
          },

          {
            label: "Historique de stocks",
            path: '/stocks/historique',
            active: isActive("/stocks/historique"),
            permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_STOCK_HISTORIQUE], user.profile_permissions_list),
          },

          {
            label: "Stock disponible",
            path: '/stocks/disponible',
            active: isActive("/stocks/disponible"),
            permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_STOCK_DISPONIBLE], user.profile_permissions_list),
          },
        ],

        permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.ENREGISTRER_STOCK, 
                datas.PERMISSIONS.MODIFIER_STOCK, 
                datas.PERMISSIONS.CONSULTER_STOCK_DISPONIBLE, 
                datas.PERMISSIONS.CONSULTER_STOCK_HISTORIQUE
            ],
            user.profile_permissions_list
        ),

      },

      {
        label: "Rapports",
        path: "/rapports",
        icone: <AiOutlineBarChart/>,
        default_active: isActive("/rapports"),
        items: [],

        permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.GENERER_RAPPORT_HISTORIQUE_STOCK, 
                datas.PERMISSIONS.GENERER_RAPPORT_HISTORIQUE_VENTE, 
                datas.PERMISSIONS.GENERER_RAPPORT_QUANTITE_PRODUIT_VENDU, 
            ],
            user.profile_permissions_list
        ),

      },

      {
        label: "Produits",
        path: "/produits",
        icone: <FaBoxOpen/>,
        default_active: isActive("/produits"),
        items: [
          {
            label: "Enregistrer un produit",
            path: '/produits/create',
            active: isActive("/produits/create"),
            permission: functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_PRODUIT], user.profile_permissions_list),
          },

          {
            label: "Liste des produits",
            path: '/produits/list',
            active: isActive("/produits/list"),
            permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_PRODUIT, datas.PERMISSIONS.SUPPRIMER_PRODUIT, datas.PERMISSIONS.MODIFIER_PRODUIT], user.profile_permissions_list),
          },
        ],

        permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.ENREGISTRER_PRODUIT, 
                datas.PERMISSIONS.MODIFIER_PRODUIT, 
                datas.PERMISSIONS.SUPPRIMER_PRODUIT, 
                datas.PERMISSIONS.CONSULTER_PRODUIT
            ],
            user.profile_permissions_list
        ),

      },

      {
        label: "Fournisseurs",
        path: "/fournisseurs",
        icone: <TbTruckDelivery />,
        default_active: isActive("/fournisseurs"),
        items: [
          {
            label: "Enregistrer",
            path: '/fournisseurs/create',
            active: isActive("/fournisseurs/create"),
            permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_FOURNISSEUR], user.profile_permissions_list),
          },

          {
            label: "Liste des fournisseurs",
            path: '/fournisseurs/list',
            active: isActive("/fournisseurs/list"),
            permission: functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_FOURNISSEUR, datas.PERMISSIONS.SUPPRIMER_FOURNISSEUR, datas.PERMISSIONS.SUPPRIMER_FOURNISSEUR,], user.profile_permissions_list),
          },
        ],

        permission: functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.ENREGISTRER_FOURNISSEUR, 
                datas.PERMISSIONS.MODIFIER_FOURNISSEUR, 
                datas.PERMISSIONS.CONSULTER_FOURNISSEUR, 
                datas.PERMISSIONS.SUPPRIMER_FOURNISSEUR
            ],
            user.profile_permissions_list
        ),

      },

      {
        label: "Points de ventes",
        path: "/points-ventes",
        icone: <AiFillShop/>,
        default_active: isActive("/points-ventes"),
        items: [
          {
            label: "Enregistrer un compte",
            path: '/points-ventes/compte/create',
            active: isActive("/points-ventes/compte/create"),
            permission: functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_COMPTE_CLIENT], user.profile_permissions_list),
          },

          {
            label: "Liste des comptes",
            path: '/points-ventes/compte/list',
            active: isActive("/points-ventes/compte/list"),
            permission: functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_COMPTE_CLIENT, datas.PERMISSIONS.MODIFIER_COMPTE_CLIENT], user.profile_permissions_list),
          },

          {
            label: "Enregistrer un point de vente",
            path: '/points-ventes/create',
            active: isActive("/points-ventes/create"),
            permission: functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_POINT_DE_VENTE], user.profile_permissions_list),
          },

          {
            label: "Liste des points de vente",
            path: '/points-ventes/list',
            active: isActive("/points-ventes/list"),
            permission: functions.isAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_POINT_DE_VENTE, datas.PERMISSIONS.MODIFIER_POINT_DE_VENTE, datas.PERMISSIONS.SUPPRIMER_POINT_DE_VENTE], user.profile_permissions_list),
          },
        ],

        permission: functions.isAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.ENREGISTRER_POINT_DE_VENTE, 
                datas.PERMISSIONS.MODIFIER_POINT_DE_VENTE, 
                datas.PERMISSIONS.CONSULTER_POINT_DE_VENTE, 
                datas.PERMISSIONS.SUPPRIMER_POINT_DE_VENTE,

                datas.PERMISSIONS.ENREGISTRER_COMPTE_CLIENT, 
                datas.PERMISSIONS.MODIFIER_COMPTE_CLIENT, 
                datas.PERMISSIONS.CONSULTER_COMPTE_CLIENT, 
                datas.PERMISSIONS.SUPPRIMER_COMPTE_CLIENT
            ],
            user.profile_permissions_list
        ),

      },


      {
        label: "Profil utilisateur",
        path: "/profiles-users",
        icone: <MdPerson/>,
        default_active: isActive("/profiles-users"),
        items: [
          {
            label: "Enregistrer un profil utilisateur",
            path: '/profiles-users/create',
            active: isActive("/profiles-users/create"),
            permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_PROFIL_UTILISATEUR], user.profile_permissions_list),
          },

          {
            label: "Liste des profils utilisateurs",
            path: '/profiles-users/list',
            active: isActive("/profiles-users/list"),
            permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_PROFIL_UTILISATEUR, datas.PERMISSIONS.MODIFIER_PROFIL_UTILISATEUR, datas.PERMISSIONS.SUPPRIMER_PROFIL_UTILISATEUR], user.profile_permissions_list),
          },

        ],

        permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.ENREGISTRER_PROFIL_UTILISATEUR, 
                datas.PERMISSIONS.MODIFIER_PROFIL_UTILISATEUR, 
                datas.PERMISSIONS.CONSULTER_PROFIL_UTILISATEUR, 
                datas.PERMISSIONS.SUPPRIMER_PROFIL_UTILISATEUR
            ],
            user.profile_permissions_list
        ),

      },

      {
        label: "Utilisateurs",
        path: "/utilisateurs",
        icone: <FaUsers/>,
        default_active: isActive("/utilisateurs"),
        items: [
          {
            label: "Enregistrer un utilisateur",
            path: '/utilisateurs/create',
            active: isActive("/utilisateurs/create"),
            permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.ENREGISTRER_UTILISATEUR], user.profile_permissions_list),
          },

          {
            label: "Liste des utilisateurs",
            path: '/utilisateurs/list',
            active: isActive("/utilisateurs/list"),
            permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_UTILISATEUR, datas.PERMISSIONS.MODIFIER_UTILISATEUR, datas.PERMISSIONS.SUPPRIMER_UTILISATEUR], user.profile_permissions_list),
          },
        ],

        permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
            [
                datas.PERMISSIONS.ENREGISTRER_UTILISATEUR, 
                datas.PERMISSIONS.MODIFIER_UTILISATEUR, 
                datas.PERMISSIONS.CONSULTER_UTILISATEUR, 
                datas.PERMISSIONS.SUPPRIMER_UTILISATEUR
            ],
            user.profile_permissions_list
        ),

      },

      // {
      //   label: "Abonnement",
      //   path: "/abonnements",
      //   icone: <TbRefreshDot/>,
      //   default_active: isActive("/abonnements"),
      //   items: [
      //     {
      //       label: "Reabonnement",
      //       path: '/abonnements/create',
      //       active: isActive("/abonnements/create"),
      //       permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_STOCK_DISPONIBLE], user.profile_permissions_list),
      //     },

      //     {
      //       label: "Historique",
      //       path: '/abonnements/historique',
      //       active: isActive("/abonnements/historique"),
      //       permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.CONSULTER_STOCK_DISPONIBLE], user.profile_permissions_list),
      //     },
      //   ],

      //   permission: functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission(
      //       [
      //           datas.PERMISSIONS.ENREGISTRER_STOCK, 
      //           datas.PERMISSIONS.MODIFIER_STOCK, 
      //           datas.PERMISSIONS.CONSULTER_STOCK_DISPONIBLE, 
      //           datas.PERMISSIONS.CONSULTER_STOCK_HISTORIQUE
      //       ],
      //       user.profile_permissions_list
      //   ),

      // },
  ]

  // return (
  //   <div className='flex bg-[#eeeeee] relative'>
  //     {/* <div className="xl:basis-[15%] basis-[55px] h-[100vh]">
  //       <SideBar/>
  //     </div> */}
  //     <SideBar/>

  //     <div className="xl:basis-[85%] basis-[100%] h-[100vh] relative overflow-scroll">
  //       <TopBar/>

  //       <div className='pt-14 px-[15px]'>

  //         {/* <div className='w-full'>
  //           <AbonnementAlert value={abonnement_alert}/>
  //         </div> */}

  //         {props.children}
  //       </div>
  //     </div>

  //     <IsLoadingComponent visible={isLoading}/>
  //     <DetailsProduits/>
  //   </div>
  // )

  return(
    <main className='flex bg-[#eeeeee] flex-row items-start relative'>
      
      <SideBar>
        <SideBarItem
          icon={<MdDashboard size={20}/>}
          text={"Tableau de bord"}
          alert
          path={"/"}
          active={isActive("/")}
        />

        {menu_list.filter(item => item.permission).map((item, index) => {
          return(
            <SideBarItem
              key={index}
              icon={<span className='text-[20px]'>{item.icone}</span>}
              text={item.label}
              active={item.default_active}
              items={item.items}
              path={item.path}
            />
          )
        })}

      </SideBar>

      <div className="flex-grow w-full h-screen relative overflow-scroll overflow-x-hidden p-2">
        <div>
          <TopBar/>
        </div>

        <div>{props.children}</div>
      </div>

      <IsLoadingComponent visible={isLoading}/>
      <DetailsProduits/>
    </main>
  )
}

export default App
