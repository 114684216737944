import React, { useRef, useState, useEffect } from 'react';
import { AiFillFileExcel, AiFillFilePdf, AiFillPrinter, AiOutlineExport } from 'react-icons/ai';
import { MdNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import ReactToPrint from 'react-to-print';

import * as XLSX from 'xlsx';


const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 'Tous'];

function GenericTable({ data, columns, onSelectionChange, isSelectionEnabled, exportation = true, viewAllItems = false, maxHieght = '73vh', sorted = true, searching = true, index_number = false}) {
  const componentRef = useRef();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(viewAllItems ? 'Tous' : 10);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(Array.from(selectedRows));
    }
  }, [selectedRows, onSelectionChange]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }
      return newSelectedRows;
    });
  };

  const toggleSelectAll = () => {
    if (selectedRows.size === data.length) {
      setSelectedRows(new Set());
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(new Set(allIds));
    }
  };

  const getSelectedItems = () => {
    return data.filter((item) => selectedRows.has(item.id));
  };

  const isRowSelected = (id) => {
    return selectedRows.has(id);
  };

  const handleRowClick = (id) => {
    if (isSelectionEnabled) {
      toggleRowSelection(id);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = itemsPerPage === 'Tous' ? sortedData.length : startIndex + itemsPerPage;
  const paginatedData = itemsPerPage === 'Tous' ? sortedData : sortedData.slice(startIndex, endIndex);

  // Filtrer les données en fonction du terme de recherche
  const filteredData = paginatedData.filter((item) => {
    return columns.some((column) => {
      const cellValue = column.value ? column.value(item) : item[column.key];
      return cellValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });

  const onDownload = () => {
    const columnsToExport = columns.filter(column => column.key && column.label);
    const dataToExport = filteredData.map(item => columnsToExport.reduce((acc, column) => {
      const cellValue = column.value ? column.value(item) : item[column.key];
      return { ...acc, [column.label]: cellValue };
    }, {})
    );

    const lib = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataToExport, { header: columnsToExport.map(column => column.label) });
    XLSX.utils.book_append_sheet(lib, ws, `${"classeur"}`);

    // Utiliser le timeout pour le chargement
    XLSX.writeFile(lib, `${"filename.xlsx"}`);
  };

  const PrinterComponent = () => {
    return (
      <div className='flex items-center justify-center'>
        <ReactToPrint
          trigger={() => <button className='inline-flex mr-3 items-center cursor-pointer'>
            <span className='text-[16px]'>
              <AiOutlineExport />
            </span>
            <span className='ml-1 text-[12px]'>PDF</span>
          </button>}
          content={() => componentRef.current} />

        <button onClick={onDownload} className='inline-flex ml-3 items-center cursor-pointer'>
          <span className='text-[16px]'>
            <AiOutlineExport />
          </span>
          <span className='ml-1 text-[12px]'>Excel</span>
        </button>
      </div>
    );
  };

  const totalPages = `${currentPage}${itemsPerPage !== 'Tous' ? '/' : ''}${itemsPerPage === 'Tous' ? '' : Math.ceil(sortedData.length / itemsPerPage)}`;
  const displayedItems = itemsPerPage === 'Tous' ? sortedData.length : itemsPerPage;


  return (
    <div>
      <div className="pagination flex justify-between mb-2 xl:flex-row flex-col-reverse">
        <div className='flex justify-between xl:justify-start flex-wrap xl:flex-nowrap w-full items-center'>
          {!viewAllItems && <label className='xl:mr-2'>
            <select value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)} className='border-[1px] border-gray-300 h-[40px] mb-2 xl:w-auto xl:mb-0 px-2 rounded-md focus:outline-none cursor-pointer'>
              {PAGE_SIZE_OPTIONS.map((option) => (
                <option className='cursor-pointer' key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>}

          {exportation && <div className='xl:hidden flex items-center mb-3 justify-end w-full'>
            {PrinterComponent()}
          </div>}

          {searching && <label className='w-full'>
            <input type="text" placeholder='Rechercher...' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className='border-gray-300 mx-0 border-[1px] h-[40px] px-2 rounded-md focus:outline-none xl:w-[350px] w-[100%]' />
          </label>}
        </div>

        {exportation && <div className='xl:flex hidden justify-center items-center'>
          {PrinterComponent()}
        </div>}
      </div>

      <div className='print:p-2' ref={componentRef}>
        <div className='flex w-[100%] items-start flex-wrap table-container'>

          <div className={`max-h-[${maxHieght}] overflow-y-auto w-full`}>
            <table className='table'>
              <thead className='bg-secondary text-light sticky top-0 z-10'>
                <tr>
                  {index_number && <th>N°</th>}
                  {isSelectionEnabled && (
                    <th className='w-[40px] text-center cursor-pointer' onClick={() => toggleSelectAll()}>
                      <input
                        type='checkbox'
                        checked={selectedRows.size === data.length}
                        onChange={() => toggleSelectAll()}
                        className='cursor-pointer' />
                    </th>
                  )}
                  {columns.map((column, index) => (
                    <th className='cursor-pointer text-[16px]' key={index} onClick={() => sorted ? handleSort(column.key) : ''}>
                      {column.label}{' '}
                      {sortConfig.key === column.key && (
                        <span className='text-[10px]'>{sortConfig.direction === 'asc' ? '▲' : '▼'}</span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    {index_number && <td>{index + 1}</td>}

                    {isSelectionEnabled && (
                      <td className='w-[40px] text-center cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={isRowSelected(item.id)}
                          onChange={() => toggleRowSelection(item.id)}
                          className='cursor-pointer' />
                      </td>
                    )}

                    {columns.map((column) => (
                      <td className="whitespace-pre-line text-[16px]" key={column.key}>
                        {column.value ? column.value(item) : item[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}

                {filteredData.length === 0 && (
                  <tr>
                    <td colSpan={index_number ? 1 + columns.length : columns.length} className='border-[1px] border-gray-400'>
                      <div className='flex justify-center items-center h-[50px]'>Aucun élément.</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>
      </div>

      {sortedData.length > 0 && <div className="w-full flex justify-between items-center mt-3">
        <span className='text-sm font-normal'>{filteredData.length}/{sortedData.length} éléments</span>

        <div className='flex items-center'>
          {!(currentPage === 1) && <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="cursor-pointer border-[1px] mr-2 border-gray-300 text-[20px] inline-flex justify-center items-center rounded-full w-[30px] h-[30px]"
          >
            <MdOutlineNavigateBefore />
          </button>}

          {itemsPerPage !== 'Tous' && <span className="text-md">
            Page {totalPages}
          </span>}

          {!(endIndex >= sortedData.length) && <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={endIndex >= sortedData.length}
            className="cursor-pointer border-[1px] ml-2 border-gray-300 text-[20px] inline-flex justify-center items-center rounded-full w-[30px] h-[30px]"
          >
            <MdNavigateNext />
          </button>}
        </div>
      </div>}
    </div>
  );
}

export default GenericTable;
