import React, { useEffect, useState } from 'react'

import { functions, getElementData } from '../../../constances'
import { useNavigate } from 'react-router-dom'
import { usePlateformContext } from '../../../contexts'
import { ApexChartComp, ApexChartPieComp, BolckStat, GenericTable } from '../../../components'
import { AiOutlineBarChart, AiOutlineTable } from 'react-icons/ai'
import { FormatDateTime, FormatPrice } from '../../../constances/functions'

const HomePagePlateform = () => {

  // Contexte de la plateforme
  const {
    user,
    agents,
    sites,
    currencies,
    pointVenteSelected,
    selectPointVente,
    produits,
    famillesProduits,
    formeProduits,
    voiesProduits,
    stocksList,
    ventesList,
  } = usePlateformContext();

  // Référence vers le point de vente sélectionné
  const pointVente = getElementData(pointVenteSelected, sites);

  // Modèle de données pour le formulaire
  const initialCredentials = { point_vente: "" };
  const [credentials, setCredentials] = useState(initialCredentials);

  useEffect(() => {
    setCredentials({ ...credentials, ['point_vente']: pointVenteSelected });
  }, [pointVenteSelected]);

  // Top produits vendus affichage (table, chart)
  const [topView, setTopView] = useState("table"); // table or chart

  // Stock disponible
  let point_de_vente_id = pointVenteSelected?.toString();

  let data_stock = user.profile === "admin" ? stocksList : stocksList.filter(item => item.site.toString() === point_de_vente_id);
  let data_vente = user.profile === "admin" ? ventesList : ventesList.filter(item => item.site.toString() === point_de_vente_id);


  // Données pour les produits vendus
  let DATA_TOP_PRODUITS = functions.vente_par_produit(data_vente)
  let DATA_TABLE = DATA_TOP_PRODUITS;

  // Colonnes pour le tableau
  const columns = [
    { key: "", label: "ID", value: row => getElementData(row.produit, produits)?.reference },
    { key: "produit", label: "Designation", value: row => getElementData(row.produit, produits)?.designation },
    { key: "", label: "Famille", value: row => getElementData(getElementData(row.produit, produits)?.famille, famillesProduits)?.label },
    { key: "", label: "Forme", value: row => getElementData(getElementData(row.produit, produits)?.forme, formeProduits)?.label },
    { key: "", label: "Dosage", value: row => getElementData(row.produit, produits)?.dosage },
    { key: "", label: "Voie", value: row => getElementData(getElementData(row.produit, produits)?.voie, voiesProduits)?.label },
    { key: "totalQuantite", label: "Qté vendue" },
    user.profile === "client-admin" && { key: "totalMontant", label: "Total des ventes", value: row => `${FormatPrice(row.totalMontant)} ${getElementData(pointVente?.currency, currencies)?.iso_code}`},
    user.profile !== "admin" && { key: "total", label: "Prix u. (actuel)", value: row => `${FormatPrice(data_stock.filter(item => item.produit === row.produit)[0]?.prix_unitaire)} ${getElementData(pointVente?.currency, currencies)?.iso_code}` },  
  ];

  // Données pour le graphique des produits vendus
  const [chartSeriesClientTopProduitVenteQuantite, setChartSeriesClientTopProduitVenteQuantite] = useState([]);
  const [chartSeriesClientTopProduitVenteCategories, setChartSeriesClientTopProduitVenteCategories] = useState([]);

  useEffect(() => {
    let data = [];
    let categories = [];
    let DATAS = DATA_TOP_PRODUITS;

    DATAS.forEach(item => {
      data.push(item.totalQuantite);
      categories.push(`${getElementData(item.produit, produits)?.designation} (${getElementData(item.produit, produits)?.dosage})`);
    });

    setChartSeriesClientTopProduitVenteQuantite([{
      name: 'Quantité de produits vendus',
      data: data,
    }]);

    setChartSeriesClientTopProduitVenteCategories(categories);
  }, [user, ventesList]);

  // Liste des ventes
  const liste_ventes = user.profile === "admin" ? ventesList : ventesList.filter(item => item.site.toString() === pointVenteSelected?.toString());
  const liste_ventes_journaliere = liste_ventes.filter(item => new Date(item.date_creation).getDate() === new Date().getDate());

  // Calcul des totaux des ventes journalières
  let [total_liste_ventes_journaliere, set_total_liste_ventes_journaliere] = useState(0);
  let [total_liste_ventes_journaliere_commande, set_total_liste_ventes_journaliere_commande] = useState(0);
  let [total_liste_ventes_journaliere_comptoire, set_total_liste_ventes_journaliere_comptoire] = useState(0);

  // Nombre de ventes au comptoir et par commande
  let ventesComptoir = (user.profile === "client-agent" ? liste_ventes_journaliere : liste_ventes).filter(item => item.type_vente === "comptoir").length;
  let ventesCommande = (user.profile === "client-agent" ? liste_ventes_journaliere : liste_ventes).filter(item => item.type_vente === "commande").length;

  // Nombre d'utilisateurs de différents profils
  let user_admin = agents.filter(item => item.profile === "admin").length;
  let user_client_admin = agents.filter(item => item.profile === "client-admin").length;
  let user_client_agent = agents.filter(item => item.profile === "client-agent").length;
  let user_client_mobile = agents.filter(item => item.profile === "client-mobile").length;


  useEffect(() => {
    let somme = 0;
    let nombre_vente_comptoire = 0;
    let nombre_vente_commande = 0;

    liste_ventes_journaliere.filter(item => item.type_vente === "comptoir").forEach(item => {
      somme = somme + item.total;
      nombre_vente_comptoire = nombre_vente_comptoire + 1;
    });

    liste_ventes_journaliere.filter(item => item.type_vente === "commande").forEach(item => {
      nombre_vente_commande = nombre_vente_commande + 1;
    });

    set_total_liste_ventes_journaliere(somme);
    set_total_liste_ventes_journaliere_commande(nombre_vente_commande);
    set_total_liste_ventes_journaliere_comptoire(nombre_vente_comptoire);
  }, [ventesList, pointVenteSelected]);


  // Colonnes pour le tableau des ventes journalières
  const columns_ventes_journalieres = [
    { key: "facture", label: "N° Facture", value: row => row.facture },
    { key: "total", label: "Montant", value: row => `${row.total} ${getElementData(pointVente?.currency, currencies)?.iso_code}` },
    { key: "vente_items", label: "Produits", value: row => row.vente_items.length },
    user.profile !== 'admin' && { key: "user", label: "Agent", value: row => row.user === user?.id ? user.user_id : getElementData(row.user, agents)?.user_id },
    { key: "date_creation", label: "Date", value: row => <span className='capitalize'>{FormatDateTime(row.date_creation)}</span> },
  ];

  // Nombre d'utilisateurs
  let nb_utilisateurs = user.profile === "admin" ? agents.length : agents.filter(item => item.sites_list.includes(pointVenteSelected ? parseInt(pointVenteSelected) : pointVenteSelected)).length;

  // Éléments à afficher sur le tableau de bord
  let elements_stats = [
    user.profile !== "admin" && {
      description: "Ventes du jour au comptoir",
      value: `${FormatPrice(total_liste_ventes_journaliere)} ${user.profile !== "admin" && getElementData(pointVente?.currency, currencies)?.iso_code}`,
      bgClassColor: "bg-green-900",
      textClassColor: "text-white",
      path: '/ventes/historique'
    },

    user.profile !== "admin" && {
      description: "Commandes du jour validées",
      value: total_liste_ventes_journaliere_commande,
      bgClassColor: "bg-secondary",
      textClassColor: "text-white",
      path: '/'
    },

    user.profile === "client-agent" && {
      description: "Commandes en cours",
      value: 0,
      bgClassColor: "bg-purple-900",
      textClassColor: "text-white",
      path: '/'
    },

    user.profile !== "admin" && {
      description: "Produits en alert",
      value: 0,
      bgClassColor: "bg-red-700",
      textClassColor: "text-white",
      path: '/stocks/disponible'
    },

    user.profile === "admin" && {
      description: "Ventes du jour au comptoir",
      value: total_liste_ventes_journaliere_comptoire,
      bgClassColor: "bg-green-900",
      textClassColor: "text-white",
      path: '/rapports'
    },

    user.profile === "admin" && {
      description: "Commandes du jour validées",
      value: total_liste_ventes_journaliere_commande,
      bgClassColor: "bg-secondary",
      textClassColor: "text-white",
      path: '/rapports'
    },

    user.profile === "admin" && {
      description: "Points de ventes",
      value: sites.length,
      bgClassColor: "bg-dark",
      textClassColor: "text-white",
      path: '/points-ventes/list'
    },

    user.profile !== "client-agent" && {
      description: "Utilisateurs",
      value: nb_utilisateurs,
      bgClassColor: "bg-primary",
      textClassColor: "text-white",
      path: '/utilisateurs/list'
    },
  ];


  // console.log(DATA_TOP_PRODUITS, suivitStocksList, data_stock)

  return (
    <div>

       {/* Résumé statistiques */}
        <div className='my-3 flex flex-wrap'>
          {elements_stats.map((item, index) => (
            <BolckStat
              key={index}
              description={item.description}
              value={item.value}
              bgClassColor={item.bgClassColor}
              textClassColor={item.textClassColor}
              path={item.path}
            />
          ))}
        </div>

        {/* Top de prodduits vente */}
        <div className='flex xl:flex-row flex-col-reverse xl:items-start w-full xl:justify-between'>
          <div className='xl:w-[70%] w-[100%] flex flex-col'>
            <div className='w-full bg-white rounded-lg p-2 mt-3 flex justify-between items-center'>
              <div>
                <h3 className='font-medium text-md'>{user.profile !== "client-agent" ? "Top des produits" : "Ventes journalières"}</h3>
                <p className='font-light text-sm'>{user.profile !== "client-agent" ? "Produits les plus vendus" : "Liste de ventes du jour enregistrées"}</p>
              </div>

              {user.profile !== "client-agent" && (
                <div className='flex items-center'>
                  <button onClick={() => setTopView("table")} className={`p-2 text-lg rounded-md cursor-pointer mr-2  ${topView === "table" ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineTable />
                  </button>

                  <button onClick={() => setTopView("chart")} className={`p-2 text-lg rounded-md cursor-pointer ml-2  ${topView === "chart" ? 'text-white bg-secondary' : 'text-secondary bg-gray-100'}`}>
                    <AiOutlineBarChart />
                  </button>
                </div>
              )}
            </div>

            {topView === "table" && (
              (user.profile === "client-admin" || user.profile === "admin") ? 
                <div className='w-full bg-white rounded-lg p-2 my-3'>
                  <GenericTable
                    data={DATA_TABLE.sort((a, b) => a.totalQuantite - b.totalQuantite)}
                    columns={columns}
                    viewAllItems={true}
                    sorted={false}
                  />
                </div>
                :
                <div className='w-full bg-white rounded-lg p-2 my-3'>
                  <GenericTable
                    data={liste_ventes_journaliere}
                    columns={columns_ventes_journalieres}
                    viewAllItems={false}
                    sorted={true}
                  />
                </div>
            )}

            {topView === "chart" && (
              <div className='w-full bg-white rounded-lg p-2 my-3'>
                <ApexChartComp 
                  series={chartSeriesClientTopProduitVenteQuantite}
                  categories={chartSeriesClientTopProduitVenteCategories}
                  type={"bar"}
                  height={600}
                />
              </div>
            )}
          </div>

          <div className='xl:w-[29.5%] w-[100%] mt-3 xl:pb-3'>
            {/* Tendance de ventes (comptoir/commande) */}
            {(user.profile === "admin" || user.profile === "client-admin") && (
              <div className='w-full'>
                <div className='w-full bg-white rounded-lg p-2 mt-0 flex justify-between items-center'>
                  <div>
                    <h3 className='font-medium text-md'>Tendances de vente</h3>
                    <p className='font-light text-sm'>Rapport de toutes les ventes enregistrées.</p>
                  </div>
                </div>

                <div className='w-full bg-white rounded-lg p-2 my-3 flex justify-between items-center'>
                  <ApexChartPieComp
                    series={[ventesComptoir, ventesCommande]}
                    categories={['Vente au comptoir', 'Vente par commande']}
                    colors={["#14532d", "#0571b2"]}
                  />
                </div>
              </div>
            )}
            
            {/* Utilisateur */}
            {(user.profile === "admin") && (
              <div className='w-full'>
                <div className='w-full bg-white rounded-lg p-2 mt-3 flex justify-between items-center'>
                  <div>
                    <h3 className='font-medium text-md'>Utilisateurs</h3>
                    <p className='font-light text-sm'>Rapport sur l'ensemble des utilisateurs</p>
                  </div>
                </div>

                <div className='w-full bg-white rounded-lg p-2 my-3 flex justify-between items-center'>
                  <ApexChartPieComp
                    series={[user_admin, user_client_admin, user_client_agent, user_client_mobile]}
                    categories={['Admin', 'Client-admin', 'Client-agent', 'Client-mobile']}
                    colors={["#dc2626", "#ff911a", "#0891b2", "#111111"]}
                  />
                </div>
              </div>
            )}

            {/* Tendance de ventes journalières (comptoir/commande) */}
            {(user.profile === "client-agent") && (
              <div className='w-full'>
                <div className='w-full bg-white rounded-lg p-2 mt-0 flex justify-between items-center'>
                  <div>
                    <h3 className='font-medium text-md'>Tendances de vente journalières</h3>
                    <p className='font-light text-sm'>Rapport de toutes les ventes du jour enregistrées.</p>
                  </div>
                </div>

                <div className='w-full bg-white rounded-lg p-2 my-3 flex justify-between items-center'>
                  <ApexChartPieComp
                    series={[ventesComptoir, ventesCommande]}
                    categories={['Vente au comptoir', 'Vente par commande']}
                    colors={["#14532d", "#0571b2"]}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

    </div>
  );
};

export default HomePagePlateform;