import React, { useRef } from 'react'
import { AiOutlineClose, AiOutlineEdit, AiOutlineFilePdf, AiOutlinePrinter } from 'react-icons/ai'
import { FaCheck, FaPlus, FaUserSlash } from 'react-icons/fa'
import { usePlateformContext } from '../../../contexts';
import { Link, useNavigate } from 'react-router-dom';
import { GenericTable, HeaderApp, HomeMain } from '../../../components';
import { URL_PLATEFORM, checkPermission, datas, functions, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';

const AgentProfilesList = () => {

    const {sites, profiles_users, countries, user} = usePlateformContext();

    const navigate = useNavigate();


    const columns = [
        { key: "label", label: "Profil"},
        { key: "permissions_list", label: "Permissions", value: row => JSON.stringify(row.permissions_list)},

        (functions.isAdmin(user) || functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_PROFIL_UTILISATEUR], user.profile_permissions_list)) && {key: "", value: row => <div className='flex items-center w-[100px]'>
            <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/profiles-users/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>
        </div>}

    ];


  return (
    <div>
         <HeaderApp
            title="Profils Utilisateurs"
            links={[
                {
                    description: "Liste",
                    path: "",
                    icon: "",
                    active: true
                },
                {
                    description: "Nouveau",
                    path: "/profiles-users/create",
                    icon: "",
                }
            ]}
        />

        <div className='print:p-2'>
        
        <div 
            className='p-2 bg-white rounded-lg'
        >

            <GenericTable
                data={profiles_users}
                columns={columns}
                index_number
            />

        </div>

        </div>
    </div>
  )
}

export default AgentProfilesList