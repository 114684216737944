import React, { useRef } from 'react'
import { AiOutlineEdit } from 'react-icons/ai'
import { usePlateformContext } from '../../../contexts';
import { useNavigate } from 'react-router-dom';
import { GenericTable, HeaderApp } from '../../../components';
import { datas, functions, getElementData } from '../../../constances';
import { useReactToPrint } from 'react-to-print';
import { FormatDate, FormatDateTime, FormatPrice } from '../../../constances/functions';
 
const StockHistorique = () => {

    const {
        produits,
        fournisseurs, 
        agents, 
        currencies, 
        user, 
        stocksList,
        pointVenteSelected,
        sites,
    } = usePlateformContext();

    const navigate = useNavigate();

    const columns = [
        {key: "code_produit", label: "ID", value: row => getElementData(row.produit, produits)?.reference},
        {key: "designation", label: "Produit", value: row => getElementData(row.produit, produits)?.designation},
        {key: "quantite", label: "Quantité", value: row => row.quantite},
        {key: "prix_unitaire", label: "Prix unitaire", value: row => `${FormatPrice(row.prix_unitaire)} ${getElementData(getElementData(row.site, sites)?.currency, currencies)?.iso_code}`},
        {key: "prix_achat", label: "Prix d'achat", value: row => `${FormatPrice(row.prix_achat)} ${getElementData(getElementData(row.site, sites)?.currency, currencies)?.iso_code}`},
        {key: "date_exp", label: "Date de exp", value: row => <span className='capitalize'>{FormatDate(row.date_exp)}</span>},
        {key: "fournisseur", label: "Fournisseur", value: row => row.fournisseur ? getElementData(row.fournisseur, fournisseurs)?.designation : "-"},
        {key: "user", label: "Agent", value: row => row.user !== user?.id ? getElementData(row.user, agents)?.user_id : user?.user_id},
        {key: "date_creation", label: "Date", value: row => <span className='capitalize'>{FormatDateTime(row.date_creation)}</span>},


        (functions.isClientAdmin(user) || functions.hasProfilePermission([datas.PERMISSIONS.MODIFIER_STOCK], user.profile_permissions_list)) && {key: "", value: row => <div className='flex items-center'>
            {(user.id === row.user) && <button 
                className='cursor-pointer hover:bg-primary focus:bg-primary focus:text-white hover:text-white focus:border-primary hover:border-primary justify-center w-full inline-flex items-center print:hidden mx-1 border-[1px] border-gray-800 font-medium rounded-md px-2 py-1 text-[12px] text-gray-800' 
                onClick={() => navigate(`/stocks/historique/update/${row.id}`)}>
                <span className='mr-1'>Modifier</span>
                <AiOutlineEdit/>
            </button>}
        </div>}
    ]


  return (
    <div className=''>
        <HeaderApp
            title="Historique de stock"
            links={[
                {
                    description: "Suivit de stock",
                    path: "/stocks",
                    icon: "",
                },
                {
                    description: "Historique",
                    path: "",
                    icon: "",
                    active: true
                },
                {
                    description: "Nouveau",
                    path: "/stocks/create",
                    icon: "",
                }
            ]}
        />
        
        <div className='print:p-2'>

            <div 
                className='p-2 bg-white rounded-lg'
            >

                <GenericTable
                    data={stocksList.filter(item => item.site.toString() === pointVenteSelected.toString())}
                    columns={columns}
                />

            </div>
        </div>
    </div>
  )
}

export default StockHistorique