import React, { useEffect, useState } from 'react'
import { usePlateformContext } from '../../../contexts';
import { useNavigate } from 'react-router-dom';
import { BtnForm, CheckBoxList, FormInput, FormSelect, HeaderApp } from '../../../components';


const AgentAdd = () => {

    const {create_user_agent, user, sites, profiles_users, countries} = usePlateformContext();
    const navigate = useNavigate();

    let model_data = {
        structure: user?.profile === 'admin' ? "" : user?.structure,
        profile: user?.profile === 'admin' ? "admin" : 'client-agent',
        first_name: "",
        last_name: "",
        gender: "",
        birth: "",
        nationality: "",
        phone: "",
        email: "",
        country: "",
        city: "",
        active: true,
        sites_list: [],
        permissions_list: [],
        profile_permissions: ''
    };


    const [credentials, setCredentials] = useState(model_data);
    const [credentialsError, setCredentialsError] = useState({});

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleChangeError = (name, value) => {
        setCredentialsError({ ...credentialsError, [name]: value });
    }

    const handleSubmit = async (e) => {
            e.preventDefault()

            // Validation du formulaire

            const errors = {}; // Objet pour stocker les erreurs

            if (!credentials.first_name) {
                errors.first_name = "Entrer le prénom";
            }

            if (!credentials.last_name) {
                errors.last_name = "Entrer le nom de famille";
            }

            if (!credentials.gender) {
                errors.gender = "Selectionner le genre";
            }

            if (!credentials.birth) {
                    errors.birth = "Entrer la date de naissance";
                }

            if (!credentials.nationality) {
                errors.nationality = "Entrer la nationnalité";
            }

            if (!credentials.phone) {
                errors.phone = "Entrer le numéro de téléphone";
            }


            if (!credentials.email) {
                errors.email = "Entrer l'adresse e-mail";
            }

            if (!credentials.country) {
                errors.country = "Selectionner le pays";
            }

            if (!credentials.city) {
                errors.city = "Selectionner la ville";
            }

            // if (!credentials.active) {
            //     errors.active = "Selectionner si l'agent est actif ou non";
            // }

            if (Object.keys(errors).length > 0) {
                setCredentialsError(errors);
            } else {
                let response = await create_user_agent(credentials);

                if (response) {
                    setCredentials(model_data);
                    navigate("/utilisateurs/list");
                }
            }
        
    }

    let optionsGender = [
        {label: "Masculin", value: "M"},
        {label: "Feminin", value: "F"}
    ]

    let optionsActive = [
        {label: "Oui", value: true},
        {label: "Non", value: false}
    ]


    const [optionsCountries, setOptionsCountries] = useState([]);
    const [optionsCities, setOptionsCities] = useState([]);
    const [optionsProfilePermissions, setOptionsProfilePermissions] = useState([]);

    useEffect(() => {
        let countries_list = countries.map((item) => {
        return {label: item.label, value: item.id}
        });

        setOptionsCountries(countries_list)
    }, [countries])

    useEffect(() => {

        let country_selected = countries.filter((item) => item.id === (credentials.country ? parseInt(credentials.country) : ''))[0]

        setOptionsCities(country_selected?.cities?.map((item) => {
            return {label: item.label, value: item.id}
        }))
    }, [credentials.country, countries, credentials])

    useEffect(() => {
        let profile_list = profiles_users.map((item) => {
        return {label: item.label, value: item.id}
        });

        setOptionsProfilePermissions(profile_list)
    }, [profiles_users])

    //
    function ajouterOuSupprimerElement(liste, element) {
        const index = liste.indexOf(element);
    
        if (index === -1) {
        // L'élément n'existe pas dans la liste, ajoutons-le.
        liste.push(element);
        } else {
        // L'élément existe dans la liste, supprimons-le.
        liste.splice(index, 1);
        }

        return liste;
    }   

    const handleChangeCheckBoxList = (name, value) => {
        setCredentials({...credentials, [name] : value})
    }

  return (
    <div>
        <HeaderApp
            title="Nouveau utilisateur"
            links={[
                {
                    description: "Liste",
                    path: "/personnels",
                    icon: "",
                },
                {
                    description: "Nouveau",
                    path: "/personnels/create",
                    icon: "",
                    active: true
                }
            ]}
        />

        <div className='flex flex-col justify-start items-center py-3'>
            <form onSubmit={handleSubmit} className='w-[100%] max-w-[100%] bg-white p-3 rounded-md'>

                <div>

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormInput
                            type={"text"}
                            label={"Nom(s)"}
                            required={true}
                            placeholder={"Nom de famille"}
                            autoComplete='off'
                            value={credentials.last_name}
                            error={credentialsError.last_name}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("last_name", null)}
                            name={"last_name"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

                        />

                        <FormInput
                            type={"text"}
                            label={"Prénom(s)"}
                            required={true}
                            placeholder={"Prénom(s)"}
                            autoComplete='off'
                            value={credentials.first_name}
                            error={credentialsError.first_name}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("first_name", null)}
                            name={"first_name"}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 flex-grow"}

                        />
                    </div>

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormInput
                            type={"date"}
                            label={"Date de naissance"}
                            required={true}
                            placeholder={"Date de naissance"}
                            autoComplete='off'
                            value={credentials.birth}
                            error={credentialsError.birth}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("birth", null)}
                            name={"birth"}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 flex-grow"}

                        />

                        <FormSelect
                            label={"Genre"}
                            required={true}
                            description={"Selectionner le genre"}
                            value={credentials.gender}
                            error={credentialsError.gender}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("gender", null)}
                            name={"gender"}
                            options={optionsGender}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 mt-2 flex-grow"}

                        />

                        <FormInput
                            type={"text"}
                            label={"Nationnalité"}
                            required={true}
                            placeholder={"Nationnalité"}
                            autoComplete='off'
                            value={credentials.nationality}
                            error={credentialsError.nationality}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("nationality", null)}
                            name={"nationality"}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 flex-grow"}

                        />
                    </div>

                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormSelect
                            required={true}
                            label={"Pays"}
                            description={"Selectionner le pays"}
                            value={credentials.country}
                            error={credentialsError.country}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("country", null)}
                            name={"country"}
                            options={optionsCountries}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 mt-2 flex-grow"}
                        />

                        <FormSelect
                            required={true}
                            label={"Ville"}
                            description={"Selectionner la ville"}
                            value={credentials.city}
                            error={credentialsError.city}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("city", null)}
                            name={"city"}
                            options={optionsCities}
                            className={"md:w-[49%] w-full mr-0 md:mr-1 mt-2 flex-grow"}
                        />
                    </div>
                    
                    <div className='flex justify-between md:flex-row flex-col'>
                        <FormInput
                            type={"text"}
                            label={"Téléphone"}
                            required={true}
                            placeholder={"Numéro de téléphone"}
                            autoComplete='off'
                            value={credentials.phone}
                            error={credentialsError.phone}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("phone", null)}
                            name={"phone"}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 flex-grow"}

                        />

                        <FormInput
                            type={"email"}
                            label={"E-mail"}
                            required={true}
                            placeholder={"exemple@site.com"}
                            autoComplete='off'
                            value={credentials.email}
                            error={credentialsError.email}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("email", null)}
                            name={"email"}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 flex-grow"}

                        />
                    </div>

                    <div className='flex justify-between md:flex-row flex-col mb-3'>
                        <FormSelect
                            required={false}
                            label={"Profil utilisateur"}
                            description={"Selectionner le profil"}
                            value={credentials.profile_permissions}
                            error={credentialsError.profile_permissions}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("profile_permissions", null)}
                            name={"profile_permissions"}
                            options={optionsProfilePermissions}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 mt-2 flex-grow"}

                        />

                        <FormSelect
                            required={true}
                            label={"Actif"}
                            // description={"Selectionner le status"}
                            value={credentials.active}
                            error={credentialsError.active}
                            onChange={handleChange}
                            onFocus={() => handleChangeError("active", null)}
                            name={"active"}
                            options={optionsActive}
                            className={"md:w-[33%] w-full mr-0 md:mr-1 mt-2 flex-grow"}

                        />
                    </div>


                    {/* Affectation aux sites */}
                    {user.profile !== "admin" && <div className='w-full my-3'>
                        <h3 className='font-normal text-sm mb-1'>Points de ventes</h3>
                        <div className='flex flex-wrap border-[1px] px-1'>
                            {sites.map((item, index) => {
                                return(
                                    <CheckBoxList 
                                        key={index} 
                                        list={credentials?.sites_list} 
                                        item={item.id} 
                                        label={`${item.label} (${item.slug})`} 
                                        onPress={() => handleChangeCheckBoxList("sites_list", ajouterOuSupprimerElement(credentials.sites_list, item.id))}
                                    />
                                )
                            })}
                        </div>
                    </div>}


                    <BtnForm
                        type="submit"
                        text={"Enregistrer"}
                        className="mt-2"
                    />

                </div>

            </form>
        </div>
    </div>
  )
}

export default AgentAdd