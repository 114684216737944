export const permissions_profile_client = [
    // Gestion de stock
    "enregistrer-stock",
    "modifier-stock",
    "consulter-stock-disponible",    
    "consulter-stock-historique",

    // gestion de vente
    "enregistrer-vente",
    "modifier-vente",
    "consulter-vente-historique",

    // gestion de commande
    "consulter-commande-en-attente",
    "consulter-commande-validee",
    "valider-commande",

    // Generer les rapports
    "generer-rapport-historique-stock",
    "generer-rapport-historique-vente",
    "generer-rapport-quantite-produit-vendu",

    // gestion de produits
    "consulter-produit",

    // Gestion de fournisseur
    "enregistrer-fournisseur",
    "modifier-fournisseur",
    "supprimer-fournisseur",
    "consulter-fournisseur",

]

export const permissions_profile_admin = [
    // Gestion de stock
    "consulter-stock-disponible", 

    // gestion de commande
    "consulter-commande-en-attente",
    "consulter-commande-validee",  
    
    // Generer les rapports
    "generer-rapport-quantite-produit-vendu",

    // gestion de produits
    "enregistrer-produit",
    "modifier-produit",
    "supprimer-produit",
    "consulter-produit",

    // Gestion de point de vente
    "enregistrer-point-de-vente",
    "modifier-point-de-vente",
    "supprimer-point-de-vente",
    "consulter-point-de-vente",

    // Gestion de compte client
    "enregistrer-compte-client",
    "modifier-compte-client",
    "supprimer-compte-client",
    "consulter-compte-client",

    // Gestion de profil utilisateur
    "enregistrer-profil-utilisateur",
    "modifier-profil-utilisateur",
    "supprimer-profil-utilisateur",
    "consulter-profil-utilisateur",

    // Gestion de profil utilisateur
    "enregistrer-utilisateur",
    "modifier-utilisateur",
    // "supprimer-utilisateur",
    "consulter-utilisateur",
]

// Permissions constante
export const PERMISSIONS = {
    ENREGISTRER_STOCK: "enregistrer-stock",
    MODIFIER_STOCK: "modifier-stock",
    CONSULTER_STOCK_DISPONIBLE: "consulter-stock-disponible",
    CONSULTER_STOCK_HISTORIQUE: "consulter-stock-historique",

    ENREGISTRER_VENTE: "enregistrer-vente",
    MODIFIER_VENTE: "modifier-vente",
    CONSULTER_VENTE_HISTORIQUE: "consulter-stock-vente",

    CONSULTER_COMMANDE_EN_ATTENTE: "consulter-commande-en-attente",
    CONSULTER_COMMANDE_VALIDEE: "consulter-commande-validee",
    VALIDER_COMMANDE: "valider-commande",

    GENERER_RAPPORT_HISTORIQUE_STOCK : "generer-rapport-historique-stock",
    GENERER_RAPPORT_HISTORIQUE_VENTE : "generer-rapport-historique-vente",
    GENERER_RAPPORT_QUANTITE_PRODUIT_VENDU : "generer-rapport-quantite-produit-vendu",

    ENREGISTRER_PRODUIT: "enregistrer-produit", 
    MODIFIER_PRODUIT: "modifier-produit",
    SUPPRIMER_PRODUIT: "supprimer-produit",
    CONSULTER_PRODUIT: "consulter-produit",
    
    ENREGISTRER_FOURNISSEUR: "enregistrer-fournisseur",
    MODIFIER_FOURNISSEUR: "modifier-fournisseur",
    SUPPRIMER_FOURNISSEUR: "supprimer-fournisseur",
    CONSULTER_FOURNISSEUR: "consulter-fournisseur",
    
    ENREGISTRER_POINT_DE_VENTE: "enregistrer-point-de-vente",
    MODIFIER_POINT_DE_VENTE: "modifier-point-de-vente",
    SUPPRIMER_POINT_DE_VENTE: "supprimer-point-de-vente",
    CONSULTER_POINT_DE_VENTE: "consulter-point-de-vente",

    ENREGISTRER_COMPTE_CLIENT: "enregistrer-compte-client",
    MODIFIER_COMPTE_CLIENT: "modifier-compte-client",
    SUPPRIMER_COMPTE_CLIENT: "supprimer-compte-client",
    CONSULTER_COMPTE_CLIENT: "consulter-compte-client",

    ENREGISTRER_PROFIL_UTILISATEUR: "enregistrer-profil-utilisateur",
    MODIFIER_PROFIL_UTILISATEUR: "modifier-profil-utilisateur",
    SUPPRIMER_PROFIL_UTILISATEUR: "supprimer-profil-utilisateur",
    CONSULTER_PROFIL_UTILISATEUR: "consulter-profil-utilisateur",

    ENREGISTRER_UTILISATEUR: "enregistrer-utilisateur",
    MODIFIER_UTILISATEUR: "modifier-utilisateur",
    SUPPRIMER_UTILISATEUR: "supprimer-utilisateur",
    CONSULTER_UTILISATEUR: "consulter-utilisateur",
};