import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthMain, BtnForm, FormInput } from '../../components';
import { usePlateformContext } from '../../contexts';
import { URL_PLATEFORM } from '../../constances';

const LoginPage = () => {

  const {login, token} = usePlateformContext();
  const navigate = useNavigate();

  let model_data = {
    username: "",
    password: "",
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
      setCredentials({...credentials, [e.target.name] : e.target.value})
  }

  const handleChangeError = (name, value) => {
      setCredentialsError({ ...credentialsError, [name]: value });
  }

  const handleSubmit = async (e) => {
      e.preventDefault()

      // Validation du formulaire

      const errors = {}; // Objet pour stocker les erreurs

      if (!credentials.username) {
          errors.username = "Entrer le numero d'identifiant";
      }

      if (!credentials.password) {
          errors.password = "Entrer le mot de passe";
      }


      if (Object.keys(errors).length > 0) {
          setCredentialsError(errors);
      } else {
        let response = await login({username: credentials.username, password: credentials.password});

        if (response.sending_otp) {
          console.log("Envoie de l'otp ... redirection");
          navigate(`/otp`);
        }
      }
      
  }

  useEffect(() => {
      if(token){
          navigate(URL_PLATEFORM)
      }
  }, [navigate, token])

  return (
    <AuthMain>
      <form onSubmit={handleSubmit} className='w-[400px] max-w-[95%] bg-white p-3 rounded-md'>

        <div className='mb-5'>
          <h3 className='text-3xl text-secondary'>Bienvenue !</h3>
          <p className='text-dark text-sm font-normal'>Veuillez entrer vos informations de connexion.</p>
        </div>

        <div>
          <FormInput
            type={"text"}
            label={"Identifiant"}
            required={true}
            placeholder={"X9999"}
            autoComplete='off'
            value={credentials.username}
            error={credentialsError.username}
            onChange={handleChange}
            onFocus={() => handleChangeError("username", null)}
            name={"username"}
          />

          <FormInput
            type={"password"}
            label={"Mot de passe"}
            required={true}
            placeholder={"Mot de passe"}
            autoComplete='off'
            value={credentials.password}
            error={credentialsError.password}
            onChange={handleChange}
            onFocus={() => handleChangeError("password", null)}
            name={"password"}
            password={true}
          />

          <BtnForm
            type="submit"
            text={"Connexion"}
          />

        </div>
      </form>
    </AuthMain>
  )
}

export default LoginPage